import { CalendarIcon } from '@heroicons/react/24/outline';

import { ComponentProps } from 'react';
import { Button } from './Button';
import { Calendar } from './Calendar';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

type DatePickerProps = {
  onChange?: (range: Date | undefined) => void;
  value?: Date | undefined;
} & Omit<
  ComponentProps<typeof Calendar>,
  'initialFocus' | 'mode' | 'selected' | 'onSelect'
>;

// TODO verify input and output respect timezone of local user; similar to what was done in `DateInput`
// TODO someday allow the button to be an input for the value, so it can be typed in
// very close the the shadcn/ui component: https://ui.shadcn.com/docs/components/date-picker#date-picker
export function DatePicker({
  value: date,
  onChange,
  ...calendarProps
}: DatePickerProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="min-w-fit justify-start font-normal"
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {/* // TODO consider alternative formats; i.e. 'PPP' */}
          {date ? date.toLocaleDateString() : <span>Pick a Date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          initialFocus
          mode="single"
          selected={date}
          onSelect={onChange}
          {...calendarProps}
        />
      </PopoverContent>
    </Popover>
  );
}
