import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button from '~/components/Button';
import CorsoLogo from '~/components/CorsoLogo';
import IconAction from '~/components/IconAction';
import Separator from '~/components/Separator';
import { ShipProtectSettingsFormValues } from '~/types';

export function ShippingPlusWidgetPreview({
  config,
}: {
  config: ShipProtectSettingsFormValues['widgetConfig'];
}) {
  const [showWidgetModal, setShowWidgetModal] = useState(false);

  if (!config) return null;

  const { shippingPlus } = config ?? {};

  const { label, description, modal } = shippingPlus ?? {};

  return (
    <div>
      <div className="flex max-w-2xl flex-col gap-2 rounded-md border border-gray-200 p-4">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <p className="text-sm font-medium text-black">{label}</p>
            <IconAction.Button
              variant="ghost"
              iconSize="sm"
              icon={InformationCircleIcon}
              title="Learn More"
              onClick={() => setShowWidgetModal(!showWidgetModal)}
            />
          </div>

          <CorsoLogo size="sm" />
        </div>
        <p className="text-xs text-corso-gray-600"> {description}</p>
      </div>

      <Button
        onClick={() => setShowWidgetModal(!showWidgetModal)}
        variant="text"
      >
        {showWidgetModal ? 'Close modal' : 'View modal'}
      </Button>

      {showWidgetModal && (
        <div className="flex max-w-2xl flex-col gap-2 rounded-md border border-gray-200 p-2">
          <div>
            {modal?.imageUrl?.length && (
              <img src={modal.imageUrl} alt="widget banner" />
            )}
            <div className="flex flex-col gap-2 p-4">
              <p className="text-center text-lg font-medium">{modal?.title}</p>
              <p className="text-sm">{modal?.description}</p>
              <Separator />
              {modal?.benefits?.map(
                ({ title, description: modalDescription }) => (
                  <div key={title} className="flex flex-col gap-1.5 p-2">
                    <p className="font-md font-medium">{title}</p>
                    <p className="text-xs">{modalDescription}</p>
                  </div>
                ),
              )}
              <Separator />
              <div className="flex justify-between gap-2 pt-2 text-xs underline">
                <p>Privacy Policy </p>|<p>CA Privacy Notice</p>|<p>Terms</p>|
                <p>Report Shipping Issue</p>
                <CorsoLogo size="base" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
