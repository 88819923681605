import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';

import { SquaresPlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Label } from '@radix-ui/react-label';
import { useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { useDebounceValue, useToggle } from 'usehooks-ts';
import api from '~/api';
import Button from '~/components/Button';
import { Checkbox, NumberInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import LineItem from '~/components/LineItem';
import Modal from '~/components/Modal';
import SpinnerIcon from '~/components/SpinnerIcon';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';

type Variant = CrewMerchantUi.StoreProduct['variants'][number];
type SelectedVariant = Omit<Variant, 'quantity'> & { quantity: number };

function ProductSearch({
  existingProducts = [],
  originalProductId,
  onAdd,
}: {
  originalProductId: string;
  existingProducts?: Variant[];
  onAdd: (products: Variant[]) => void;
}) {
  const {
    storeUser: { storeId, store },
  } = useMerchantContext();
  // set the default value to the original product id from the line item
  const [searchTerm, setSearchTerm] = useDebounceValue(originalProductId, 300);
  const { data: results = [], isLoading } = useQuery({
    queryKey: ['products', { storeId, searchTerm }],
    queryFn: () => api.store(String(storeId)).product.search(searchTerm),
  });
  const [showSelected, toggle, setShowSelected] = useToggle(false);
  const [selected, setSelected] = useState<Variant[]>([]);

  const handleSelect =
    (variant: Variant): ChangeEventHandler<HTMLInputElement> =>
    ({ target }) => {
      if (target.checked) {
        setSelected((prev) => [...prev, variant]);
      } else {
        setSelected((prev) =>
          prev.filter((v) => v.idFromPlatform !== variant.idFromPlatform),
        );
      }
    };

  return (
    <div className="flex flex-col gap-4">
      <div className="relative flex flex-col gap-4">
        <TextInput
          id="search"
          label="Search"
          defaultValue={originalProductId}
          placeholder="Search by title or variant SKU"
          onChange={(e) => setSearchTerm(e.target.value)}
          labelVisuallyHidden
          addon={{
            insideEnd:
              isLoading ?
                <SpinnerIcon className="size-5" />
              : <MagnifyingGlassIcon className="size-5" aria-hidden="true" />,
          }}
        />
        {!results.length ?
          <div className="flex h-96 flex-col items-center justify-center text-corso-gray-500">
            <span>{isLoading ? 'Searching.. ' : 'No products found.'}</span>
          </div>
        : <ul className="h-96 overflow-y-auto">
            {results.map((product) => (
              <li key={product.idFromPlatform}>
                <Label>{product.title}</Label>
                <ul className="pl-4">
                  {product.variants.map((variant) => {
                    const isExisting = existingProducts.some(
                      (v) => v.idFromPlatform === variant.idFromPlatform,
                    );
                    const isSelected = selected.some(
                      (v) => v.idFromPlatform === variant.idFromPlatform,
                    );

                    return (
                      <li>
                        <Label className="flex flex-row items-center gap-2">
                          <Checkbox
                            name="Product"
                            value={variant.idFromPlatform}
                            onChange={handleSelect(variant)}
                            disabled={isExisting}
                            checked={isSelected}
                          />
                          {variant.label}
                          {isExisting ?
                            <span className="text-corso-gray-500">
                              (Already added)
                            </span>
                          : <span className="text-corso-gray-500">
                              ({variant.quantity} available)
                            </span>
                          }
                          {variant.price !== null && (
                            <span className="ml-auto text-corso-gray-500">
                              {formatter.currency(
                                Number(variant.price),
                                store.currencyCode,
                              )}
                            </span>
                          )}
                        </Label>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        }

        {showSelected && (
          <div className="absolute top-0 flex h-full w-full flex-col gap-4 bg-white">
            <button
              className="flex items-center gap-2"
              type="button"
              onClick={() => setShowSelected(false)}
            >
              <ChevronLeftIcon
                className="h-5 w-5 text-corso-gray-500"
                aria-hidden="true"
              />
              <span className="text-corso-gray-500">
                Back to Search Results
              </span>
            </button>

            <ul className="overflow-y-auto pl-4">
              {selected.map((variant) => (
                <li key={variant.id}>
                  <Label className="flex flex-row items-center gap-2">
                    <Checkbox
                      name="Product"
                      value={variant.idFromPlatform}
                      onChange={handleSelect(variant)}
                      checked
                    />
                    {variant.label}
                  </Label>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="flex justify-between bg-white">
        <Button onClick={() => toggle()}>{selected.length} selected</Button>
        <Button
          disabled={selected.length === 0}
          variant="primary"
          onClick={() => onAdd(selected)}
        >
          Add
        </Button>
      </div>
    </div>
  );
}

export default function ProductReplacement({
  products,
  originalProductName,
  buttonText = 'Add Replacement Product',

  onChange,
}: {
  products: SelectedVariant[];
  originalProductName: string;

  buttonText?: string;

  onChange: (products: SelectedVariant[]) => void;
}) {
  const [showModal, setShowModal] = useState(false);
  const handleNewProducts = (selection: Variant[]) => {
    onChange([
      ...products,
      ...selection.map((product) => ({ ...product, quantity: 1 })),
    ]);
    setShowModal(false);
  };

  const handleDelete =
    ({ id }: Variant) =>
    () => {
      onChange(products.filter((p) => p.id !== id));
    };

  const handleQuantityChange =
    ({ id }: Variant) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { valueAsNumber } = event.target;
      // TODO elevate to form for a more unified validation
      // default to no less than 1
      const quantity =
        Number.isNaN(valueAsNumber) || valueAsNumber < 1 ?
          1
        : Math.trunc(valueAsNumber);

      onChange(
        products.map((product) =>
          product.id === id ? { ...product, quantity } : product,
        ),
      );
    };

  return (
    <div className="flex flex-col gap-2">
      <ul className="space-y-2">
        {products.map((product) => (
          <li
            key={product.id}
            className="flex w-full flex-row gap-2 rounded border p-2"
          >
            <div className="flex-auto">
              <LineItem
                name={`${product.productName} - ${product.label}`}
                quantity={product.quantity}
                unitPrice={
                  product.price !== null ? Number(product.price) : null
                }
                sku={product.sku}
                imageUrl={product.imageUrl}
              >
                <NumberInput
                  id={`quantity-${product.id}`}
                  label="Quantity"
                  labelVisuallyHidden
                  value={product.quantity}
                  step={1}
                  min={1}
                  addon={{ insideEnd: 'QTY' }}
                  onChange={handleQuantityChange(product)}
                  className="mt-2 max-w-fit"
                />
              </LineItem>
            </div>

            <div className="mt-12">
              <IconAction.Button
                icon={TrashIcon}
                title={`Remove ${product.label}`}
                onClick={handleDelete(product)}
                className="ml-2"
              />
            </div>
          </li>
        ))}
      </ul>
      <Button
        size="intrinsic"
        onClick={() => setShowModal(true)}
        className="self-end"
      >
        <SquaresPlusIcon className="mr-2 h-5 w-5" />
        {buttonText}
      </Button>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        title="Add Replacement Products"
      >
        <ProductSearch
          originalProductId={originalProductName}
          existingProducts={products}
          onAdd={handleNewProducts}
        />
      </Modal>
    </div>
  );
}
