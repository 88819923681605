import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { z } from 'zod';

export const usePathParams = <T>(
  schema: z.Schema<T, z.ZodTypeDef, unknown>,
) => {
  const params = useParams();
  return useMemo(() => schema.parse(params), [params, schema]);
};
