import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = HTMLAttributes<HTMLSpanElement> & { error?: boolean };
export default function SupportingText({
  children,
  className,
  error,
  ...props
}: Props) {
  return (
    <span
      {...props}
      className={twMerge(
        'text-xs text-corso-gray-500',
        error && 'text-corso-red-600',
        className,
      )}
      role={error ? 'alert' : undefined}
    >
      {children}
    </span>
  );
}
