import { faTags } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { CrewMerchantUi } from 'corso-types';
import { FormEventHandler, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '~/components/Button';
import Card from '~/components/Card';
import ConfirmModal from '~/components/ConfirmModal';
import EmptyStateAction from '~/components/EmptyStateAction';
import { Input, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Modal from '~/components/Modal';
import Page from '~/components/Page';
import { Badge } from '~/components/ui/primitives/Badge';
import {
  useStoreClaimTagDelete,
  useStoreClaimTags,
  useStoreClaimTagsCreate,
} from '~/hooks/useClaimTags';
import { ClaimTag } from '~/types';

function DeleteClaimTag({ tag }: { tag: ClaimTag }) {
  const { mutate: deleteClaimTags, isPending } = useStoreClaimTagDelete();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmDelete = () => {
    deleteClaimTags(tag.id);
    setShowConfirmation(false);
  };

  return (
    <>
      <IconAction.Button
        icon={TrashIcon}
        title={`Delete ${tag.name}`}
        loading={isPending}
        onClick={() => setShowConfirmation(true)}
      />

      <ConfirmModal
        title="Delete Claim Tag"
        prompt="Are you sure you sure? Tag will be removed from all Claims and Automation Rules. This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        show={showConfirmation}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
}

function ClaimTagForm({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const {
    formState: { errors },
    handleSubmit,
    reset,
    register,
  } = useForm<CrewMerchantUi.ClaimTagCreate>({
    resolver: zodResolver(CrewMerchantUi.claimTagCreateSchema),
  });

  const { mutate: createClaimTag } = useStoreClaimTagsCreate();

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => {
      createClaimTag(formValues.name);
      reset();
      onClose();
    })(event).catch(console.error);
  };

  return (
    <Modal title="Create Claim Tag" show={show} onClose={onClose}>
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <TextInput
          id="name"
          label="Name"
          details="The name of the tag."
          required
          {...register('name')}
          error={errors.name?.message}
        />

        <Button variant="primary" type="submit">
          Create
        </Button>
      </form>
    </Modal>
  );
}

export default function ClaimTagsSettings() {
  const { data: claimTags = [], isLoading } = useStoreClaimTags(); // Default to an empty array to avoid null issues
  const [filteredTags, setFilteredTags] = useState(claimTags);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    setFilteredTags(claimTags);
  }, [claimTags]);

  const claimTagsExist = claimTags.length > 0 && !isLoading;

  return (
    <Page
      title="Claim Tags"
      primaryAction={{
        onAction: () => setShowForm(true),
        icon: PlusIcon,
        content: 'Add Claim Tag',
      }}
    >
      <Card>
        {/* Filter tags by name to assist with finding the right tag to delete */}
        {claimTagsExist ?
          <Input
            label="Search tags"
            id="search-tags"
            type="search"
            labelVisuallyHidden
            placeholder="Search tags"
            onChange={(e) => {
              const search = e.target.value.toLowerCase();
              setFilteredTags(
                claimTags.filter((ct) =>
                  ct.name.toLowerCase().includes(search),
                ),
              );
            }}
          />
        : <EmptyStateAction.Button
            onClick={() => setShowForm(true)}
            icon={<FontAwesomeIcon icon={faTags} />}
            label="Create a claim tag"
            description="Claim tags allow you to categorize and filter claims. Claim tags can also be created when managing Automation Rules or Claims."
          />
        }

        {filteredTags.length > 0 && (
          <ul className="grid grid-cols-1 gap-2.5 md:grid-cols-2">
            {filteredTags.map((ct) => (
              <li
                key={ct.id}
                className="flex items-center justify-between gap-2"
              >
                <Badge>{ct.name}</Badge>
                <DeleteClaimTag tag={ct} />
              </li>
            ))}
          </ul>
        )}

        <ClaimTagForm show={showForm} onClose={() => setShowForm(false)} />
      </Card>
    </Page>
  );
}
