import { faLightbulb, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { SparklesIcon as SparklesIconSolid } from '@heroicons/react/20/solid';
import {
  ExclamationCircleIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import {
  ClickhouseDataset,
  datasetDetails,
  enumToZodLiteralUnion,
} from 'corso-types';
import { Key, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import Card from '~/components/Card';
import Feed from '~/components/Feed';
import { TextInput } from '~/components/field';
import Icon from '~/components/Icon';
import IconAction from '~/components/IconAction';
import InfoPopover from '~/components/InfoPopover';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';

// TODO: future improvements
// TODO: dynamic set height on iFrame

type InsightRequest = {
  id: Key;
  dataset: `${ClickhouseDataset}`;
  question: string;
  askedAt: Date;
};

// ------ Corso AI ------
function InsightResponse({ request }: { request: InsightRequest }) {
  const { userFullName } = useMerchantContext();
  const storeId = useStoreId();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['insight', storeId, request, userFullName],
    queryFn: () =>
      api.store(storeId).dashboard.prompt(
        {
          question: request.question,
          dataset: request.dataset,
        },
        userFullName,
      ),
  });

  if (isLoading)
    return (
      <div role="status">
        <Icon
          icon={faSpinnerThird}
          className="h-8 w-8 animate-spin text-corso-blue-600"
        />
        <span className="sr-only">Loading...</span>
      </div>
    );
  if (isError) return <div className="text-red-500">{error.message}</div>;
  if (!data)
    return (
      <p>
        {' '}
        Nothing was returned for that question, please rephrase and try again.
      </p>
    );
  return (
    <div className="flex justify-between rounded-md border p-2">
      <pre className="max-w-prose self-center text-wrap font-sans text-sm text-corso-gray-800">
        {data.answer}
      </pre>
      <Badge className="self-end">
        {datasetDetails[request.dataset].displayName}
      </Badge>
    </div>
  );
}

function SamplePromptButton({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex size-full flex-col justify-between gap-3 rounded-lg bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-600 p-4 hover:opacity-90"
    >
      <p className="text-pretty text-left text-sm font-semibold text-white">
        {label}
      </p>
      <Icon
        icon={faLightbulb}
        className="h-auto max-h-6 self-end text-white md:max-h-6"
      />
    </button>
  );
}

const promptFields = z.object({
  question: z.string().trim(),
  dataset: enumToZodLiteralUnion(ClickhouseDataset),
});

function getEnabledDatasets({
  isReturnsEnabled,
  isShippingProtectionEnabled,
}: {
  isReturnsEnabled: boolean;
  isShippingProtectionEnabled: boolean;
}) {
  const enabledDatasets: ClickhouseDataset[] = [];

  // applies to all merchants
  enabledDatasets.push(ClickhouseDataset.storeOrders);
  enabledDatasets.push(ClickhouseDataset.storeProductVariants);

  if (isReturnsEnabled) {
    enabledDatasets.push(ClickhouseDataset.crewClaimLineItems);
  }

  if (isShippingProtectionEnabled) {
    enabledDatasets.push(ClickhouseDataset.shipProtectClaims);
  }

  return enabledDatasets;
}

export default function CorsoAI() {
  const storeId = useStoreId();
  const [questionsAndResponses, setQuestionAndResponses] = useState<
    InsightRequest[]
  >([]);

  useEffect(() => {
    setQuestionAndResponses([]);
  }, [storeId]);

  const { isReturnsEnabled, isShippingProtectionEnabled } =
    useEnabledClaimType();

  const enabledDatasets = getEnabledDatasets({
    isReturnsEnabled,
    isShippingProtectionEnabled,
  });

  const { control, register, handleSubmit, resetField, watch } = useForm<
    z.infer<typeof promptFields>
  >({
    resolver: zodResolver(promptFields),
    defaultValues: {
      dataset: ClickhouseDataset.storeOrders,
    },
  });

  const selectedDataset = watch('dataset');

  const [samplePrompts, setSamplePrompts] = useState(
    datasetDetails[selectedDataset].sampleQuestions,
  );

  const [placeholder, setPlaceholder] = useState(samplePrompts[0]);

  useEffect(() => {
    const updatedPrompts = datasetDetails[selectedDataset].sampleQuestions;
    setSamplePrompts(updatedPrompts);
    setPlaceholder(updatedPrompts[0]);
  }, [selectedDataset]);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-[1fr_auto]">
      <Card>
        <p>
          <span className="flex items-center gap-2">
            <span className="bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-800 bg-clip-text text-2xl font-bold text-transparent">
              Corso AI
            </span>
            <Badge variant="info">Beta</Badge>
          </span>
          <small className="text-xs text-corso-gray-500">
            Ask a question to get insights from your data, start with a sample
            prompt or create your own.
          </small>
        </p>

        <ul
          style={{
            '--gap': '0.5rem', // aka gap-2
          }}
          className="flex flex-wrap gap-[--gap]"
        >
          {samplePrompts.map((prompt) => (
            <li
              key={prompt}
              className="flex-grow basis-[calc(50%-var(--gap))] md:flex-grow-0 md:basis-[calc(33.33%-var(--gap))]"
            >
              <SamplePromptButton
                label={prompt}
                onClick={() => {
                  resetField('question');
                  setPlaceholder(prompt);
                  // then fire the submit
                  setQuestionAndResponses((current) => [
                    ...current,
                    {
                      id: crypto.randomUUID(),
                      askedAt: new Date(),
                      question: prompt,
                      dataset: selectedDataset,
                    },
                  ]);
                }}
              />
            </li>
          ))}
        </ul>

        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            handleSubmit(({ question, dataset }) => {
              resetField('question');
              setQuestionAndResponses((current) => [
                ...current,
                {
                  id: crypto.randomUUID(),
                  askedAt: new Date(),
                  question: placeholder ? question || placeholder : question,
                  dataset,
                },
              ]);
            })(e).catch((error) => console.error(error));
          }}
        >
          <TextInput
            id="question"
            label="Ask a Question for Data Insights"
            labelVisuallyHidden
            // value={placeholder}
            placeholder={placeholder}
            {...register('question')}
            // delegate enter key to prefill with placeholder if empty
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              if (e.currentTarget.value || !placeholder) return;
              e.currentTarget.value = placeholder;
              // continue to submit after prefill
            }}
            addon={{
              insideEnd: (
                <IconAction.Button
                  title="Ask Question"
                  icon={SparklesIcon}
                  variant="ghost"
                  type="submit"
                />
              ),
            }}
            details={
              <p className="grid grid-cols-[auto_1fr] items-center gap-2 text-corso-gray-500 md:gap-1">
                <ExclamationCircleIcon className="h-4 w-4" />
                <span>
                  Responses may have mistakes, please verify important
                  information.
                </span>
              </p>
            }
          />
        </form>
        <Feed
          renderIcon={() => (
            <SparklesIconSolid className="h-4 w-4 text-corso-blue-600" />
          )}
          events={questionsAndResponses.sort(
            (a, b) => b.askedAt.valueOf() - a.askedAt.valueOf(),
          )}
        >
          {(event) => (
            <div className="flex flex-auto flex-col gap-1">
              <div className="flex flex-wrap items-center justify-between">
                <span className="text-sm text-corso-gray-500">
                  {event.question}
                </span>
                <div className="flex items-center gap-2">
                  <span className="text-xs leading-5 text-corso-gray-500">
                    asked <RelativeDateTime dateTime={event.askedAt} />
                  </span>
                </div>
              </div>

              <InsightResponse request={event} />
            </div>
          )}
        </Feed>
      </Card>
      <Card>
        <span className="flex items-center gap-2">
          <span className="bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-800 bg-clip-text text-xl font-bold text-transparent">
            Dataset
          </span>
        </span>
        <small className="text-xs text-corso-gray-500">
          Select an available dataset.
        </small>
        <div className="mb-6 flex gap-2">
          <Controller
            control={control}
            name="dataset"
            render={({ field, fieldState }) => (
              <SimpleSelect
                label="Dataset"
                labelVisuallyHidden
                options={enabledDatasets.map((dataset) => ({
                  value: `${dataset}` as const,
                  label: datasetDetails[dataset].displayName,
                }))}
                value={field.value}
                onChange={field.onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
        <span className="flex items-center gap-2">
          <span className="bg-gradient-to-br from-corso-blue-400 via-corso-blue-500 to-corso-blue-800 bg-clip-text text-xl font-bold text-transparent">
            Fields
          </span>
        </span>
        <small className="text-xs text-corso-gray-500">
          Fields available in the selected dataset.
        </small>

        {!datasetDetails[selectedDataset].columns.length ?
          <p className="max-w-prose text-sm text-corso-gray-700">
            No column information available for the selected dataset.
          </p>
        : <ul>
            {datasetDetails[selectedDataset].columns
              .sort((a, b) => a.displayName.localeCompare(b.displayName))
              .map(({ displayName, description }) => (
                <li
                  key={displayName}
                  className="flex items-center gap-0.5 bg-white p-2 px-4 last:rounded-b-xl even:bg-corso-gray-100"
                >
                  <span className="text-sm text-corso-gray-700">
                    {displayName}
                  </span>
                  {!!description && (
                    // would probably look better in a tooltip style instead of a popover
                    <InfoPopover title={displayName}>
                      <InfoPopover.Prose>{description}</InfoPopover.Prose>
                    </InfoPopover>
                  )}
                </li>
              ))}
          </ul>
        }
      </Card>
    </div>
  );
}
