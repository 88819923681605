import { Label } from '@radix-ui/react-label';
import { useMutation } from '@tanstack/react-query';
import { CrewMerchantUi, FinalizationEmailTypeEnum } from 'corso-types';
import { FormEventHandler, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import api from '~/api';
import Button from '~/components/Button';
import { SupportingText, TextAreaInput, TextInput } from '~/components/field';
import Modal from '~/components/Modal';
import SpinnerIcon from '~/components/SpinnerIcon';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useToast } from '~/hooks/useToast';
import { useClaimReviewContext } from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';

export default function RequestMoreInfo({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { storeUser, userFullName } = useMerchantContext();

  const { claimReview } = useClaimReviewContext();

  const { show: showToast } = useToast();

  const {
    claim: { id, externalId, shippingAddress, customerEmail },
  } = claimReview.getValues();

  const { data: emailSettings } = useConfigSettings(({ email }) => email);
  const emailTemplate = useMemo(
    () =>
      emailSettings?.storeEmailTemplates.finalizeEmailTemplates.find(
        (template) =>
          template.type === FinalizationEmailTypeEnum.claimNeedsMoreInfo,
      ),
    [emailSettings?.storeEmailTemplates.finalizeEmailTemplates],
  );

  const { register, handleSubmit, reset } = useForm<
    CrewMerchantUi.ClaimNeedsMoreInfo['notifyCustomerByEmail']
  >({
    defaultValues: {
      emailType: FinalizationEmailTypeEnum.claimNeedsMoreInfo,
      subject: emailTemplate?.subject ?? '',
      header: emailTemplate?.header ?? '',
      body: emailTemplate?.body ?? '',
    },
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  const {
    mutate: sendEmail,
    isPending,
    isError,
  } = useMutation({
    mutationKey: ['claimNeedsMoreInfoEmail', id],
    mutationFn: (
      notifyCustomerByEmail: CrewMerchantUi.ClaimNeedsMoreInfo['notifyCustomerByEmail'],
    ) =>
      api
        .store(String(storeUser.storeId))
        .claim(`${id}`, userFullName)
        .requestInfo({
          notifyCustomerByEmail,
          claim: {
            id,
            externalId,
            shippingAddress,
            customerEmail,
          },
        }),
    onSuccess: () => {
      closeAndReset();
      showToast('Email Sent');
    },
  });
  const formId = 'needs-more-info-email-form';

  const onSubmit: FormEventHandler = (event) => {
    handleSubmit((data) => {
      sendEmail(data);
      reset();
    })(event).catch(console.error);
  };

  return (
    <Modal
      show={show}
      title="Request More Information"
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
          >
            {isPending ?
              <SpinnerIcon />
            : 'Send'}
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
        className="flex flex-col gap-4"
      >
        <dl className="grid grid-cols-5 gap-2">
          <dt>
            <Label>Send From:</Label>
          </dt>
          <dd className="col-span-4">{emailSettings?.emailSendFrom}</dd>
          <dt>
            <Label>Send To:</Label>
          </dt>
          <dd className="col-span-4">{customerEmail}</dd>
        </dl>
        <TextInput
          id="notify-customer-subject"
          label="Subject"
          required
          {...register('subject', { required: true })}
        />
        <TextInput
          id="notify-customer-header"
          label="Header"
          required
          {...register('header', { required: true })}
        />
        <TextAreaInput
          id="notify-customer-body"
          label="Body"
          required
          {...register('body', { required: true })}
        />
        {isError && (
          <SupportingText error>
            Sorry, failed to send the email. Please try again.
          </SupportingText>
        )}
      </form>
    </Modal>
  );
}
