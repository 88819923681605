import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

const NestedContext = createContext(new Map<string, number>());

const useNested = () => useContext(NestedContext);

/** Predicate if a component keyed by `nest` is a nested descendant of another. */
export const useIsNested = (nest: string) => {
  const nested = useNested();
  return nested.has(nest);
};

/**
 * Get the absolute depth of nesting.
 * This counts the occurrences of a component keyed by `nest` in the hierarchy.
 * Non-nested components will return `undefined`.
 */
export const useNestedDepth = (nest: string) => {
  const nested = useNested();
  return nested.get(nest);
};

/**
 * Use to track if a component keyed by `nest` is a nested descendant of another.
 * This could be deeply nested, so any descendant will know.
 */
// inspired by https://www.aleksandrhovhannisyan.com/blog/react-context-nested-components/#making-it-more-reusable
export function NestedTracker({
  nest,
  children,
}: PropsWithChildren<{ nest: string }>) {
  const nested = useNested();
  const value = useMemo(() => {
    const depth = nested.get(nest) ?? 0;
    return new Map(nested).set(nest, depth + 1);
  }, [nest, nested]);

  return (
    <NestedContext.Provider value={value}>{children}</NestedContext.Provider>
  );
}
