import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { ThemeSettings, ThemeSettingsFormValues } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useToast } from './useToast';

const useStore = () => {
  const { storeUser } = useMerchantContext();
  return {
    storeId: storeUser.storeId.toString(),
    customerAppUrl: storeUser.store.customerAppUrl,
  };
};

const useQueryKey = () => {
  const { storeId } = useStore();
  return ['themeSettings', storeId] as const;
};

export const useThemeSettings = () => {
  const queryKey = useQueryKey();
  const { customerAppUrl } = useStore();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api
        .store(storeId)
        .settings.theme.get()
        .then((data) => ({
          ...data,
          customerAppUrl,
        })),
  });
};

export const useThemeSettingsUpdate = () => {
  const { storeId } = useStore();
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (data: Partial<ThemeSettingsFormValues>) => {
      const theme = queryClient.getQueryData<ThemeSettings>(queryKey);

      if (!theme) {
        throw new Error('Theme settings not found');
      }

      return api.store(storeId).settings.theme.update({ ...theme, ...data });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error) => {
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
      return error;
    },
  });
};
