import { useQuery } from '@tanstack/react-query';
import api from '~/api';
import { useStoreId } from './useStoreId';

export const useProductTags = () => {
  const storeId = useStoreId();
  const queryKey = ['productTags', storeId];

  return useQuery({
    queryKey,
    queryFn: () => api.store(storeId).product.tags(),
  });
};
