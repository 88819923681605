import { formatDistance } from 'date-fns';

function formatCurrencyString(currency: string, compact = false) {
  return Intl.NumberFormat(undefined, {
    notation: compact ? 'compact' : 'standard',
    style: 'currency',
    currency,
  });
}

export const formatter = {
  count: (value: number, compact = false) =>
    Intl.NumberFormat(undefined, {
      notation: compact ? 'compact' : 'standard',
      style: 'decimal',
    }).format(value),
  percent: (value: number, compact = false) =>
    Intl.NumberFormat(undefined, {
      notation: compact ? 'compact' : 'standard',
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 4,
      style: 'percent',
    }).format(value),
  currency: (value: number, currency: string, compact = false) =>
    formatCurrencyString(currency, compact).format(value),
  date: (date: string | number | Date) =>
    Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date)),
  dateTime: (date: string | number | Date) =>
    Intl.DateTimeFormat(undefined, {
      dateStyle: 'full',
      timeStyle: 'long',
    }).format(new Date(date)),
  relativeTimeFromNow: (date: Date) =>
    formatDistance(date, new Date(), { addSuffix: true }),
};
