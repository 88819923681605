import { z } from 'zod';
import { apiClientRespSchema } from '~/types';

export class ErrorResponse<T = unknown> extends Error {
  body: T;

  response: Response;

  constructor(message: string, response: Response, body: T) {
    super(message);
    this.body = body;
    this.response = response;
  }
}

export function isApiError(
  body: unknown,
): body is z.infer<typeof apiClientRespSchema> {
  return apiClientRespSchema.safeParse(body).success;
}
const errorMessageSchema = z.object({
  message: z.string(),
});

export function isErrorMessage(
  body: unknown,
): body is z.infer<typeof errorMessageSchema> {
  return errorMessageSchema.safeParse(body).success;
}
