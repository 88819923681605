import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { isNullish } from 'corso-types';
import { ComponentType, useState } from 'react';
import { z } from 'zod';
import Button from '~/components/Button';
import ButtonGroup from '~/components/ButtonGroup';
import { FieldGroup } from '~/components/field/FieldGroup';
import Icon from '~/components/Icon';
import { MultiSelectContent } from '~/components/ui/MultiSelect';
import { DatePicker } from '~/components/ui/primitives/DatePicker';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '~/components/ui/primitives/DropdownMenu';
import { Label } from '~/components/ui/primitives/Label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/primitives/Popover';
import { formatter } from '~/utils/formatter';

export enum FilterControlType {
  select = 'select', // radio buttons
  date = 'date', // pre-defined date ranges or custom date range
  multiselect = 'multiselect', // checkboxes w/ search if many
  boolean = 'boolean', // true/false -- select but will be converted to boolean at the request level
}
export enum DatePreset {
  today = '0d',
  last7Days = '7d',
  last30Days = '30d',
  last90Days = '90d',
  last12Months = '12m',
  custom = 'custom',
}

const filterOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});
type FilterOption = z.infer<typeof filterOptionSchema>;

const baseSchema = z.object({
  id: z.string(),
  label: z.string(),
});

export const selectFilterSchema = baseSchema.extend({
  type: z.literal(FilterControlType.select),
  options: z.array(filterOptionSchema),
  value: z.string().nullable().optional(),
});

export const multiSelectFilterSchema = baseSchema.extend({
  type: z.literal(FilterControlType.multiselect),
  options: z.array(filterOptionSchema),
  value: z.array(z.string()).nullable().optional(),
});

export const dateFilterSchema = baseSchema.extend({
  type: z.literal(FilterControlType.date),
  options: z.array(filterOptionSchema),
  value: z
    .union([
      z.object({
        preset: z.string(),
        range: z.never().optional(),
      }),
      z.object({
        preset: z.literal(`${DatePreset.custom}`),
        range: z.object({
          starting: z.coerce.date().nullable(),
          ending: z.coerce.date().nullable(),
        }),
      }),
    ])
    .nullable()
    .optional(),
});
type DateFilter = z.infer<typeof dateFilterSchema>;

export const booleanFilterSchema = baseSchema.extend({
  type: z.literal(FilterControlType.boolean),
  value: z.boolean().nullable().optional(),
  options: z.tuple([
    z.object({ label: z.string(), value: z.literal(true) }),
    z.object({ label: z.string(), value: z.literal(false) }),
  ]),
});

export const filterSchema = z.discriminatedUnion('type', [
  selectFilterSchema,
  multiSelectFilterSchema,
  dateFilterSchema,
  booleanFilterSchema,
]);

export type Filter = z.infer<typeof filterSchema>;

type ControlProps<
  T extends Filter['type'],
  F extends Extract<Filter, { type: T }> = Extract<Filter, { type: T }>,
> = {
  filter: F;
  onChange: (filter: F) => void;
  onClose: () => void;
  defaultOpen?: boolean;
};

function ClearButton({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <Button
      disabled={disabled}
      variant="text"
      className="text-xs"
      onClick={onClick}
    >
      Clear
    </Button>
  );
}

const formatTriggerLabel = ({
  label,
  options,
  value,
}: {
  label: string;
  options: FilterOption[];
  value?: DateFilter['value'] | null;
}) => {
  if (!value) {
    return label;
  }

  if (value.preset !== 'custom') {
    const selectedLabel = options.find(
      (option) => option.value === value.preset,
    )?.label;
    return `${label}${selectedLabel ? `: ${selectedLabel}` : ''}`;
  }

  const starting =
    value.range?.starting ? formatter.date(value.range.starting) : '';
  const ending = value.range?.ending ? formatter.date(value.range.ending) : '';

  if (starting && ending) {
    return `${label}: ${starting} - ${ending}`;
  }

  if (starting) {
    return `${label}: Starting ${starting}`;
  }

  if (ending) {
    return `${label}: Ending ${ending}`;
  }

  return label;
};

function DateMenu({
  filter,
  defaultOpen,
  onChange,
  onClose,
}: ControlProps<FilterControlType.date>) {
  const { label, options, value } = filter;
  const [selectedPreset, setSelectedPreset] = useState<
    NonNullable<DateFilter['value']>['preset'] | null
  >(value?.preset ?? null);

  const handleClear = () => {
    setSelectedPreset(null);
    onChange({ ...filter, value: null });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  const handleValueChange = (valueChange: string) => {
    setSelectedPreset(valueChange);

    if (valueChange !== 'custom') {
      onChange({ ...filter, value: { preset: valueChange } });
    }
  };

  const handleDateChange = (key: 'starting' | 'ending') => (date?: Date) => {
    onChange({
      ...filter,
      value: {
        preset: 'custom',
        range: {
          ...(value?.range ?? { starting: null, ending: null }),
          [key]: date,
        },
      },
    });
  };

  const triggerLabel = formatTriggerLabel({
    label,
    options,
    value: selectedPreset === 'custom' ? { ...value, preset: 'custom' } : value,
  });

  return (
    <DropdownMenu defaultOpen={defaultOpen} onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button className="text-xs font-medium">{triggerLabel}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {/* intentionally not using a scroll area, so that this always shows the entire content  */}
        <DropdownMenuRadioGroup
          value={selectedPreset ?? undefined}
          onValueChange={handleValueChange}
        >
          {options.map((option) => (
            <DropdownMenuRadioItem
              key={option.value}
              value={option.value}
              onSelect={(event) => event.preventDefault()}
            >
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        {selectedPreset === DatePreset.custom && (
          <div className="space-y-3 p-2">
            <FieldGroup>
              <Label htmlFor="starting">Starting</Label>
              <DatePicker
                id="starting"
                value={value?.range?.starting ?? undefined}
                onChange={handleDateChange('starting')}
                disabled={
                  !!value?.range?.ending && { after: value.range.ending }
                }
              />
            </FieldGroup>
            <FieldGroup>
              <Label htmlFor="ending">Ending</Label>
              <DatePicker
                id="ending"
                value={value?.range?.ending ?? undefined}
                onChange={handleDateChange('ending')}
                disabled={
                  !!value?.range?.starting && {
                    before: value.range.starting,
                  }
                }
              />
            </FieldGroup>
          </div>
        )}
        <div className="px-2 py-1">
          <ClearButton disabled={!value} onClick={handleClear} />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function MultiSelectPopover({
  filter,
  defaultOpen,
  onChange,
  onClose,
}: ControlProps<FilterControlType.multiselect>) {
  const { label, options, value } = filter;
  const currentValues = value ?? [];
  const selectedLabels = currentValues
    .map(
      (selected) => options.find((option) => option.value === selected)?.label,
    )
    .join(', ');

  const triggerLabel = `${label}${selectedLabels ? `: ${selectedLabels}` : ''}`;

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  return (
    // * modal prop necessary to prevent flicker and instant close of popover, but why this works is unclear
    <Popover defaultOpen={defaultOpen} onOpenChange={handleOpenChange} modal>
      <PopoverTrigger asChild>
        <Button className="text-xs font-medium">{triggerLabel}</Button>
      </PopoverTrigger>
      <PopoverContent align="start" className="p-0">
        <MultiSelectContent
          options={options}
          value={options.filter((option) =>
            currentValues.includes(option.value),
          )}
          onChange={(v) =>
            onChange({
              ...filter,
              value: v.length ? v.map((o) => o.value) : null,
            })
          }
          clearable
        />
      </PopoverContent>
    </Popover>
  );
}

function SelectMenu({
  filter,
  defaultOpen,
  onChange,
  onClose,
}: ControlProps<FilterControlType.select>) {
  const { label, options, value } = filter;
  const handleClear = () => {
    onChange({ ...filter, value: null });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  const selectedLabel = options.find((option) => option.value === value)?.label;
  const triggerLabel = `${label}${selectedLabel ? `: ${selectedLabel}` : ''}`;

  return (
    <DropdownMenu defaultOpen={defaultOpen} onOpenChange={handleOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button className="text-xs font-medium">{triggerLabel}</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuRadioGroup
          value={value ?? undefined}
          onValueChange={(v) => onChange({ ...filter, value: v })}
        >
          {options.map((option) => (
            <DropdownMenuRadioItem
              key={option.value}
              value={option.value}
              onSelect={(event) => event.preventDefault()}
            >
              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
        <div className="px-2 py-1">
          <ClearButton disabled={isNullish(value)} onClick={handleClear} />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function BooleanMenu({
  filter,
  defaultOpen,
  onChange,
  onClose,
}: ControlProps<FilterControlType.boolean>) {
  return (
    <SelectMenu
      filter={{
        ...filter,
        type: FilterControlType.select,
        value: isNullish(filter.value) ? null : `${filter.value}`,
        options: filter.options.map((o) => ({
          ...o,
          value: `${o.value}` as const,
        })),
      }}
      onChange={({ value }) =>
        onChange({
          ...filter,
          value: isNullish(value) ? value : value === 'true',
        })
      }
      defaultOpen={defaultOpen}
      onClose={onClose}
    />
  );
}

const controlComponents = {
  [FilterControlType.select]: SelectMenu,
  [FilterControlType.multiselect]: MultiSelectPopover,
  [FilterControlType.date]: DateMenu,
  [FilterControlType.boolean]: BooleanMenu,
} as const satisfies {
  [T in Filter['type']]: ComponentType<ControlProps<T>>;
};

type FilterProps<
  T extends Filter['type'],
  F extends Extract<Filter, { type: T }> = Extract<Filter, { type: T }>,
> = {
  filter: F;
  onChange: (filter: F) => void;
  defaultOpen?: boolean;
  onRemove(id: string): void;
};

export default function Filter<T extends Filter['type']>({
  filter,
  defaultOpen,
  onChange,
  onRemove,
}: FilterProps<T>) {
  const handleClose = () => {
    // need to check for null or undefined since boolean filters can be false
    if (filter.value === null || filter.value === undefined) {
      onRemove(filter.id);
    }
  };

  // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove assertions
  const Component = controlComponents[filter.type] as unknown as ComponentType<
    ControlProps<T>
  >;

  return (
    <ButtonGroup horizontal>
      {/* // TODO consider a lookup-based enforcement of type to filter control similar to automations */}

      <Component
        filter={filter}
        defaultOpen={defaultOpen}
        onChange={onChange}
        onClose={handleClose}
      />

      <Button className="text-xs" onClick={() => onRemove(filter.id)}>
        <span className="sr-only">Remove {filter.label}</span>
        <Icon icon={faXmark} className="size-3" />
      </Button>
    </ButtonGroup>
  );
}
