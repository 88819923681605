import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from 'react';
import IconAction from './IconAction';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from './ui/primitives/Popover';

export default function InfoPopover({
  title,
  children,
}: PropsWithChildren<{
  title: string;
}>) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <IconAction.Button
          icon={InformationCircleIcon}
          iconSize="sm"
          title={title}
          variant="ghost"
        />
      </PopoverTrigger>
      <PopoverContent align="start">{children}</PopoverContent>
    </Popover>
  );
}

function InfoPopoverProse({ children }: { children: string }) {
  return <p className="max-w-prose text-xs text-corso-gray-800">{children}</p>;
}

InfoPopover.Prose = InfoPopoverProse;
