import { forwardRef, Ref } from 'react';
import Input, { InputTypeProps } from './Input';

const UrlInput = forwardRef(
  ({ ...rest }: InputTypeProps<string>, ref: Ref<HTMLInputElement>) => (
    <Input type="url" ref={ref} {...rest} />
  ),
);

export default UrlInput;
