import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import Card from './Card';
import IconAction from './IconAction';

export default function DetailCard({
  linkTo,
  externalId,
  title,
  children,
}: {
  linkTo: string;
  externalId: string;
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <Card>
      <div className="space-y-3">
        <div className="mb-2 flex items-start justify-between text-sm font-medium">
          <div className="flex flex-col space-y-1">
            <Card.Title>{title}</Card.Title>
            <span className="text-xs text-corso-gray-500">#{externalId}</span>
          </div>

          <IconAction.Link
            title={`View ${title}`}
            className="items-start"
            icon={ArrowUpRightIcon}
            variant="ghost"
            iconSize="sm"
            to={linkTo}
          />
        </div>
        {children}
      </div>
    </Card>
  );
}
