import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { PropsWithChildren, useEffect } from 'react';
import { NavLink, useLocation, useRouteError } from 'react-router-dom';
import { useStoreId } from '~/hooks/useStoreId';
import Icon, { SupportedIcon } from './Icon';
import { Button } from './ui/primitives/Button';

export default function PageStatus({
  title,
  detail,
  icon,
  children,
}: PropsWithChildren<{
  title: string;
  detail: string;
  icon: SupportedIcon;
}>) {
  return (
    <section className="flex h-full w-full items-center justify-center">
      <div className="mx-auto flex flex-col items-center gap-6">
        <Icon icon={icon} className="size-16 opacity-50" />
        <p className="text-2xl font-semibold">{title}</p>
        <p className="max-w-prose text-pretty">{detail}</p>
        <div className="self-stretch">{children}</div>
      </div>
    </section>
  );
}

/** Displays an error page, while also logging the `error` or any route errors. */
function PageStatusError({ error }: { error?: unknown }) {
  const storeId = useStoreId();
  const location = useLocation();
  const routeError = useRouteError(); // ? maybe try to parse to see if we have a user-friendly error available too

  useEffect(() => {
    if (!routeError) return;
    console.error(routeError);
  }, [routeError]);

  useEffect(() => {
    if (!error) return;
    console.error(error);
  }, [error]);

  return (
    <PageStatus
      title="Something went wrong"
      detail="We encountered an unexpected issue. Try reloading this page or contact our support team if the problem persists."
      icon={faCircleExclamation}
    >
      <div className="flex flex-col gap-2">
        <Button asChild size="lg">
          <NavLink to={location.pathname} replace reloadDocument>
            Reload
          </NavLink>
        </Button>
        <Button asChild variant="link">
          <NavLink to={`/${storeId}`} replace reloadDocument>
            Go to Home
          </NavLink>
        </Button>
      </div>
    </PageStatus>
  );
}

PageStatus.Error = PageStatusError;
