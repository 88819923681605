import { PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import api from '~/api';
import Button from '~/components/Button';
import MediaDisplay from '~/components/MediaDisplay';
import { Label } from '~/components/ui/primitives/Label';

export type MediaAsset = {
  name: string;
  src: string;
  isVisibleToCustomer?: boolean;
};

/** The `MediaAsset.src` will be an object URL representing the file that must be revoked/freed to prevent memory leaks. */
type PreviewAsset = MediaAsset & { file: File };

type AssetDisplayProps = {
  asset: MediaAsset;
  onRemove: () => void;
  className?: string;
};

function RemovableMediaDisplay({
  asset,
  onRemove,
  className,
}: AssetDisplayProps) {
  return (
    <div
      className={twMerge('relative aspect-square w-full min-w-20', className)}
    >
      <MediaDisplay src={asset.src} alt={asset.name} />
      <Button className="absolute right-1 top-1" onClick={onRemove}>
        <XMarkIcon
          className="h-5 w-5"
          title={`Remove ${asset.name}`}
          aria-hidden="true"
        />
        <span className="sr-only">Remove {asset.name}</span>
      </Button>
    </div>
  );
}

// very similar to the `InputTypeProps` type
type MediaUploaderProps = {
  id: string;
  label: string;
  labelVisuallyHidden?: boolean;
  // ? possibly add `details`
  required?: boolean;
  assets?: MediaAsset[];
  onChange: (assets: MediaAsset[]) => void;
};

/**
 * Acts as a wrapper for strictly uncontrolled input for multiple images.
 * Manages previewing, selection, and uploading images via presigned URLs.
 * In the event an image is removed, the component does **NOT** handle deleting the image from the bucket.
 * Resulting in an array of metadata image objects represented the uploaded images.
 */
export default function MediaUploader({
  id,
  label,
  labelVisuallyHidden = false,
  required = false,
  assets = [],
  onChange,
}: MediaUploaderProps) {
  const ref = useRef<HTMLInputElement>(null);
  // images that are currently being uploaded, shown using ObjectURLs
  const [previewAssets, setPreviewAssets] = useState<PreviewAsset[]>([]);

  const removePreviewAsset = useCallback((preview: PreviewAsset) => {
    URL.revokeObjectURL(preview.src);
    setPreviewAssets((previews) => previews.filter((p) => p !== preview));
  }, []);

  const { mutate: uploadAsset, isPending } = useMutation({
    mutationFn: (previews: PreviewAsset[]) =>
      // TODO handle upload errors for each file
      Promise.all(previews.map(({ file }) => api.fileUpload.put(file))),
    onSuccess: (results, previews) => {
      onChange([...assets, ...results]); // add uploaded image to the list of images
      previews.map(removePreviewAsset); // remove the preview from the list of previews, it should now be in the list of images
    },
  });

  const clickInput = useCallback(() => ref.current?.click(), [ref]);
  const triggerUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      const files = [...event.target.files];
      const previews = files.map(
        (file) =>
          ({
            name: file.name,
            src: URL.createObjectURL(file),
            file,
          }) satisfies PreviewAsset,
      );

      setPreviewAssets(previews);
      uploadAsset(previews);
    },
    [uploadAsset],
  );

  const removeAsset = useCallback(
    (remove: MediaAsset) =>
      onChange(assets.filter((asset) => asset !== remove)),
    [assets, onChange],
  );

  return (
    <div role="group" className="flex flex-col gap-2">
      <div
        className={twMerge(
          'flex justify-between',
          labelVisuallyHidden && 'sr-only',
        )}
      >
        <Label htmlFor={id}>{label}</Label>
        {!required && (
          <span
            className="text-xs leading-6 text-corso-gray-500"
            id="images-optional"
          >
            Optional
          </span>
        )}
      </div>
      <Button onClick={clickInput} loading={isPending}>
        {isPending ?
          'Uploading'
        : <>
            <PhotoIcon className="h-5 w-5" aria-hidden="true" />
            Upload
          </>
        }
      </Button>
      <input
        id={id}
        type="file"
        accept="image/*, video/*"
        hidden
        multiple
        required={required}
        onChange={triggerUpload}
        ref={ref}
      />
      {!!assets.length && (
        <ul className="mt-2 grid grid-cols-2 gap-4 sm:grid-cols-4">
          {assets.map((asset) => (
            <li key={asset.name}>
              <RemovableMediaDisplay
                asset={asset}
                onRemove={() => removeAsset(asset)}
              />
            </li>
          ))}
          {previewAssets.map((asset) => (
            <li key={asset.src}>
              <RemovableMediaDisplay
                asset={asset}
                onRemove={() => removePreviewAsset(asset)}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
