import { cva, VariantProps } from 'class-variance-authority';
import { forwardRef, HTMLAttributes } from 'react';
import { cn } from '~/utils/shadcn';

// `hidden` attribute style necessary to ensure the base attribute works with the display styles
const fieldGroupVariants = cva('flex flex-col gap-1 [&[hidden]]:hidden');

export type FieldGroupProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof fieldGroupVariants>;

/** A, mostly, structural component for wrapping field-related elements; i.e. a `label` and `input` or `textarea`. */
const FieldGroup = forwardRef<HTMLDivElement, FieldGroupProps>(
  ({ className, ...props }, ref) => (
    <div
      className={cn(fieldGroupVariants({ className }))}
      ref={ref}
      {...props}
    />
  ),
);
FieldGroup.displayName = 'FieldGroup';

export { FieldGroup, fieldGroupVariants };
