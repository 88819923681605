import { useMerchantContext } from '~/providers/MerchantProvider';

/**
 * Provides the `storeId` of the currently selected store user.
 */
export const useStoreId = () => {
  const { storeUser } = useMerchantContext();

  return `${storeUser.storeId}` as const;
};
