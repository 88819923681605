import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { Ref, forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Input, { InputTypeProps } from './Input';

const PasswordInput = forwardRef(
  (
    { error, ...rest }: Omit<InputTypeProps<string>, 'addon'>,
    ref: Ref<HTMLInputElement>,
  ) => {
    const [visible, setVisible] = useState(false);
    return (
      <Input
        type={visible ? 'text' : 'password'}
        ref={ref}
        error={error}
        {...rest}
        addon={{
          insideEnd: (
            <button
              type="button"
              className={twMerge(
                'rounded-md focus:outline-none focus:ring-2 focus:ring-corso-blue-600 focus:ring-offset-2',
                !!error && 'focus:ring-corso-red-600',
              )}
              onClick={() => setVisible((prev) => !prev)}
            >
              <span className="sr-only">
                {visible ? 'Hide' : 'Show'} password
              </span>
              {visible ?
                <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
              : <EyeIcon className="h-5 w-5" aria-hidden="true" />}
            </button>
          ),
        }}
      />
    );
  },
);

export default PasswordInput;
