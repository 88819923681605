import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
} from 'react-router-dom';
import api from '~/api';
import { queryClient } from '~/providers/QueryProvider';
import { ACCESS_CODE_KEY } from '~/types';

type UserData = Awaited<ReturnType<typeof api.user.getWithAccessCode>>;
export type LoaderData = UserData;

const SignUpLoader = (async ({ request }) => {
  const url = new URL(request.url);
  const accessCode = url.searchParams.get(ACCESS_CODE_KEY);

  if (!accessCode) {
    return redirect('/');
  }

  const queryKey = [ACCESS_CODE_KEY, accessCode];

  try {
    const state = queryClient.getQueryState(queryKey);
    const user =
      state && !state.isInvalidated ?
        queryClient.getQueryData<UserData>(queryKey)
      : await api.user.getWithAccessCode(accessCode);

    queryClient.setQueryData(queryKey, user);

    if (!user) {
      throw new Error('User not found');
    }

    return json<LoaderData>(user);
  } catch (e) {
    return redirect('/');
  }
}) satisfies LoaderFunction;

export default SignUpLoader;

export const useSignUpLoaderData = () => useLoaderData() as LoaderData;
