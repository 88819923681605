import {
  CheckIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';

import { isTruthy } from 'corso-types';
import Virtualizer from '~/components/Virtualizer';
import { cn } from '~/utils/shadcn';
import { Badge } from './primitives/Badge';
import { Button } from './primitives/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './primitives/Command';
import { Label } from './primitives/Label';
import { Popover, PopoverContent, PopoverTrigger } from './primitives/Popover';

// TODO for a11y, should this use a dropdown menu over a popover

/**
 * Given a `predicate` function, returns a comparator function that sorts based on the predicate, where `true` results come before `false` results.
 */
function predicateCompare<T>(predicate: (value: T) => boolean) {
  return (a: T, b: T) => {
    const aSelected = predicate(a);
    const bSelected = predicate(b);
    if (aSelected && !bSelected) return -1; // a comes before b
    if (!aSelected && bSelected) return 1; // b comes before a
    return 0; // a and b are equal
  };
}

// ? is this now just an opinionated wrapper around `Command`
export function MultiSelectContent({
  options,

  value = [],
  onChange,

  emptyLabel,
  placeholder,
  estimateSize = 32,
  creatable = false,
  clearable = false,
  togglable = false,
}: {
  options: MultiSelectOption[];

  // TODO refactor `value` and `onChange` to be the value itself; similar to `Combobox` and `SimpleSelect`
  value?: MultiSelectOption[];
  onChange?: NoInfer<(value: MultiSelectOption[]) => void>;

  emptyLabel?: string;
  placeholder?: string;
  estimateSize?: number;

  creatable?: boolean;
  /** Allows clearing all selected options. */
  clearable?: boolean;
  // TODO consider alternative names
  /**
   * Allows toggling all options by way of selecting all or clearing all.
   * Using this option with `clearable` results in a clear all that's always visible, on it's own, it'll display the clear all only when all options are selected.
   */
  togglable?: boolean;
}) {
  const [inputValue, setInputValue] = useState('');

  const [createdValues, setCreatedValues] = useState<MultiSelectOption[]>(
    value.filter((v) => !options.some((o) => o.value === v.value)),
  );

  useEffect(() => {
    // given the `value` and `options` have changed, re-evaluate what is a created value including what internally might be marked as created that doesn't exist in the value or options yet
    setCreatedValues((currentCreatedValues) => {
      const createdOfValues = value.filter(
        (v) => !options.some((o) => o.value === v.value),
      );
      const createdInState = currentCreatedValues.filter(
        (v) =>
          !options.some((o) => o.value === v.value) &&
          !createdOfValues.some((o) => o.value === v.value),
      );
      return [...createdInState, ...createdOfValues];
    });
  }, [value, options]);

  const handleUnselect = useCallback(
    (selected: Pick<MultiSelectOption, 'value'>) => {
      onChange?.(value.filter((v) => v.value !== selected.value));
    },
    [onChange, value],
  );

  const isSelected = useCallback(
    (option: Pick<MultiSelectOption, 'value'>) =>
      value.some((selected) => selected.value === option.value),
    [value],
  );

  // possibly replace with `cmdk.defaultFilter` if it's released; https://github.com/pacocoursey/cmdk/pull/229#issuecomment-2318951732
  const searchPredicate = useCallback(
    (option: MultiSelectOption) => {
      const compareValue = inputValue.trim().toLowerCase();
      return [
        option.value,
        typeof option.label === 'string' ? option.label : null,
        ...(option.keywords ?? []),
      ]
        .flat()
        .filter(isTruthy)
        .some((v) => v.toLowerCase().includes(compareValue));
    },
    [inputValue],
  );

  const filteredOptions = useMemo(
    () => options.filter(searchPredicate),
    [options, searchPredicate],
  );

  const filteredCreated = useMemo(
    () => createdValues.filter(searchPredicate),
    [createdValues, searchPredicate],
  );

  const items = useMemo(
    () =>
      [filteredCreated, filteredOptions]
        .flat()
        .sort(predicateCompare(searchPredicate))
        .sort(predicateCompare((option) => isSelected(option))),
    [filteredCreated, filteredOptions, searchPredicate, isSelected],
  );

  return (
    <Command shouldFilter={false}>
      <CommandInput
        aria-label={placeholder}
        placeholder={placeholder}
        value={inputValue}
        onValueChange={setInputValue}
        onKeyDown={(event) => {
          if (inputValue) return;
          if (!['Delete', 'Backspace'].includes(event.key)) return;

          const lastSelectOption = value.at(-1);
          if (!lastSelectOption) return;

          handleUnselect(lastSelectOption);
        }}
      />
      {/* bypass command list styling and let height be handled by the `Virtualizer` */}
      <CommandList className="contents">
        <CommandEmpty>
          {emptyLabel ??
            (creatable ?
              'Nothing found, search to create something new.'
            : 'Nothing Found.')}
        </CommandEmpty>
        {/* we may want re-evaluate so clear and select all meta can remain as one item so they share focus when toggled */}
        {/* meta item: clear */}
        {(clearable || (togglable && options.length === value.length)) && (
          <CommandGroup>
            <CommandItem
              disabled={!value.length}
              onSelect={() => {
                onChange?.([]);
              }}
            >
              <XMarkIcon className="mr-2 h-4 w-4" />
              Clear
            </CommandItem>
          </CommandGroup>
        )}
        {/* meta item: toggle to select all */}
        {togglable && !!options.length && value.length !== options.length && (
          <CommandGroup>
            <CommandItem onSelect={() => onChange?.(options)}>
              <PlusIcon className="mr-2 h-4 w-4" />
              Select All
            </CommandItem>
          </CommandGroup>
        )}
        {/* meta item: create a new item */}
        {creatable &&
          inputValue &&
          filteredOptions.every((option) => option.value !== inputValue) && (
            <CommandGroup>
              <CommandItem
                value={inputValue}
                onSelect={() => {
                  const createdValue = {
                    value: inputValue,
                    label: inputValue,
                  } satisfies MultiSelectOption;
                  // might be better with a `onCreate` callback instead of `onChange`, but there's a consideration of how to handle this when controlled/uncontrolled
                  setCreatedValues([...createdValues, createdValue]);
                  onChange?.([...value, createdValue]);
                  setInputValue('');
                }}
              >
                <PlusIcon className="mr-2 h-4 w-4" />
                Add &quot;{inputValue}&quot;
              </CommandItem>
            </CommandGroup>
          )}
        {!items.length ? null : (
          <CommandGroup>
            <Virtualizer
              items={items}
              estimateSize={() => estimateSize} // size of `CommandItem` when rendered
            >
              {(option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  keywords={option.keywords}
                  onSelect={() => {
                    if (isSelected(option)) handleUnselect(option);
                    else onChange?.([...value, option]);

                    setInputValue('');
                  }}
                >
                  <CheckIcon
                    className={cn(
                      'mr-2 h-4 w-4 shrink-0',
                      isSelected(option) ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {option.label}
                </CommandItem>
              )}
            </Virtualizer>
          </CommandGroup>
        )}
      </CommandList>
    </Command>
  );
}

// TODO consider unbounded generic type `T` or bounded `T extends string` works as expected
// TODO add optional `details` prop to match other field-like components
// TODO refactor creatable item
// TODO redesign usage of `searchValue` to leverage [`keywords`](https://github.com/pacocoursey/cmdk#:~:text=A%20third%20argument%2C-,keywords,-%2C%20can%20also%20be) instead

/**
 * The `value` of each option is expected to be unique, as it is also used as the `key`.
 * The `label` can be any `ReactNode` to represent the option in the UI; however, it's suggested to keep it mostly prose.
 */
export type MultiSelectOption = {
  value: string;
  /** Aliases for the option to use while ranking and searching. */
  keywords?: string[];
  label: ReactNode;
};

/**
 * Fundamentally, is an alternative to the `Combobox` component, except it allows for multiple selections, and optional creation.
 */
export function MultiSelect({
  label,
  labelVisuallyHidden = false,

  details,
  error,

  visibleLimit = 5,

  defaultOpen = false,

  // MultiSelectContent props
  value = [],
  placeholder,
  ...additionalMultiSelectContentProps
}: {
  /**
   * A label for the selection, to provide context for the user.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  label: ReactNode;
  // TODO consider alternative names
  labelVisuallyHidden?: boolean;

  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  error?: ReactNode;

  /**
   * The maximum number of selected options to display before truncating with a `+n` count of the remaining options.
   * @default 5
   */
  visibleLimit?: number;

  defaultOpen?: boolean;
} & ComponentProps<typeof MultiSelectContent>) {
  const id = useId();

  const [open, setOpen] = useState(defaultOpen);

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={id} className={cn(labelVisuallyHidden && 'sr-only')}>
        {label}
      </Label>
      <Popover
        open={open}
        onOpenChange={setOpen}
        modal // capture outside interaction, useful if within another modal/dialog
      >
        <PopoverTrigger asChild id={id}>
          <Button
            role="combobox"
            variant="outline"
            aria-expanded={open}
            // may need to revisit this being full width instead of mostly intrinsic width
            className="w-full justify-between gap-2"
          >
            {value.length ?
              <div className="flex flex-wrap gap-1">
                {value.slice(0, visibleLimit).map((selected) => (
                  <Badge key={selected.value}>{selected.label}</Badge>
                ))}
                {visibleLimit && value.length > visibleLimit && (
                  <Badge variant="info">
                    +{value.length - visibleLimit} More
                  </Badge>
                )}
              </div>
            : <span
                className={cn(
                  'flex items-center gap-2 font-normal text-neutral-500',
                  open && 'invisible',
                )}
              >
                <MagnifyingGlassIcon className="h-4 w-4 shrink-0 opacity-50" />
                {placeholder}
              </span>
            }
            <ChevronDownIcon
              className={cn(
                'h-4 w-4 shrink-0 opacity-50 transition-all duration-100',
                open && '-rotate-180',
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <MultiSelectContent
            value={value}
            placeholder={placeholder}
            {...additionalMultiSelectContentProps}
          />
        </PopoverContent>
      </Popover>
      {/* // TODO should these both use `SupportingText` */}
      {error && (
        <section className="text-xs text-corso-red-600">{error}</section>
      )}
      {details && (
        <section className="text-xs text-corso-gray-500">{details}</section>
      )}
    </div>
  );
}
