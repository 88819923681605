import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';

export type ComparableAmount = {
  /**
   * The current monetary value.
   * This could be the same as the `compareAmount` if the value has not changed.
   */
  amount: number;
  /**
   * The a monetary value to compare against.
   * If provided and different from `amount`, will be displayed with a line-through.
   */
  compareAmount?: number;
};

/**
 * A purely text line item that displays a name associated with a monetary value.
 * Similar to what might be seen on a receipt without any indication of quantity.
 */
export default function AmountLine({
  name,
  amount,
  compareAmount,
}: ComparableAmount & {
  name: string;
}) {
  const {
    storeUser: {
      store: { currencyCode },
    },
  } = useMerchantContext();

  return (
    <div className="flex justify-between gap-2 text-xs text-corso-gray-500">
      <p>{name}</p>
      <p className="flex gap-1">
        {compareAmount !== undefined && compareAmount !== amount && (
          <span className="line-through">
            {formatter.currency(compareAmount, currencyCode)}
          </span>
        )}
        <span>{formatter.currency(amount, currencyCode)}</span>
      </p>
    </div>
  );
}
