import { Key } from 'react';
import LineItem, { LineItemProps } from './LineItem';

export type LineItemListLineItem = Omit<LineItemProps, 'children'> & {
  key: Key;
};
export default function LineItemList({
  lineItems,
}: {
  lineItems: LineItemListLineItem[];
}) {
  return (
    <div className="flex flex-col gap-4">
      {lineItems.map(({ key, ...lineItemProps }) => (
        <LineItem
          variant="small"
          key={key}
          /* eslint-disable-next-line react/jsx-props-no-spreading */ // intentional, to allow all items listed to leverage all available props defined via the type
          {...lineItemProps}
        />
      ))}
    </div>
  );
}
