import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { forwardRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from '~/components/Icon';
import { Button } from '~/components/ui/primitives/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/primitives/DropdownMenu';
import FilterControl, { Filter } from './Filter';

type AddFilterMenuProps = {
  options: { id: string; label: string }[];
  onSelect: (id: string) => void;
};

function AddFilterMenu({ options, onSelect }: AddFilterMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="min-h-fit gap-1 px-1.5 py-1.5 text-xs"
        >
          Add filter <Icon icon={faPlus} className="size-3" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {options.map(({ id, label }) => (
          <DropdownMenuItem
            key={id}
            onSelect={() => onSelect(id)}
            className="text-xs"
          >
            {label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

type FiltersProps = {
  filterOptions: Record<string, Filter>;
  activeFilters: Record<string, Filter>;
  onChange: (changed: Filter) => void;
  onRemoveFilters: (id?: string[]) => void;
  className?: string;
};

const Filters = forwardRef<HTMLDivElement, FiltersProps>(
  (
    { filterOptions, activeFilters, className, onChange, onRemoveFilters },
    ref,
  ) => {
    const notActiveOptions = useMemo(
      () =>
        Object.values(filterOptions)
          .filter((filter) => !activeFilters[filter.id])
          .map(({ id, label }) => ({ id, label })),
      [filterOptions, activeFilters],
    );

    const handleChange = (updated: Filter) => {
      onChange(updated);
    };

    const handleRemove = (id: string) => {
      onRemoveFilters([id]);
    };

    const handleNewFilter = (id: string) => {
      const filter = filterOptions[id];
      if (filter) onChange(filter);
    };

    const handleClear = () => {
      onRemoveFilters(Object.keys(activeFilters));
    };

    return (
      <div
        className={twMerge(
          'flex flex-wrap items-center gap-2 overflow-x-auto',
          className,
        )}
        ref={ref}
      >
        {Object.values(activeFilters).map((filter) => (
          <FilterControl
            key={filter.id}
            // have the menu open if the filter doesn't have a value like a new filter
            defaultOpen={!filter.value}
            filter={filter}
            onChange={handleChange}
            onRemove={handleRemove}
          />
        ))}

        {notActiveOptions.length > 0 && (
          <AddFilterMenu
            options={notActiveOptions}
            onSelect={handleNewFilter}
          />
        )}
        {Object.keys(activeFilters).length > 0 && (
          <Button
            className="min-h-fit px-1.5 py-1.5 text-xs"
            variant="outline"
            onClick={handleClear}
          >
            Clear all
          </Button>
        )}
      </div>
    );
  },
);

export default Filters;
