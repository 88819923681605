import { faCalendarExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import PageStatus from './PageStatus';
import { Button } from './ui/primitives/Button';

export default function SubscriptionExpired() {
  return (
    <PageStatus
      title="Subscription Expired"
      detail="You have reached the end of your subscription. To regain access, simply reach out to continue using the Corso app, free forever."
      icon={faCalendarExclamation}
    >
      <Button asChild variant="primary" className="w-full" size="lg">
        <Link to="https://corso.com/book-a-time">Contact Us</Link>
      </Button>
    </PageStatus>
  );
}
