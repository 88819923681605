import {
  faAddressBook as faAddressBookLight,
  faAt as faAtLight,
  faBadgeCheck as faBadgeCheckLight,
  faBell as faBellLight,
  faBoxCheck as faBoxCheckLight,
  faBox as faBoxLight,
  faExchange as faExchangeLight,
  faFeather as faFeatherLight,
  faGrid2 as faGrid2Light,
  faHatWizard as faHatWizardLight,
  faHexagonPlus as faHexagonPlusLight,
  faPaintBrush as faPaintBrushLight,
  faPlugCirclePlus as faPlugCirclePlusLight,
  faRectangleHistory as faRectangleHistoryLight,
  faTags as faTagsLight,
  faUserGroupSimple as faUserGroupSimpleLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAddressBook as faAddressBookSolid,
  faArrowLeft,
  faAt as faAtSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBell as faBellSolid,
  faBoxCheck as faBoxCheckSolid,
  faBox as faBoxSolid,
  faExchange as faExchangeSolid,
  faFeather as faFeatherSolid,
  faGrid2 as faGrid2Solid,
  faHatWizard as faHatWizardSolid,
  faHexagonPlus as faHexagonPlusSolid,
  faPaintBrush as faPaintBrushSolid,
  faPlugCirclePlus as faPlugCirclePlusSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faTags as faTagsSolid,
  faUserGroupSimple as faUserGroupSimpleSolid,
  faXmark,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import {
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
} from 'corso-types';
import { ReactNode, useEffect, useState } from 'react';
import {
  NavLink,
  Outlet,
  To,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import Card from '~/components/Card';
import { Button } from '~/components/ui/primitives/Button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHandle,
  DrawerHeader,
  DrawerTitle,
} from '~/components/ui/primitives/Drawer';
import { useSearchParam } from '~/hooks/useSearchParam';
import { useStoreId } from '~/hooks/useStoreId';

export const queryParamKey = {
  returnTo: 'return-to',
  settingsNavigation: 'settings-navigation',
} as const;

const links = [
  {
    name: 'Theme',
    to: 'theme',
    icon: faPaintBrushLight,
    activeIcon: faPaintBrushSolid,
  },

  {
    name: 'Users',
    to: 'users',
    icon: faUserGroupSimpleLight,
    activeIcon: faUserGroupSimpleSolid,
  },
  {
    name: 'Integrations',
    to: 'integrations',
    icon: faPlugCirclePlusLight,
    activeIcon: faPlugCirclePlusSolid,
  },
  {
    name: crewClaimTypeEnumPluralizedName[CrewClaimTypeEnum.return],
    to: 'returns',
    icon: faExchangeLight,
    activeIcon: faExchangeSolid,
  },
  {
    name: crewClaimTypeEnumPluralizedName[CrewClaimTypeEnum.warranty],
    to: 'warranties',
    icon: faBadgeCheckLight,
    activeIcon: faBadgeCheckSolid,
  },
  {
    name: 'Shipping Plus',
    to: 'shipping-plus',
    icon: faHexagonPlusLight,
    activeIcon: faHexagonPlusSolid,
  },
  {
    name: 'Shipping Claims',
    to: 'shipping-claims',
    icon: faBoxCheckLight,
    activeIcon: faBoxCheckSolid,
  },

  {
    name: 'Registrations',
    to: 'registrations',
    icon: faAddressBookLight,
    activeIcon: faAddressBookSolid,
  },

  {
    name: 'Automations',
    to: 'automations',
    icon: faHatWizardLight,
    activeIcon: faHatWizardSolid,
  },

  {
    name: 'Email',
    to: 'email',
    icon: faAtLight,
    activeIcon: faAtSolid,
  },
  {
    name: 'Reasons',
    to: 'reasons',
    icon: faGrid2Light,
    activeIcon: faGrid2Solid,
  },
  {
    name: 'Custom Fields',
    to: 'custom-fields',
    icon: faFeatherLight,
    activeIcon: faFeatherSolid,
  },
  {
    name: 'Product Groups',
    to: 'product-groups',
    icon: faRectangleHistoryLight,
    activeIcon: faRectangleHistorySolid,
  },
  {
    name: 'Notifications',
    to: 'notifications',
    icon: faBellLight,
    activeIcon: faBellSolid,
  },
  {
    name: 'Shipping Policies',
    to: 'shipping-policies',
    icon: faBoxLight,
    activeIcon: faBoxSolid,
  },
  {
    name: 'Claim Tags',
    to: 'claim-tags',
    icon: faTagsLight,
    activeIcon: faTagsSolid,
  },
] satisfies {
  name: ReactNode;
  to: To;
  icon: IconDefinition;
  activeIcon: IconDefinition;
}[];

const sidebarActionVariants = cva(
  'flex items-center gap-x-3 rounded-md p-2 text-xs font-semibold',
  {
    variants: {
      active: {
        [`${false}`]: 'text-neutral-900 hover:bg-neutral-100',
        [`${true}`]: 'bg-neutral-50 text-corso-blue-700',
      },
      kind: {
        button: 'w-full', // special for the button so it fills the width like the other links
      },
    },
    defaultVariants: {
      active: `${false}`,
    },
  },
);

export default function SettingsLayout() {
  const storeId = useStoreId();
  const location = useLocation();
  const returnToSearchParam = useSearchParam(queryParamKey.returnTo);
  const isSettingsNavigation =
    useSearchParam(queryParamKey.settingsNavigation) !== null;
  const navigate = useNavigate();
  const [returnTo, setReturnTo] = useState<string | null>(null);

  useEffect(() => {
    if (!returnToSearchParam) return; // don't set it if it's empty; always keep the last set value when rendering the layout
    setReturnTo(returnToSearchParam);
  }, [returnToSearchParam]);

  return (
    // TODO fix onOpenChange to always navigate to close the drawer
    <Drawer open onOpenChange={() => navigate(returnTo ?? `/${storeId}`)}>
      <DrawerContent
        className="group/drawer h-dvh bg-neutral-100 md:h-[95%]"
        data-settings-navigation={isSettingsNavigation}
      >
        <DrawerTitle className="sr-only">Settings</DrawerTitle>
        <DrawerDescription className="sr-only">
          Settings navigation.
        </DrawerDescription>
        <DrawerHeader>
          <DrawerHandle className="data-[vaul-handle]:md:hidden" />
        </DrawerHeader>
        <NavLink
          className="hidden items-center gap-1 px-4 group-data-[settings-navigation=false]/drawer:flex md:group-data-[settings-navigation=false]/drawer:hidden"
          to={{
            pathname: location.pathname,
            search: new URLSearchParams({
              [queryParamKey.settingsNavigation]: 'true', // existence would be enough; i.e. an empty string could work too
            }).toString(),
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          Back
        </NavLink>
        <DrawerClose asChild>
          <Button
            variant="ghost"
            size="icon"
            className="absolute right-0 top-0 md:right-2 md:top-2"
          >
            <span className="sr-only">Close</span>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </DrawerClose>
        <div className="mx-auto grid max-w-screen-xl grid-cols-1 gap-4 p-4 md:grid-cols-[minmax(auto,15rem),1fr] md:p-8">
          <aside className="group-data-[settings-navigation=false]/drawer:hidden md:group-data-[settings-navigation=false]/drawer:block">
            <Card flush>
              <nav>
                <ul className="flex flex-col gap-1 p-1">
                  {links.map((link) => (
                    <li key={link.to}>
                      <NavLink
                        to={link.to}
                        className={({ isActive }) =>
                          sidebarActionVariants({ active: `${isActive}` })
                        }
                      >
                        {({ isActive }) => (
                          <>
                            {/* // TODO consider a toggleable link, like the `SidebarIcon` */}
                            <FontAwesomeIcon
                              icon={isActive ? link.activeIcon : link.icon}
                              fixedWidth
                              size="lg"
                            />
                            {link.name}
                          </>
                        )}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </Card>
          </aside>
          <article className="group-data-[settings-navigation=true]/drawer:hidden md:group-data-[settings-navigation=true]/drawer:block">
            <Outlet />
          </article>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
