import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CrewClaimResolutionMethodEnum,
  CrewClaimStatusCode,
  crewClaimStatuses,
  CrewMerchantUi,
  FinalizationEmailTypeEnum,
} from 'corso-types';
import { groupBy } from 'corso-utils/map';
import { ReactNode, useCallback, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import ConfirmWithBlocker from '~/components/ConfirmWithBlocker';
import { totalPrice } from '~/components/LineItem';
import { Loading } from '~/components/Loading';
import PageStatus from '~/components/PageStatus';
import { usePathParams } from '~/hooks/usePathParams';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from './MerchantProvider';

const {
  claimFinalizePayloadSchema: claimFinalizePayload,
  claimFinalizeLineItemDenialSchema: denyClaimLineItem,
  claimFinalizeLineItemRefundSchema: approvedRefund,
  claimFinalizeLineItemGiftCardSchema: approvedGiftCard,
  claimFinalizeLineItemBaseSchema: baseClaimLineItemProcess,
  claimFinalizeLineItemVariantExchangeSchema: approvedVariantExchange,
  claimFinalizeReplacementOrderSchema: approvedReplacementOrder,
  claimLineItemSchema,
} = CrewMerchantUi;

const denialSchema = denyClaimLineItem.omit({ noteToCustomer: true });

const approvalSchema = z.discriminatedUnion('resolutionMethodEnum', [
  // TODO support all resolution methods for review // ? maybe find a way to extract this from the payload schema
  approvedRefund.omit({ noteToCustomer: true }),
  approvedGiftCard.omit({ noteToCustomer: true }),
  approvedVariantExchange.omit({ noteToCustomer: true }),
  approvedReplacementOrder.omit({ noteToCustomer: true }),
]);

/**
 * Represents a resolution method that can be approved, from those that can be finalized.
 * Resolution method that **actually** demonstrates support to be approved as an `approveClaimLineItem`.
 */
export type ApproveResolutionMethod = z.infer<
  typeof approvalSchema
>['resolutionMethodEnum'];

/**
 * Resolution method expected to have support as defined by the API.
 * Intentionally not exported, as it is only used to assure types.
 * Has a redundant `typeof`; however, it's necessary as it provides assured keys for resulting union.
 */
type FinalizationApprovalResolutionMethod = z.infer<
  typeof claimFinalizePayload
>['claim']['claimLineItems']['approved'][keyof z.infer<
  typeof claimFinalizePayload
>['claim']['claimLineItems']['approved']][number]['resolutionMethodEnum'];

/**
 * Represents all supported and reviewable resolution methods mapped to themselves.
 * Useful to narrow types and only process reviewable resolution methods.
 * Additionally, if a resolution method is not actually supported as an `approveClaimLineItem`, this will error indicated which resolution method is missing/not supported in either the API or the UI.
 */
export const approveResolutionMethod = {
  [CrewClaimResolutionMethodEnum.refund]: CrewClaimResolutionMethodEnum.refund,
  [CrewClaimResolutionMethodEnum.giftCard]:
    CrewClaimResolutionMethodEnum.giftCard,
  [CrewClaimResolutionMethodEnum.variantExchange]:
    CrewClaimResolutionMethodEnum.variantExchange,
  [CrewClaimResolutionMethodEnum.replacementOrder]:
    CrewClaimResolutionMethodEnum.replacementOrder,
} as const satisfies Record<
  FinalizationApprovalResolutionMethod,
  Extract<FinalizationApprovalResolutionMethod, ApproveResolutionMethod>
>;

/**
 * Represents the resolutions that can be changed into another.
 * Given a requested resolution as a key, represents the resulting resolutions that are allowed.
 * Alike transitions for a state machine.
 * Any resolution that can be transitioned to itself represents a resolution that can be approved as requested.
 */
// TODO consider alternative names
// ! TODO clean up and improve this entire file, so it's really easy to understand and adjust available functionality
export const resolutionMethodChangesAllowed = {
  [CrewClaimResolutionMethodEnum.refund]: [
    CrewClaimResolutionMethodEnum.refund, // * refund can be changed to refund for partial approval
    CrewClaimResolutionMethodEnum.giftCard,
    CrewClaimResolutionMethodEnum.replacementOrder,
  ],
  [CrewClaimResolutionMethodEnum.giftCard]: [
    CrewClaimResolutionMethodEnum.giftCard, // * gift card can be changed to gift card for partial approval
    CrewClaimResolutionMethodEnum.refund,
    CrewClaimResolutionMethodEnum.replacementOrder,
  ],
  [CrewClaimResolutionMethodEnum.variantExchange]: [
    CrewClaimResolutionMethodEnum.refund,
    CrewClaimResolutionMethodEnum.giftCard,
    // CrewClaimResolutionMethodEnum.replacementOrder, // ! disabled for now, because this can result in multiple orders and neither UI shows this correctly; i.e. a replacement order and a variant exchange
  ],
  [CrewClaimResolutionMethodEnum.warrantyReview]: [
    CrewClaimResolutionMethodEnum.replacementOrder,
    CrewClaimResolutionMethodEnum.giftCard,
    CrewClaimResolutionMethodEnum.refund,
    // ? maybe support `repair` as a resulting resolution
  ],
  // ? maybe add `replacementOrder` with transitions to support review
} as const satisfies Partial<
  Record<
    CrewClaimResolutionMethodEnum,
    readonly CrewClaimResolutionMethodEnum[]
  >
>;

/** Resolution methods that are allowed to be reviewed, and contain support for transition to other resolutions. */
export type ReviewableResolutionMethod =
  keyof typeof resolutionMethodChangesAllowed;

// ? the `Extract` utility doesn't work here, but I'm not sure why
export type ModifiableReviewLineItem = ReviewLineItem & {
  claimLineItem: CrewMerchantUi.ClaimLineItem & {
    requestedResolutionMethodEnum: `${ReviewableResolutionMethod}`;
  };
};

/** Determines if a claim line item can be changed to another resolution method from the requested resolution. */
export function canChangeResolution(
  reviewLineItem: ReviewLineItem,
): reviewLineItem is ModifiableReviewLineItem {
  return (
    reviewLineItem.claimLineItem.requestedResolutionMethodEnum in
    resolutionMethodChangesAllowed
  );
}

export const reviewableResolutionMethods = Object.values(
  CrewClaimResolutionMethodEnum,
).filter(
  (resolutionMethod): resolutionMethod is ReviewableResolutionMethod =>
    resolutionMethod in resolutionMethodChangesAllowed,
);

/**
 * Resolution methods which can be the result of a review.
 * The result of the transitions available.
 * Can provide a specific reviewable resolution method to get the resolvable resolution methods for it.
 */
export type TransformableResolutionMethod =
  (typeof resolutionMethodChangesAllowed)[ReviewableResolutionMethod][number];

export enum ReviewMetaStatus {
  undecided = 'undecided',
  approvingAsRequested = 'approvingAsRequested',
  approvingWithModification = 'approvingWithModification', // counts for approved as requested, partial approvals, and approvals with modified resolutions
  denying = 'denying',
}

// TODO elevate `noteToCustomer` to `reviewLineItem` and omit from denial and approvals; then simplify note to customer updating
const reviewMetadataSchema = z.discriminatedUnion('status', [
  z.object({
    status: z.literal(ReviewMetaStatus.undecided),
  }),
  z.object({
    status: z.literal(ReviewMetaStatus.denying),
    denial: denialSchema,
  }),
  z.object({
    status: z.literal(ReviewMetaStatus.approvingAsRequested),
    approval: approvalSchema,
  }),
  z.object({
    status: z.literal(ReviewMetaStatus.approvingWithModification),
    approval: approvalSchema,
  }),
]);

const reviewLineItemSchema = z.object({
  claimLineItem: claimLineItemSchema,
  noteToCustomer: z.string().nullable(),
  reviewMetadata: reviewMetadataSchema,
});

export type ReviewLineItem = z.infer<typeof reviewLineItemSchema>;

export function isRequestedAs<T extends CrewClaimResolutionMethodEnum>(
  resolutionMethod: T,
) {
  return <R extends ReviewLineItem>(
    reviewLineItem: R,
  ): reviewLineItem is R & {
    claimLineItem: { requestedResolutionMethodEnum: T };
  } =>
    reviewLineItem.claimLineItem.requestedResolutionMethodEnum ===
    resolutionMethod;
}

export function isMetaStatus<T extends ReviewMetaStatus>(status: T) {
  return <R extends ReviewLineItem>(
    reviewLineItem: R,
  ): reviewLineItem is R & {
    reviewMetadata: {
      status: T;
    };
  } => reviewLineItem.reviewMetadata.status === status;
}

// possibly find a safer way to do this, to extract the type instead
export type ResolvableReviewItem<T extends ResolvableResolutionMethod> =
  ReviewLineItem & {
    reviewMetadata: {
      status:
        | ReviewMetaStatus.approvingAsRequested
        | ReviewMetaStatus.approvingWithModification;
      approval: {
        resolutionMethodEnum: T;
      };
    };
  };

/** Creates a predicate to identify a review line item that is approved with a given **resolved resolution** method. */
export function isResolvedAs<T extends ResolvableResolutionMethod>(
  resolutionMethod: T,
) {
  const approvedAsRequested = isMetaStatus(
    ReviewMetaStatus.approvingAsRequested,
  );
  const approvedWithModification = isMetaStatus(
    ReviewMetaStatus.approvingWithModification,
  );

  return <R extends ReviewLineItem>(
    reviewLineItem: R,
  ): reviewLineItem is R & ResolvableReviewItem<T> =>
    (approvedAsRequested(reviewLineItem) ||
      approvedWithModification(reviewLineItem)) &&
    reviewLineItem.reviewMetadata.approval.resolutionMethodEnum ===
      resolutionMethod;
}

export type ApprovingReviewLineItem = ReviewLineItem & {
  reviewMetadata: {
    status:
      | ReviewMetaStatus.approvingAsRequested
      | ReviewMetaStatus.approvingWithModification;
  };
};

type DenyingReviewLineItem = ReviewLineItem & {
  reviewMetadata: { status: ReviewMetaStatus.denying };
};

/** Creates a predicate to identify a review line item that is denied for a given **requested resolution** method. */
export function isDeniedAs<T extends ReviewableResolutionMethod>(
  requestedResolutionMethod: T,
) {
  // ? might need to be a type predicate
  return <R extends ReviewLineItem>(
    reviewLineItem: R,
  ): reviewLineItem is R & DenyingReviewLineItem =>
    isMetaStatus(ReviewMetaStatus.denying)(reviewLineItem) &&
    isRequestedAs(requestedResolutionMethod)(reviewLineItem);
}

type UndecidedReviewLineItem = ReviewLineItem & {
  reviewMetadata: { status: ReviewMetaStatus.undecided };
};

/** Grouping all the line items into their respective **requested resolution methods** for easier processing and review. */
const reviewLineItemsSchema = z.object({
  // * can be finalized as-is; i.e. approved as requested
  [CrewClaimResolutionMethodEnum.refund]: z.array(reviewLineItemSchema),
  [CrewClaimResolutionMethodEnum.giftCard]: z.array(reviewLineItemSchema),
  [CrewClaimResolutionMethodEnum.variantExchange]:
    z.array(reviewLineItemSchema),
  // * can only be finalized with a modified resolution; review necessary
  [CrewClaimResolutionMethodEnum.warrantyReview]: z.array(reviewLineItemSchema),

  [CrewClaimResolutionMethodEnum.replacementOrder]:
    z.array(claimLineItemSchema), // ! cannot be reviewed only available as finalization
  [CrewClaimResolutionMethodEnum.repair]: z.array(claimLineItemSchema), // ! cannot be reviewed or finalized; unsupported
} satisfies Record<
  ReviewableResolutionMethod,
  z.Schema<z.infer<typeof reviewLineItemSchema>[]>
> &
  Record<
    | UnsupportedResolutionMethod
    | Exclude<CrewClaimResolutionMethodEnum, ReviewableResolutionMethod>,
    z.Schema<z.infer<typeof claimLineItemSchema>[]>
  >);

const claimReviewSchema = z
  .object({
    claim: CrewMerchantUi.claimSchema
      .merge(claimFinalizePayload.shape.claim)
      .omit({ claimLineItems: true })
      .merge(
        z.object({
          reviewLineItems: reviewLineItemsSchema,
        }),
      ),
  })
  .and(
    // * used to control if the email fields are shown, not part of the payload
    z.discriminatedUnion('isNotifyCustomerByEmailEnabled', [
      z.object({
        isNotifyCustomerByEmailEnabled: z.literal(false),
        notifyCustomerByEmail: z
          .object({
            emailType: z.nativeEnum(FinalizationEmailTypeEnum).optional(),
            subject: z.string().optional(),
            header: z.string().optional(),
            body: z.string().optional(),
          })
          .optional(),
      }),
      z.object({
        isNotifyCustomerByEmailEnabled: z.literal(true),
        notifyCustomerByEmail: z.object({
          emailType: z.nativeEnum(FinalizationEmailTypeEnum),
          subject: z.string().nonempty(),
          header: z.string().nonempty(),
          body: z.string().nonempty(),
        }),
      }),
    ]),
  );

export type ClaimReview = z.infer<typeof claimReviewSchema>;

export function claimLineItemTotalPrice(
  claimLineItem: CrewMerchantUi.ClaimLineItem,
) {
  return totalPrice({
    unitPrice: claimLineItem.originalStoreOrderLineItem.unitPrice,
    unitTax: claimLineItem.originalStoreOrderLineItem.unitTax,
    quantity: claimLineItem.quantity,
  });
}

function monetaryClaimValue(claimLineItem: CrewMerchantUi.ClaimLineItem) {
  /**
   * It should be provided in a monetary-focused claim via the customer ui; however, calculate to satisfy types.
   * ! Important to note that `requestedAmount` and `amount` are aggregates; that is, the total price for the `quantity` of the item claimed by the unit price.
   */
  const monetaryValue = claimLineItemTotalPrice(claimLineItem);
  return {
    requestedAmount: monetaryValue, // it should be provided in a monetary-focused claim via the customer ui; however, calculate to satisfy types
    amount: monetaryValue, // TODO allow partial monetary approval
  };
}

export function createBaseReview(claimLineItem: CrewMerchantUi.ClaimLineItem) {
  return {
    id: claimLineItem.id,
    quantity: claimLineItem.quantity,
    errors: claimLineItem.errors,
    returnLineItemIdFromPlatform: claimLineItem.returnLineItemIdFromPlatform,
  } satisfies Omit<z.infer<typeof baseClaimLineItemProcess>, 'noteToCustomer'>;
}

// TODO improve reconstruction to guarantee shape when reconstructing, especially with partial approvals and modified resolutions
export const createRequestedApproval = {
  [CrewClaimResolutionMethodEnum.refund]: (claimLineItem) => ({
    status: ReviewMetaStatus.approvingAsRequested,
    approval: {
      resolutionMethodEnum: CrewClaimResolutionMethodEnum.refund,
      ...createBaseReview(claimLineItem),
      ...monetaryClaimValue(claimLineItem),
    },
  }),
  [CrewClaimResolutionMethodEnum.giftCard]: (claimLineItem) => ({
    status: ReviewMetaStatus.approvingAsRequested,
    approval: {
      resolutionMethodEnum: CrewClaimResolutionMethodEnum.giftCard,
      ...createBaseReview(claimLineItem),
      ...monetaryClaimValue(claimLineItem),
    },
  }),
  [CrewClaimResolutionMethodEnum.variantExchange]: (claimLineItem) => {
    if (!claimLineItem.variantExchangeLineItem)
      throw new Error('Missing Variant Exchange Line Item');
    return {
      status: ReviewMetaStatus.approvingAsRequested,
      approval: {
        resolutionMethodEnum: CrewClaimResolutionMethodEnum.variantExchange,
        ...createBaseReview(claimLineItem),
        variantExchangeLineItem: claimLineItem.variantExchangeLineItem,
      },
    };
  },
} as const satisfies Partial<
  Record<
    CrewClaimResolutionMethodEnum,
    (
      claimLineItem: CrewMerchantUi.ClaimLineItem,
    ) => ApprovingReviewLineItem['reviewMetadata']
  >
>;

type DefaultResolvableResolutionMethod = keyof typeof createRequestedApproval;

const toDefaultReviewLineItem =
  (resolutionMethod: DefaultResolvableResolutionMethod) =>
  (claimLineItem: CrewMerchantUi.ClaimLineItem) =>
    ({
      claimLineItem,
      noteToCustomer: null, // default to `null` over using what may exist in the `claimLineItem` as it should be `undefined` until reviewed
      reviewMetadata: createRequestedApproval[resolutionMethod](claimLineItem),
    }) satisfies ReviewLineItem;

/**
 * Determines is a claim line item has a default approval for it's requested resolution method.
 * Meaning that the claim line item can be approved as requested, without review, and is therefore resolvable.
 */
export function hasDefaultApproval(
  claimLineItem: CrewMerchantUi.ClaimLineItem,
): claimLineItem is CrewMerchantUi.ClaimLineItem & {
  requestedResolutionMethodEnum: `${DefaultResolvableResolutionMethod}`;
} {
  return claimLineItem.requestedResolutionMethodEnum in createRequestedApproval;
}

export type ResolvableResolutionMethod =
  | DefaultResolvableResolutionMethod
  | TransformableResolutionMethod;

// possibly make this a derived array
export const resolvableResolutionMethod = {
  [CrewClaimResolutionMethodEnum.refund]: CrewClaimResolutionMethodEnum.refund,
  [CrewClaimResolutionMethodEnum.giftCard]:
    CrewClaimResolutionMethodEnum.giftCard,
  [CrewClaimResolutionMethodEnum.variantExchange]:
    CrewClaimResolutionMethodEnum.variantExchange,
  [CrewClaimResolutionMethodEnum.replacementOrder]:
    CrewClaimResolutionMethodEnum.replacementOrder,
} as const satisfies Record<
  ResolvableResolutionMethod,
  ResolvableResolutionMethod
>;

export type SupportedResolutionMethod =
  | ReviewableResolutionMethod
  | TransformableResolutionMethod
  | ResolvableResolutionMethod;

export const supportedResolutionMethods = Object.values(
  CrewClaimResolutionMethodEnum,
).filter(
  (resolutionMethod): resolutionMethod is SupportedResolutionMethod =>
    resolutionMethod in resolutionMethodChangesAllowed ||
    resolutionMethod in approveResolutionMethod,
);

/** Enum values that are entirely unsupported in any way within the merchant UI. */
type UnsupportedResolutionMethod = Exclude<
  CrewClaimResolutionMethodEnum,
  SupportedResolutionMethod
>;

export const unsupportedResolutionMethods = Object.values(
  CrewClaimResolutionMethodEnum,
).filter(
  (resolutionMethod): resolutionMethod is UnsupportedResolutionMethod =>
    !(resolutionMethod in resolutionMethodChangesAllowed),
);

export const createDenial = (
  claimLineItem: CrewMerchantUi.ClaimLineItem,
  // ? maybe extract the related type
  denialStatusDetailCode: (typeof crewClaimStatuses)[CrewClaimStatusCode.denied]['claimStatusDetail'][number]['code'],
) =>
  ({
    status: ReviewMetaStatus.denying,
    denial: {
      ...createBaseReview(claimLineItem),
      claimStatusDetailCode: denialStatusDetailCode,
    },
  }) satisfies DenyingReviewLineItem['reviewMetadata'];

export const createUndecided = () =>
  ({
    status: ReviewMetaStatus.undecided,
  }) as const satisfies UndecidedReviewLineItem['reviewMetadata'];

// used over `useFormContext` to wrap with loading and default values
export function useClaimReviewContext() {
  const claimReview = useFormContext<ClaimReview>();
  return { claimReview };
}

/**
 * The current loaded claim for review.
 * Most of the time this will be the URL path parameter, but represents only the claim once loaded for review.
 */
export function useClaimId() {
  const { claimReview } = useClaimReviewContext();
  const claimId = claimReview.watch('claim.id');
  return claimId;
}

// should just be part of the context, but that'll have to come when this provider is refactored as part of the redesign
/**
 * Invalidates the claim query for the current claim being reviewed based on the URL path parameter.
 * This will trigger a refetch of the claim data.
 */
export function useInvalidateClaimReview() {
  const queryClient = useQueryClient();
  const { userFullName } = useMerchantContext();
  const storeId = useStoreId();
  const claimId = useClaimId();

  if (!claimId) throw new Error('Missing Claim ID');

  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: ['claim', { claimId: `${claimId}`, storeId }, userFullName],
      }),
    [queryClient, claimId, storeId, userFullName],
  );
}

const pathParamsSchema = z.object({
  claimId: z.coerce.number(),
});

/** Only intended within `ClaimReview`. */
export function ClaimReviewProvider({ children }: { children?: ReactNode }) {
  const { userFullName } = useMerchantContext();
  const storeId = useStoreId();
  const { claimId } = usePathParams(pathParamsSchema);

  const {
    data: claim,
    isPending,
    isError,
    error,
  } = useQuery({
    enabled: !!claimId,
    queryKey: ['claim', { claimId: `${claimId}`, storeId }, userFullName],
    queryFn: () => {
      if (!claimId) throw new Error('Missing Claim ID');
      return api.store(storeId).claim(`${claimId}`, userFullName).get();
    },
    select: (data) => {
      const { claimLineItems, ...otherClaimData } = data;
      const grouped = groupBy(
        claimLineItems,
        (claimLineItem) => claimLineItem.requestedResolutionMethodEnum,
      );

      // could use a better name, but guarantees an array for TypeScript
      const getGrouped = (key: CrewClaimResolutionMethodEnum) =>
        grouped.get(key) ?? [];

      return {
        claim: {
          ...otherClaimData,
          reviewLineItems: {
            [CrewClaimResolutionMethodEnum.refund]: getGrouped(
              CrewClaimResolutionMethodEnum.refund,
            ).map(
              toDefaultReviewLineItem(CrewClaimResolutionMethodEnum.refund),
            ),
            [CrewClaimResolutionMethodEnum.variantExchange]: getGrouped(
              CrewClaimResolutionMethodEnum.variantExchange,
            ).map(
              toDefaultReviewLineItem(
                CrewClaimResolutionMethodEnum.variantExchange,
              ),
            ),
            [CrewClaimResolutionMethodEnum.giftCard]: getGrouped(
              CrewClaimResolutionMethodEnum.giftCard,
            ).map(
              toDefaultReviewLineItem(CrewClaimResolutionMethodEnum.giftCard),
            ),
            [CrewClaimResolutionMethodEnum.warrantyReview]: getGrouped(
              CrewClaimResolutionMethodEnum.warrantyReview,
            ).map((claimLineItem) => ({
              claimLineItem,
              noteToCustomer: null, // default to `null` over using what may exist in the `claimLineItem` as it should be `undefined` until reviewed
              reviewMetadata: createUndecided(),
            })),
            [CrewClaimResolutionMethodEnum.replacementOrder]: getGrouped(
              CrewClaimResolutionMethodEnum.replacementOrder,
            ),
            [CrewClaimResolutionMethodEnum.repair]: getGrouped(
              CrewClaimResolutionMethodEnum.repair,
            ),
          },
        },
        isNotifyCustomerByEmailEnabled: false,
      } satisfies ClaimReview;
    },
    // intentional overrides since claim changes are not persisted on change yet
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  // ? extract into another component, so that this provider can strictly be for the claim data
  const methods = useForm<ClaimReview>({
    defaultValues: claim,
    resolver: zodResolver(claimReviewSchema),
  });

  useEffect(() => {
    if (!claim) return;
    methods.reset(claim);
  }, [claim, methods]);

  if (isError) {
    // TODO differentiate client and server errors, and only propagate server/request errors
    return <PageStatus.Error error={error} />;
  }

  if (isPending || !methods.formState.defaultValues) {
    return <Loading />;
  }

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */ // using documented suggestion; @see https://www.react-hook-form.com/api/formprovider/
    <FormProvider key={claimId} {...methods}>
      {children}
      <ConfirmWithBlocker
        shouldBlock={methods.formState.isDirty}
        title="Finalization Incomplete"
        prompt="Any edits you've made to the resolution will not be saved. Are you sure you want to leave?"
        cancelText="Return to Claim"
        proceedText="Leave"
      />
    </FormProvider>
  );
}
