import {
  CorsoClaimType,
  CrewClaimResolutionMethodEnum,
  CrewClaimTypeEnum,
  CrewMerchantUi,
  enumToZodLiteralUnion,
} from 'corso-types';
import { z } from 'zod';

export const customerSchema = CrewMerchantUi.claimAddressSchema
  .pick({ firstName: true, lastName: true, phone: true })
  .merge(z.object({ email: z.string().email() }));

export const shippingAddressSchema = CrewMerchantUi.claimAddressSchema.omit({
  firstName: true,
  lastName: true,
  phone: true,
});

const shippingProtectionFormValuesSchema =
  CrewMerchantUi.shippingClaimCreateSchema
    .pick({
      noteFromMerchant: true,
      reason: true,
      resolutionMethod: true,
      notifyCustomer: true,
    })
    .extend({
      claimType: z.literal(CorsoClaimType.shippingProtection),
      customer: customerSchema,
      shippingAddress: shippingAddressSchema,
      lineItems: z
        .array(
          z.object({
            id: z.number(),
            quantity: z.number(),
          }),
        )
        .min(1, { message: 'Must select at least one line item.' }),
    });

export type ShippingProtectionFormValues = z.infer<
  typeof shippingProtectionFormValuesSchema
>;

const claimCreateLineItemSchema =
  CrewMerchantUi.claimCreateSchema.shape.claimLineItems.element;

const crewClaimLineItemSchema = claimCreateLineItemSchema
  .pick({ originalStoreOrderLineItemId: true, quantity: true })
  .merge(
    z.object({
      reasonId: z.number(),
      reasonDetailId: z.number().optional(),
    }),
  );
const variantExchangeLineItemSchema =
  claimCreateLineItemSchema.shape.variantExchangeLineItem.unwrap();

const crewClaimFormValuesSchema = z.object({
  claimType: enumToZodLiteralUnion(CrewClaimTypeEnum),
  customer: customerSchema,
  shippingAddress: shippingAddressSchema,
  claimLineItems: z
    .discriminatedUnion('requestedResolutionMethodEnum', [
      crewClaimLineItemSchema.merge(
        z.object({
          requestedResolutionMethodEnum: z.literal(
            `${CrewClaimResolutionMethodEnum.refund}`,
          ),
          variantExchangeLineItem: z.never().optional(),
        }),
      ),
      crewClaimLineItemSchema.merge(
        z.object({
          requestedResolutionMethodEnum: z.literal(
            `${CrewClaimResolutionMethodEnum.giftCard}`,
          ),
          variantExchangeLineItem: z.never().optional(),
        }),
      ),
      crewClaimLineItemSchema.merge(
        z.object({
          requestedResolutionMethodEnum: z.literal(
            `${CrewClaimResolutionMethodEnum.warrantyReview}`,
          ),
          variantExchangeLineItem: z.never().optional(),
        }),
      ),
      crewClaimLineItemSchema.merge(
        z.object({
          requestedResolutionMethodEnum: z.literal(
            `${CrewClaimResolutionMethodEnum.variantExchange}`,
          ),
          variantExchangeLineItem: z.object(
            variantExchangeLineItemSchema.shape,
            {
              required_error: 'Exchange variant required.',
            },
          ),
        }),
      ),
    ])
    .array()
    .min(1, { message: 'Must select at least one line item.' }),
});

export type CrewClaimFormValues = z.infer<typeof crewClaimFormValuesSchema>;

export const formValuesSchema = z.discriminatedUnion('claimType', [
  shippingProtectionFormValuesSchema,
  crewClaimFormValuesSchema.extend({
    claimType: z.literal(CorsoClaimType.return),
  }),
  crewClaimFormValuesSchema.extend({
    claimType: z.literal(CorsoClaimType.warranty),
  }),
]);
export type FormValues = z.infer<typeof formValuesSchema>;
