import { MutableRefObject, Ref, useEffect, useRef } from 'react';

export default function useCombinedRefs<T>(...refs: Ref<T>[]) {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        /* eslint-disable-next-line no-param-reassign */ // required to mutate ref
        (ref as MutableRefObject<T | null>).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}
