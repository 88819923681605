import { faShopify, faUps, faUsps } from '@fortawesome/free-brands-svg-icons';
import { faHexagonPlus } from '@fortawesome/pro-light-svg-icons';
import { faEye, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ReactNode } from 'react';
import Icon from '~/components/Icon';
import { Badge } from '~/components/ui/primitives/Badge';
import { CarrierService, ProtectionRate } from '~/types';
import { formatter } from '~/utils/formatter';

const getDisplayPrice = (price: number, currencyCode: string) =>
  price === 0 ? 'FREE' : formatter.currency(price, currencyCode);

const conditionalPriceString = (rate: ProtectionRate) => {
  if (rate.type === 'Flat') return null;

  const { maxAmount, minAmount, type, currencyCode } = rate;

  if (minAmount && maxAmount)
    return type === 'Order_Total' ?
        `Orders ${formatter.currency(minAmount, currencyCode)} - ${formatter.currency(maxAmount, currencyCode)}`
      : `Orders ${minAmount}oz - ${maxAmount}oz`;

  if (minAmount)
    return type === 'Order_Total' ?
        `Orders ${formatter.currency(minAmount, currencyCode)} and up`
      : `Orders ${minAmount}oz and up`;

  if (maxAmount)
    return type === 'Order_Total' ?
        `Orders ${formatter.currency(0, currencyCode)}-${formatter.currency(maxAmount, currencyCode)}`
      : `Orders 0-${maxAmount}oz`;

  return null;
};

const CarrierIconMap = {
  USPS_Express: faUsps,
  USPS_GroundAdvantage: faUsps,
  USPS_Priority: faUsps,
  USPS_First: faUsps,
  USPS_MediaMail: faUsps,

  UPSDAP_2ndDayAir: faUps,
  UPSDAP_Express: faUps,
  UPSDAP_Ground: faUps,
  UPSDAP_NextDayAir: faUps,
  UPSDAP_UPSStandard: faUps,
} satisfies Record<CarrierService, IconDefinition>;

export function ShippingRate({
  rate,
  children,
}: {
  rate: ProtectionRate;
  children?: ReactNode;
}) {
  const {
    price,
    name,
    description,
    plusDescription,
    plusName,
    currencyCode,
    shouldShowOriginalRate,
    carrierService,
    isPlusRate,
  } = rate;

  const isManagedOnShopify = !!rate.idFromPlatform;

  const displayPrice =
    carrierService ? 'carrier price' : getDisplayPrice(price, currencyCode);

  const displayName = isPlusRate ? (plusName ?? name) : name;

  const displayDescription =
    isPlusRate ? (plusDescription ?? description) : description;

  const conditionalDisplay = conditionalPriceString(rate);

  return (
    <div className="flex items-center justify-center gap-1.5">
      {/* grid */}
      <div className="grid flex-grow grid-cols-[1fr_auto] gap-x-2 gap-y-1">
        {/* grid element 1 */}
        <div className="flex items-center gap-2">
          <p className="text-sm font-medium text-corso-gray-800">
            {displayName}
          </p>
          {isManagedOnShopify && (
            <Icon icon={faShopify} className="flex-shrink-0 text-black" />
          )}
          {isPlusRate && (
            <Icon icon={faHexagonPlus} className="flex-shrink-0 text-black" />
          )}
          {shouldShowOriginalRate && (
            <Icon icon={faEye} className="flex-shrink-0 text-black" />
          )}
          {carrierService && (
            <Icon
              className="flex-shrink-0 text-black"
              icon={CarrierIconMap[carrierService]}
            />
          )}
        </div>

        {/* grid element 2 */}

        <div className="justify-self-end">
          <Badge variant={displayPrice === 'FREE' ? 'success' : 'info'}>
            {displayPrice} {isPlusRate && '+ dynamic price'}
          </Badge>
        </div>

        {/* grid element 3 */}
        <div>
          {displayDescription && (
            <p className="text-xs text-corso-gray-600">{displayDescription}</p>
          )}
        </div>

        {/* grid element 4 */}
        <div>
          {conditionalDisplay && (
            <p className="text-right text-xs text-corso-gray-600">
              {conditionalDisplay}
            </p>
          )}
        </div>
      </div>

      {children}
    </div>
  );
}
