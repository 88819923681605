import { useMutation } from '@tanstack/react-query';
import { CorsoClaimType, CrewMerchantUi } from 'corso-types';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import api from '~/api';
import Card from '~/components/Card';
import ClaimCreateForm from '~/components/claimCreate/ClaimCreateForm';
import ClaimTypeSelection from '~/components/claimCreate/ClaimTypeSelection';
import OrderLookup from '~/components/claimCreate/OrderLookup';
import DescriptionList from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import LineItem from '~/components/LineItem';
import { Loading } from '~/components/Loading';
import Modal from '~/components/Modal';
import Page from '~/components/Page';
import RelativeDateTime from '~/components/RelativeDateTime';
import { usePathParams } from '~/hooks/usePathParams';
import { useStoreId } from '~/hooks/useStoreId';
import { useStoreRules } from '~/hooks/useStoreRules';
import { formatter } from '~/utils/formatter';

function EligibleLineItem({
  lineItem,
  storeRules,
}: {
  lineItem: CrewMerchantUi.CrewOrder['lineItems'][0];
  storeRules: Awaited<ReturnType<typeof useStoreRules>>['data'];
}) {
  const gridDescription = [];

  const {
    return: returns,
    warranty,
    automationMadeIneligibleOn,
    productTagsAtPurchase,
    ineligibleLineItemFootnote,
  } = lineItem;
  const { exchange, giftCard, refund } = returns;

  const eligibleFor = [] as string[];
  if (giftCard.isEligible) eligibleFor.push('Gift Card');
  if (refund.isEligible) eligibleFor.push('Refund');
  if (exchange.isEligible) eligibleFor.push('Exchange');
  if (warranty.isEligible) eligibleFor.push('Warranty');

  const modifyWindowRule = storeRules?.find(
    (rule) => rule.id === lineItem.eligibilityStoreRuleId,
  );

  const subtitle =
    eligibleFor?.length ?
      <p className="text-xs text-corso-gray-500">
        Eligible for: {eligibleFor.join(', ')}
      </p>
    : <p className="text-xs text-corso-red-600">
        {ineligibleLineItemFootnote ?? 'Not Eligible '}
      </p>;

  gridDescription.push({
    term: 'Exchange',
    details:
      exchange?.lastEligibleDate ?
        formatter.date(exchange.lastEligibleDate)
      : 'Not Offered',
  });

  gridDescription.push({
    term: 'Refund',
    details:
      refund?.lastEligibleDate ?
        formatter.date(refund.lastEligibleDate)
      : 'Not Offered',
  });

  gridDescription.push({
    term: 'Gift Card',
    details:
      giftCard?.lastEligibleDate ?
        formatter.date(giftCard.lastEligibleDate)
      : 'Not Offered',
  });

  gridDescription.push({
    term: 'Warranty',
    details:
      warranty?.lastEligibleDate ?
        formatter.date(warranty.lastEligibleDate)
      : 'Not Offered',
  });

  const listDescriptions = [];

  if (modifyWindowRule) {
    listDescriptions.push({
      term: 'Modify Claim Window Automation',
      details: modifyWindowRule.name,
    });
  }

  listDescriptions.push({
    term: 'Product Tags At Purchase',
    details:
      productTagsAtPurchase?.length ? productTagsAtPurchase.join(', ') : 'None',
  });

  if (automationMadeIneligibleOn) {
    listDescriptions.push({
      term: 'Made In-Eligible By Automation On',
      details: formatter.date(automationMadeIneligibleOn),
    });
  }

  return (
    <Disclosure
      defaultOpen={false}
      renderSummary={() => (
        <SimpleSummary>
          <div className="ml-2">
            <LineItem
              key={lineItem.sku}
              name={lineItem.name}
              imageUrl={lineItem.imgUrl}
              quantity={lineItem.currentQuantity}
              options={lineItem.optionsFromPlatform}
              subtitle={
                <p className="text-xs text-corso-gray-500">{subtitle}</p>
              }
            />
          </div>
        </SimpleSummary>
      )}
    >
      <div className="flex flex-col gap-2 p-3 lg:gap-4 lg:p-4">
        <DescriptionList descriptions={gridDescription} />
        <DescriptionList descriptions={listDescriptions} layoutType="list" />
      </div>
    </Disclosure>
  );
}

function EligibilityOverview({
  order,
  show,
  close,
}: {
  order: CrewMerchantUi.CrewOrder;
  show: boolean;
  close: () => void;
}) {
  const { lineItems, orderNo, createdOn } = order;

  const { data: storeRules } = useStoreRules();

  const descriptions = [];

  descriptions.push({
    details: orderNo,
    term: 'Order Number',
  });

  descriptions.push({
    details: <RelativeDateTime dateTime={createdOn} />,
    term: 'Order Date',
  });

  return (
    <Modal show={show} onClose={close} title="Order Eligibility">
      <DescriptionList descriptions={descriptions} />
      <p className="text-lg">Line Items</p>
      {lineItems.map((lineItem) => EligibleLineItem({ lineItem, storeRules }))}
    </Modal>
  );
}

const paramsSchema = z.object({
  storeId: z.string(),
  orderIdFromPlatform: z.string().optional(),
});

export default function OrderOverview() {
  const [order, setOrder] = useState<CrewMerchantUi.CrewOrder | null>(null);
  const [claimType, setClaimType] = useState<CorsoClaimType | null>(null);
  const [showEligibility, setShowEligibility] = useState(false);

  const storeId = useStoreId();

  const { orderIdFromPlatform } = usePathParams(paramsSchema);
  const { mutateAsync: fetchOrder, isPending } = useMutation({
    mutationFn: api.store(storeId).order.get,
  });

  useEffect(() => {
    if (orderIdFromPlatform) {
      fetchOrder({ idFromPlatform: orderIdFromPlatform })
        .then(setOrder)
        .catch((error) => {
          console.error('Error fetching order:', error);
          setOrder(null);
          // ensure if we fail to find the order, or we change stores, we clear the orderIdFromPlatform
          window.history.replaceState(null, '', `/${storeId}/orders/lookup`);
        });
    }
  }, [orderIdFromPlatform, fetchOrder, storeId]);

  if (isPending) return <Loading />;

  if (!order) return <OrderLookup onOrderLookup={setOrder} />;

  return (
    <Page
      title="Order Overview"
      size="narrow"
      backAction={{
        accessibilityLabel: 'Back to Order Lookup',
        onAction() {
          setOrder(null);
          window.history.replaceState(null, '', `/${storeId}/orders/lookup`);
        },
      }}
      primaryAction={{
        content: 'View Eligibility',
        onAction: () => setShowEligibility(true),
      }}
    >
      <Card>
        <Card.Title>Order {order.orderNo}</Card.Title>
        <ClaimTypeSelection onClaimTypeSelect={setClaimType} />
        {claimType && (
          <ClaimCreateForm
            // reconciliation resets the form when the claim type changes
            key={claimType}
            order={order}
            claimType={claimType}
            onCancel={() => {
              setOrder(null);
              setClaimType(null);
            }}
          />
        )}
        <EligibilityOverview
          order={order}
          show={showEligibility}
          close={() => setShowEligibility(false)}
        />
      </Card>
    </Page>
  );
}
