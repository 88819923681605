import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimTypeEnum,
  CrewMerchantUi,
  NO_SHOPIFY_PRODUCT_ID,
} from 'corso-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/Alert';
import { Checkbox, Fieldset, NumberInput } from '~/components/field';
import LineItem from '~/components/LineItem';
import RelativeDateTime from '~/components/RelativeDateTime';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { reviewableResolutionMethods } from '~/providers/ClaimReviewProvider';
import { toLowerCase } from '~/utils/casing';
import { CrewClaimFormValues } from './claimCreateSchemas';
import { ClaimCreateVariantExchange } from './ClaimCreateVariantExchange';

function getEligibilityFootnote(
  lineItem: CrewMerchantUi.CrewOrder['lineItems'][0],
) {
  if (lineItem.automationMadeIneligibleOn) {
    return (
      <div>
        <p className="text-xs text-corso-red-600">
          Automation Made Ineligible{'  '}
          <RelativeDateTime dateTime={lineItem.automationMadeIneligibleOn} />
        </p>
      </div>
    );
  }

  if (lineItem.ineligibleLineItemFootnote) {
    return (
      <div>
        <p className="text-xs text-corso-red-600">
          {lineItem.ineligibleLineItemFootnote}
        </p>
      </div>
    );
  }

  return null;
}

export function CrewClaimFields({
  order,
  claimType,
}: {
  order: CrewMerchantUi.CrewOrder;
  claimType: CrewClaimTypeEnum;
}) {
  const { control, register, setValue, watch, formState } =
    useFormContext<CrewClaimFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'claimLineItems',
  });

  const watchFields = watch('claimLineItems');
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFields[index],
  }));

  return (
    <>
      <Alert
        title="Policy Override"
        message="Creating this claim will bypass your settings for final sale, exchanges, and eligibility windows."
      />

      <Fieldset legend="Line Items">
        <ul className="space-y-4">
          {order.lineItems.map((lineItem) => {
            const lineItemFieldIndex = controlledFields.findIndex(
              (field) => field.originalStoreOrderLineItemId === lineItem.id,
            );
            const lineItemField = controlledFields[lineItemFieldIndex];

            const { claimReasons } = lineItem[toLowerCase(claimType)];
            const { claimReasonDetails = [] } =
              claimReasons.find(
                (reason) => reason.id === lineItemField?.reasonId,
              ) ?? {};

            const isDisabled =
              !lineItem.currentQuantity || lineItem.isCorsoProduct;

            const eligibleFootnote = getEligibilityFootnote(lineItem);

            return (
              <li className="group" key={lineItem.id}>
                <div className="flex items-center gap-2">
                  {/* // TODO a11y label */}
                  <Checkbox
                    disabled={isDisabled}
                    className={twMerge(isDisabled && 'invisible')}
                    checked={!!lineItemField}
                    onChange={(e) => {
                      if (e.target.checked) {
                        append({
                          originalStoreOrderLineItemId: lineItem.id,
                          quantity: lineItem.currentQuantity,
                          // * default returns to refund, warranty to warranty review
                          requestedResolutionMethodEnum:
                            claimType === CrewClaimTypeEnum.return ?
                              CrewClaimResolutionMethodEnum.refund
                            : CrewClaimResolutionMethodEnum.warrantyReview,
                          /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */ // TODO address fix
                          reasonId: claimReasons.at(0)!.id,
                          reasonDetailId: claimReasons
                            .at(0)
                            ?.claimReasonDetails.at(0)?.id,
                        });
                      } else {
                        remove(
                          fields.findIndex(
                            (field) =>
                              field.originalStoreOrderLineItemId ===
                              lineItem.id,
                          ),
                        );
                      }
                    }}
                  />

                  <div className="flex-grow">
                    <LineItem
                      imageUrl={lineItem.imgUrl}
                      name={lineItem.name}
                      sku={lineItem.sku}
                      quantity={lineItem.currentQuantity}
                      unitPrice={lineItem.unitPrice}
                      unitTax={lineItem.unitTax}
                      options={lineItem.optionsFromPlatform}
                      subtitle={eligibleFootnote}
                    />
                  </div>
                </div>
                {lineItemField && (
                  <div className="space-y-4 pb-8 pl-7 pt-2 group-last-of-type:pb-0">
                    <NumberInput
                      id={`quantity-${lineItem.id}`}
                      label="Quantity"
                      min={1}
                      max={lineItem.currentQuantity}
                      required
                      {...register(
                        `claimLineItems.${lineItemFieldIndex}.quantity`,
                        { valueAsNumber: true },
                      )}
                      error={
                        formState.errors.claimLineItems?.[lineItemFieldIndex]
                          ?.quantity?.message
                      }
                    />
                    <div className="flex flex-col gap-4 md:flex-row md:gap-1">
                      <div className="flex-grow">
                        <Controller
                          control={control}
                          name={`claimLineItems.${lineItemFieldIndex}.reasonId`}
                          render={({ field, fieldState }) => (
                            <SimpleSelect
                              label="Reason"
                              options={claimReasons.map((reason) => ({
                                label: reason.name,
                                value: `${reason.id}`,
                              }))}
                              value={`${field.value}`}
                              onChange={(selected) => {
                                const reasonId = Number.parseInt(selected, 10);
                                const claimReason = claimReasons.find(
                                  ({ id }) => id === reasonId,
                                );
                                // reset reason detail while setting reason
                                setValue(
                                  `claimLineItems.${lineItemFieldIndex}`,
                                  {
                                    ...lineItemField,
                                    reasonId,
                                    reasonDetailId:
                                      claimReason?.claimReasonDetails.at(0)?.id,
                                  } as CrewClaimFormValues['claimLineItems'][number],
                                );
                              }}
                              error={fieldState.error?.message}
                            />
                          )}
                        />
                      </div>
                      {claimReasonDetails.length > 0 && (
                        <div className="flex-grow">
                          <Controller
                            control={control}
                            name={`claimLineItems.${lineItemFieldIndex}.reasonDetailId`}
                            render={({ field, fieldState }) => (
                              <SimpleSelect
                                label="Reason Detail"
                                options={claimReasonDetails.map(
                                  (reasonDetail) => ({
                                    label: reasonDetail.name,
                                    value: `${reasonDetail.id}`,
                                  }),
                                )}
                                value={`${field.value}`}
                                onChange={(selected) =>
                                  field.onChange(Number.parseInt(selected, 10))
                                }
                                error={fieldState.error?.message}
                              />
                            )}
                          />
                        </div>
                      )}
                    </div>
                    {/* // * only returns can be changed on the requested resolution; i.e. warranties MUST always be warranty review */}
                    {claimType === CrewClaimTypeEnum.return && (
                      <Controller
                        control={control}
                        name={`claimLineItems.${lineItemFieldIndex}.requestedResolutionMethodEnum`}
                        render={({ field, fieldState }) => (
                          <SimpleSelect
                            label="Resolution Method"
                            options={reviewableResolutionMethods
                              .filter(
                                (resolution) =>
                                  resolution !==
                                  CrewClaimResolutionMethodEnum.warrantyReview,
                              )
                              .filter(
                                // omit variant exchange as an option if the line item has no variant for exchange
                                (resolution) =>
                                  resolution !==
                                    CrewClaimResolutionMethodEnum.variantExchange ||
                                  (lineItem.productIdFromPlatform &&
                                    lineItem.productIdFromPlatform !==
                                      NO_SHOPIFY_PRODUCT_ID),
                              )
                              .map((resolution) => ({
                                label:
                                  crewClaimResolutionMethodEnumName[resolution],
                                value: resolution,
                              }))}
                            value={field.value}
                            onChange={(selected) => {
                              setValue(`claimLineItems.${lineItemFieldIndex}`, {
                                ...lineItemField,
                                requestedResolutionMethodEnum: selected,
                                variantExchangeLineItem: undefined, // intentionally always resetting, so that changing from variant exchange to a refund doesn't keep the variant exchange data
                              } as CrewClaimFormValues['claimLineItems'][number]);
                              field.onChange(selected); // redundant, but kept for the other side effects to state
                            }}
                            error={fieldState.error?.message}
                          />
                        )}
                      />
                    )}
                    {lineItemField.requestedResolutionMethodEnum ===
                      CrewClaimResolutionMethodEnum.variantExchange && (
                      <Controller
                        control={control}
                        name={`claimLineItems.${lineItemFieldIndex}.variantExchangeLineItem`}
                        render={({ field, fieldState }) => (
                          <>
                            <ClaimCreateVariantExchange
                              idFromPlatform={lineItem.productIdFromPlatform}
                              selectedQuantity={lineItemField.quantity}
                              onChange={field.onChange}
                            />
                            {fieldState.error && (
                              <Alert
                                variant="danger"
                                message={fieldState.error.message}
                              />
                            )}
                          </>
                        )}
                      />
                    )}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        {formState.errors.claimLineItems?.message && (
          <div className="mt-2">
            <Alert
              variant="danger"
              message={formState.errors.claimLineItems.message}
            />
          </div>
        )}
      </Fieldset>
    </>
  );
}
