import { Auth0Provider } from '@auth0/auth0-react';
import { PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import env from '~/env';

const appStateSchema = z.object({ returnTo: z.string() });

/**
 * Wrapper around `Auth0Provider` that redirects to the `returnTo` path in `appState` after login if present.
 * Otherwise, acts just like `Auth0Provider`, and still allows overriding the `onRedirectCallback` prop.
 * @see https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#protecting-a-route-in-a-react-router-dom-v6-app
 * @see https://developer.auth0.com/resources/guides/spa/react/basic-authentication
 */
export default function Auth0ProviderWithNavigate({
  children,
}: PropsWithChildren) {
  const navigate = useNavigate();
  const onRedirect = useCallback(
    (unsafeAppState: unknown) => {
      const parsedAppState = appStateSchema.safeParse(unsafeAppState);
      if (!parsedAppState.success) return; // just ignore invalid app state and use default redirect and router behavior
      navigate(parsedAppState.data.returnTo);
    },
    [navigate],
  );

  return (
    <Auth0Provider
      onRedirectCallback={onRedirect}
      domain={env.VITE_AUTH0_DOMAIN}
      clientId={env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  );
}
