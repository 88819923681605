import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/shadcn';

// TODO consider replace ALL buttons with this component
const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-neutral-900 text-white shadow hover:bg-neutral-900/90',
        destructive: 'bg-red-500 text-white shadow hover:bg-red-500/90',
        outline:
          'border border-neutral-200 hover:bg-neutral-50/80 hover:text-neutral-900', // ? revisit shadow and hover, but double check with usage; i.e. claim detail tags
        primary: 'bg-corso-blue-600 text-white shadow hover:bg-corso-blue-700',
        secondary:
          'bg-white text-neutral-700 shadow hover:bg-neutral-100/80 hover:text-neutral-900',
        ghost:
          'text-neutral-600 hover:bg-neutral-300/80 hover:text-neutral-900',
        link: 'text-neutral-900 underline-offset-4 hover:underline',
      },
      size: {
        default: 'px-6 py-2',
        sm: 'px-4 py-1.5',
        lg: 'px-8 py-3',
        icon: 'aspect-square min-h-8 min-w-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonProps = {
  asChild?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
