import { useAuth0 } from '@auth0/auth0-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { FormEventHandler, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import api from '~/api';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import { EmailInput, Input, TextInput } from '~/components/field';
import SpinnerIcon from '~/components/SpinnerIcon';
import { MultiSelect } from '~/components/ui/MultiSelect';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useSignUpLoaderData } from '~/loaders/SignUpLoader';
import Auth0ProviderWithNavigate from '~/providers/Auth0ProviderWithNavigate';
import {
  storeUserSignUpAndOnboardSchema,
  storeUserSignUpSchema,
} from '~/types';

function SignUpForm() {
  const { storeId } = useSignUpLoaderData();
  const { loginWithRedirect } = useAuth0();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  type FormValues = z.infer<typeof storeUserSignUpSchema>;

  const { mutateAsync: sendInvite, isPending } = useMutation({
    mutationFn: (values: FormValues) => {
      setErrorMessage(null);
      return api.store(String(storeId)).storeUser.signUp({
        ...values,
        isOnboardingComplete: true,
      });
    },
    onSuccess: (data, values) =>
      loginWithRedirect({
        authorizationParams: {
          login_hint: values.email,
        },
      }),
    onError: () => {
      setErrorMessage('Failed to send invite. Please try again.');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(storeUserSignUpSchema),
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => sendInvite(values))(event).catch(console.error);
  };

  return (
    <form onSubmit={submitHandler} className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 md:flex-row">
        <TextInput
          id="firstName"
          label="First Name"
          autoComplete="given-name"
          required
          error={errors.firstName?.message}
          {...register('firstName')}
        />

        <TextInput
          id="lastName"
          label="Last Name"
          autoComplete="family-name"
          required
          error={errors.lastName?.message}
          {...register('lastName')}
        />
      </div>

      <EmailInput
        id="email"
        label="Email"
        autoComplete="email"
        required
        error={errors.email?.message}
        {...register('email')}
      />

      {errorMessage && <Alert variant="danger" message={errorMessage} />}

      <Button
        disabled={isPending}
        variant="primary"
        type="submit"
        className="mt-4"
      >
        {isPending ?
          <SpinnerIcon />
        : 'Send Invite'}
      </Button>
    </form>
  );
}

const interestedInOptions = [
  'Returns',
  'Warranties',
  'Shipping Protection',
  'Registrations',
];

const estimateReturnCountOptions = [
  { label: 'Up to 300', value: '<300' },
  { label: 'Up to 1200', value: '<1200' },
  { label: 'Up to 3000', value: '<3000' },
  { label: 'More than 6000', value: '>6000' },
] as const satisfies { label: string; value: string }[];

function SignUpAndOnboardForm() {
  const { storeId } = useSignUpLoaderData();
  const { loginWithRedirect } = useAuth0();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  type FormValues = z.infer<typeof storeUserSignUpAndOnboardSchema>;

  const { mutateAsync: sendInvite, isPending } = useMutation({
    mutationFn: (values: FormValues) => {
      setErrorMessage(null);

      return api.store(String(storeId)).storeUser.signUp({
        ...values,
        isOnboardingComplete: false,
      });
    },
    onSuccess: (data, values) =>
      loginWithRedirect({
        authorizationParams: {
          login_hint: values.email,
        },
      }),
    onError: () => {
      setErrorMessage('Failed to send invite. Please try again.');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    resolver: zodResolver(storeUserSignUpAndOnboardSchema),
    defaultValues: {
      productsOfInterest: [],
    },
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => sendInvite(values))(event).catch(console.error);
  };

  return (
    <form onSubmit={submitHandler} className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 md:flex-row">
        <TextInput
          id="firstName"
          label="First Name"
          autoComplete="given-name"
          required
          error={errors.firstName?.message}
          {...register('firstName')}
        />

        <TextInput
          id="lastName"
          label="Last Name"
          autoComplete="family-name"
          required
          error={errors.lastName?.message}
          {...register('lastName')}
        />
      </div>

      <EmailInput
        id="email"
        label="Email"
        autoComplete="email"
        required
        error={errors.email?.message}
        {...register('email')}
      />

      <div className="space-y-4">
        <Input
          id="phone"
          type="tel"
          label="Phone"
          autoComplete="tel"
          error={errors.phone?.message}
          {...register('phone')}
        />

        <Controller
          name="productsOfInterest"
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiSelect
              label="What Products Are You Interested In?"
              options={interestedInOptions.map((option) => ({
                label: option,
                value: option,
              }))}
              value={value.map((option) => ({ label: option, value: option }))}
              placeholder="Select all that apply"
              onChange={(selected) => onChange(selected.map((v) => v.value))}
              error={errors.productsOfInterest?.message}
            />
          )}
        />

        <Controller
          name="estimatedAnnualClaims"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <SimpleSelect
              label="Estimated Annual Warranty and Return Claims"
              options={estimateReturnCountOptions}
              value={value} // be warned, react-hook-form is lying about the value type, as there's no default value
              onChange={onChange}
              error={fieldState.error?.message}
              required
            />
          )}
        />
      </div>

      {errorMessage && <Alert variant="danger" message={errorMessage} />}

      <Button
        disabled={isPending}
        variant="primary"
        type="submit"
        className="mt-4"
      >
        {isPending ?
          <SpinnerIcon />
        : 'Send Invite'}
      </Button>
    </form>
  );
}

function SignUpContent() {
  const { hasCompletedOnboarding } = useSignUpLoaderData();
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-center text-lg">Join The Crew</h1>

      <p className="text-center text-sm">
        This will send an invitation to your email to complete the sign up.
      </p>

      {hasCompletedOnboarding ?
        <SignUpForm />
      : <SignUpAndOnboardForm />}
    </div>
  );
}

export default function SignUp() {
  return (
    <Auth0ProviderWithNavigate>
      <SignUpContent />
    </Auth0ProviderWithNavigate>
  );
}
