import {
  ClaimResolutionMethodEnum,
  ClaimStatusEnum,
  CrewMerchantUi,
  shippingClaimReasonName,
} from 'corso-types';

export { shippingClaimReasonName };

export const shippingClaimResolutionMethodName = {
  [ClaimResolutionMethodEnum.refund]: 'Refund',
  [ClaimResolutionMethodEnum.reorder]: 'Reorder',
} as const satisfies Record<ClaimResolutionMethodEnum, string>;

export const shippingClaimStatusName = {
  [ClaimStatusEnum.open]: 'Open',
  [ClaimStatusEnum.closedDenied]: 'Closed - Denied',
  [ClaimStatusEnum.closedRefunded]: 'Closed - Refunded',
  [ClaimStatusEnum.closedReordered]: 'Closed - Reordered',
  [ClaimStatusEnum.inProgress]: 'In Progress',
  [ClaimStatusEnum.closedResolved]: 'Closed - Resolved',
} as const satisfies Record<ClaimStatusEnum, string>;

type ClaimSource = NonNullable<CrewMerchantUi.ShippingClaim['source']>;
export const shippingClaimSourceName = {
  Customer_App: 'Customer Portal',
  Gladly_Hero_Integration: 'Gladly Hero Integration',
  Merchant_App: 'Merchant Admin App',
  Gorgias_Ticket_Widget: 'Gorgias Ticket Widget',
  Retool: 'Corso Concierge Team',
  Zowie_Inbox_Integration: 'Zowie Inbox Integration',
} as const satisfies Record<ClaimSource, string>;
