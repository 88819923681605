import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons';
import Icon from '~/components/Icon';

import { CrewMerchantUi } from 'corso-types';
import { LinkButton } from '~/components/Button';
import Card from '~/components/Card';
import SimpleTooltip from '~/components/ui/SimpleTooltip';

type Shipment = NonNullable<CrewMerchantUi.ClaimShipment>;

export default function ShipmentPackingSlip({
  shipment,
}: {
  shipment: Shipment;
}) {
  return (
    <div className="flex flex-col gap-2">
      <Card.Title> Packing Slip </Card.Title>
      {shipment.returnShipmentPdfAssetUrl && (
        <SimpleTooltip content="Download Packing Slip">
          <LinkButton to={shipment.returnShipmentPdfAssetUrl}>
            <Icon icon={faFileArrowDown} />
          </LinkButton>
        </SimpleTooltip>
      )}
    </div>
  );
}
