import { PlayIcon } from '@heroicons/react/20/solid';
import { ReactNode, useState } from 'react';

type OpenRender = ReactNode | ((open: boolean) => ReactNode);

// ? could use a better name
function render(openRender: OpenRender, open: boolean) {
  return typeof openRender === 'function' ? openRender(open) : openRender;
}

/** Partial improvement over the HeadlessUI alternative using native `details` and `summary` elements. */
export default function Disclosure({
  renderSummary: summary,
  defaultOpen = false,
  children,
}: {
  renderSummary: OpenRender;
  defaultOpen?: boolean;
  children?: OpenRender;
}) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <details
      className="w-full [&[open]>summary_.simple-summary-icon]:rotate-90"
      open={open}
      onToggle={(event) => setOpen(event.currentTarget.open)}
    >
      <summary className="cursor-pointer list-none marker:hidden [&::-webkit-details-marker]:hidden">
        {render(summary, open)}
      </summary>
      {render(children, open)}
    </details>
  );
}

// TODO determine better name
export function SimpleSummary({ children }: { children?: ReactNode }) {
  return (
    <div className="flex items-center gap-1 p-2">
      <PlayIcon
        className="simple-summary-icon block size-3 rotate-0 text-corso-gray-500 transition-transform duration-75 ease-out"
        aria-hidden="true"
      />
      {children}
    </div>
  );
}
