import AmountLine, { ComparableAmount } from './AmountLine';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';

export default function RefundLineItem({
  compareAmount,
  amount,
  handlingFee,
  shippingFee,
}: ComparableAmount & {
  handlingFee?: number;
  shippingFee?: number;
}) {
  return (
    <ResolutionLineItemTitle title="Refund">
      <AmountLine name="Total" amount={amount} compareAmount={compareAmount} />
      {handlingFee !== undefined && handlingFee > 0 && (
        <AmountLine name="Handling Fee" amount={handlingFee} />
      )}
      {shippingFee !== undefined && shippingFee > 0 && (
        <AmountLine name="Shipping Fee" amount={shippingFee} />
      )}
    </ResolutionLineItemTitle>
  );
}
