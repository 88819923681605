import { z } from 'zod';

const envSchema = z.object({
  VITE_NODE_ENV: z.enum(['prod', 'stage', 'dev']), // TODO use `NodeEnv` as `nativeEnum` from `corso-lib`
  VITE_SENTRY_DSN: z.string(),
  VITE_BASE_API_URL: z.string(),
  VITE_AUTH0_CLIENT_ID: z.string(),
  VITE_AUTH0_DOMAIN: z.string(),
  VITE_BUILD_SHA: z.string(),
});

const env = envSchema.parse(import.meta.env);
export default env;
