import { useCallback } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';
import ConfirmModal from './ConfirmModal';

/**
 * Used to display a confirmation modal when the user attempts to navigate away from the current page or refresh the browser.
 * It uses `useBlocker` to handle SPA navigation and `useBeforeUnload` to handle browser refresh events.
 */
export default function ConfirmWithBlocker({
  shouldBlock,

  title,
  prompt,

  onCancel,
  cancelText,

  onProceed,
  proceedText,
}: {
  shouldBlock: boolean;

  title: string;
  prompt: string;

  /**
   * Only called through the the modal, if it is shown.
   * Will not be called from the `beforeunload` event.
   */
  onCancel?: () => void;
  cancelText: string;

  /**
   * Only called through the the modal, if it is shown.
   * Will not be called from the `beforeunload` event.
   */
  onProceed?: () => void;
  proceedText: string;
}) {
  // using `useBlocker` instead of `usePrompt` due to compatibility concerns
  // used to display custom prompt on SPA navigation
  const blocker = useBlocker(shouldBlock);

  // handles `beforeunload` event to display native browser prompt such as refresh
  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!shouldBlock) return; // ? needs to also include submitting

      // cancel the event as stated by the standard
      event.preventDefault();
      /** set `returnValue` to help with compatibility @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [shouldBlock],
  );

  // could use `useEffect`, but this abstraction is already available and does the handler cleanup
  useBeforeUnload(onBeforeUnload);

  return (
    <ConfirmModal
      title={title}
      show={blocker.state === 'blocked'}
      prompt={prompt}
      confirmText={proceedText}
      cancelText={cancelText}
      onConfirm={() => {
        onProceed?.();
        blocker.proceed?.();
      }}
      onCancel={() => {
        onCancel?.();
        blocker.reset?.();
      }}
    />
  );
}
