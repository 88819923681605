import { forwardRef, Ref } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Icon, { SupportedIcon } from './Icon';
import SpinnerIcon from './SpinnerIcon';

type IconSize = 'sm' | 'md' | 'lg' | 'xs';
type Variant = 'outline' | 'ghost';
type IconActionProps = {
  /** Required to label the actions for screen readers and present information to users hovering the action. */
  title: string;
  icon: SupportedIcon;
  iconSize?: IconSize;

  className?: string;
  variant?: Variant;
};
type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  IconActionProps & {
    loading?: boolean;
    /** Applies alternative styling to indicate the button is pressed/active. */
    pressed?: boolean;
  };
const iconSizeMap = {
  xs: 'size-3',
  sm: 'size-4',
  md: 'size-5',
  lg: 'size-6',
} satisfies Record<IconSize, string>;

const variantStyles = {
  outline:
    'rounded-md px-3 py-2 shadow-sm ring-1 ring-inset ring-corso-gray-300',
  ghost: 'rounded-full p-1',
} satisfies Record<Variant, string>;

const baseStyle =
  'flex items-center justify-center text-center text-sm text-corso-gray-600 font-semibold hover:bg-corso-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1';

const pressedStyle = 'bg-corso-gray-200 hover:bg-corso-gray-200';

const IconButton = forwardRef(
  (
    {
      title,
      icon,
      onClick,
      className,
      disabled,
      loading = false,
      iconSize = 'md',
      type = 'button',
      variant = 'outline',
      pressed = false,
      ...props
    }: IconButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button
      /* eslint-disable-next-line react/button-has-type */ // always defined, and defaulted to `button`
      type={type}
      className={twMerge(
        baseStyle,
        variantStyles[variant],
        pressed && pressedStyle,
        disabled &&
          `cursor-not-allowed text-corso-gray-400 opacity-75 hover:bg-transparent`,
        className,
      )}
      onClick={onClick}
      title={title}
      disabled={disabled}
      {...props}
      ref={ref}
    >
      <span className="sr-only">{title}</span>
      {/* eslint-disable-next-line no-nested-ternary -- TODO FIX */}
      {loading ?
        <SpinnerIcon className={`${iconSizeMap[iconSize]}`} />
      : <Icon icon={icon} className={iconSizeMap[iconSize]} />}
    </button>
  ),
);

function IconLink({
  title,
  icon,
  to,
  target,
  className,
  iconSize = 'md',
  variant = 'outline',
}: IconActionProps & { to: LinkProps['to']; target?: string }) {
  return (
    <Link
      to={to}
      className={twMerge(baseStyle, variantStyles[variant], className)}
      title={title}
      target={target}
      rel="noreferrer"
    >
      <span className="sr-only">{title}</span>
      <Icon icon={icon} className={iconSizeMap[iconSize]} />
    </Link>
  );
}

// ? potentially split into independent components instead
export default { Button: IconButton, Link: IconLink };
