import {
  ArrowUturnLeftIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './Button';
import ConfirmWithBlocker from './ConfirmWithBlocker';
import { Transition } from './ui/primitives/Transition';

/**
 * A wrapper that shows floating actions to save or revert changes.
 * With unsaved changes shows a confirmation modal block, and sets up a handler for the `beforeunload` event.
 *
 * Using a `formId` referencing the `id` of `form`, the component will trigger `reset` and `submit` events to the form.
 * Otherwise, both `onReset` and `onSubmit` handlers are required to handle the actions.
 */
export default function FloatingSave({
  onReset,
  onSubmit,
  form,
  isDirty = false,
  isSubmitting = false,
}: { isSubmitting?: boolean; isDirty?: boolean } & (
  | { onSubmit: () => void; onReset: () => void; form?: never }
  | { onSubmit?: never; onReset?: never; form: string }
)) {
  const resetRef = useRef<HTMLButtonElement>(null);
  const show = isDirty || isSubmitting;

  return (
    <>
      <Transition show={show}>
        <div
          className={twMerge(
            'fixed bottom-6 right-6 z-10 flex flex-col flex-wrap items-end gap-6',
            show ?
              'animate-in fade-in zoom-in-90 fill-mode-forwards'
            : 'animate-out fade-out zoom-out-90 fill-mode-forwards',
          )}
        >
          <Button
            title="Revert Changes"
            className="max-w-fit px-2 shadow-md"
            form={form}
            type={form ? 'reset' : 'button'}
            disabled={isSubmitting}
            onClick={onReset}
            ref={resetRef}
          >
            <ArrowUturnLeftIcon className="h-5 w-5" aria-hidden="true" />
          </Button>
          <Button
            variant="primary"
            className="min-w-[6rem] p-4 shadow-md"
            form={form}
            type={form ? 'submit' : 'button'}
            loading={isSubmitting}
            onClick={() => onSubmit?.()}
          >
            <DocumentCheckIcon className="h-5 w-5" aria-hidden="true" /> Save
          </Button>
        </div>
      </Transition>
      <ConfirmWithBlocker
        shouldBlock={isDirty}
        title="Unsaved Changes"
        prompt="You have unsaved changes. Do you wish to proceed and discard your changes?"
        cancelText="Return to Page"
        onProceed={() => resetRef.current?.click()}
        proceedText="Discard Changes"
      />
    </>
  );
}
