import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode } from 'react';

// ! DO NOT CHANGE THE CLIENT FROM THE GLOBAL DEFAULTS https://tanstack.com/query/v5/docs/framework/react/guides/important-defaults
// if a query or mutation needs something different, they can individually override them
export const queryClient = new QueryClient();

export function QueryProvider({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
