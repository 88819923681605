import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import api from '~/api';
import { StoreRule, StoreRuleCreate, StoreRuleUpdate } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { inspectionSettingsQueryPrefix } from './useClaimLineItemInspection';
import { useStoreId } from './useStoreId';
import { useToast } from './useToast';

// TODO revise usage of mutation and queries to be more consistent, and abstract optimistic/error handling

function useQueryKey() {
  const storeId = useStoreId();
  return ['automationRules', storeId] as const;
}

export function useStoreRules() {
  const queryKey = useQueryKey();
  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api
        .store(storeId)
        .storeRules.list()
        .then((storeRules) => storeRules.sort((a, b) => a.id - b.id)),
  });
}

export function useInvalidateRelatedQueries() {
  const queryClient = useQueryClient();
  const queryKey = useQueryKey();

  return useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey });
    await queryClient.invalidateQueries({
      queryKey: [inspectionSettingsQueryPrefix],
    });
  }, [queryClient, queryKey]);
}

// ? maybe split
export function useStoreRuleUpsert() {
  const storeId = useStoreId();

  const toast = useToast();
  const invalidateRelatedQueries = useInvalidateRelatedQueries();

  return useMutation({
    mutationFn: (rule: StoreRuleCreate | StoreRuleUpdate) =>
      'id' in rule ?
        api.store(storeId).storeRules.update(`${rule.id}`, rule)
      : api.store(storeId).storeRules.create(rule),

    onSuccess: () => invalidateRelatedQueries(),
    onError: (error) => {
      toast.show(
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.',
      );
    },
  });
}

export function useStoreRuleDelete() {
  const storeId = useStoreId();

  const toast = useToast();
  const invalidateRelatedQueries = useInvalidateRelatedQueries();

  return useMutation({
    mutationFn: (storeRuleId: StoreRule['id']) =>
      api.store(storeId).storeRules.delete(`${storeRuleId}`),
    onSuccess: () => invalidateRelatedQueries(),
    onError: (error) => {
      toast.show(
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.',
      );
    },
  });
}

/** A simplified abstraction to more easily toggle a rule. */
export function useToggleStoreRule() {
  const { mutate: upsertRule } = useStoreRuleUpsert();
  return useCallback(
    (storeRule: StoreRule) => {
      upsertRule({
        ...storeRule,
        isEnabled: !storeRule.isEnabled,
      });
    },
    [upsertRule],
  );
}
