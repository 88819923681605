import {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import ConfirmModal from '~/components/ConfirmModal';
import IconAction from '~/components/IconAction';
import Menu from '~/components/Menu';
import { useProtectionRateDelete } from '~/hooks/useProtectionRates';
import { ShippingRate } from '~/pages/settings/ShippingRate';
import { ProtectionRate } from '~/types';
import ShippingRateForm from './ShippingRateForm';

function ManageShippingRate({ rate }: { rate: ProtectionRate }) {
  const { mutate: deleteProtectionRate } = useProtectionRateDelete();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEdt, setShowEdit] = useState(false);

  return (
    <ShippingRate rate={rate}>
      <Menu
        align="end"
        buttonAs={
          <IconAction.Button
            variant="ghost"
            iconSize="lg"
            icon={EllipsisVerticalIcon}
            className="bg-white"
            title="Rate Actions"
          />
        }
      >
        <Menu.ItemButton onClick={() => setShowEdit(true)}>
          <PencilIcon className="h-5 w-5" /> Edit
        </Menu.ItemButton>

        <Menu.ItemButton onClick={() => setShowDeleteConfirmation(true)}>
          <TrashIcon className="h-5 w-5" /> Delete
        </Menu.ItemButton>
      </Menu>

      <ConfirmModal
        prompt="Are you sure you want to delete this rate? This action cannot be undone."
        title="Delete Rate"
        show={showDeleteConfirmation}
        onConfirm={() => deleteProtectionRate(`${rate.id}`)}
        onCancel={() => setShowDeleteConfirmation(false)}
      />

      <ShippingRateForm
        show={showEdt}
        onClose={() => setShowEdit(false)}
        values={rate}
      />
    </ShippingRate>
  );
}

export default function ManageShippingRates({
  rates,
}: {
  rates: ProtectionRate[];
}) {
  return (
    <ul className="flex flex-col justify-between gap-px bg-neutral-200">
      {rates?.map((rate) => (
        <li key={rate.id} className="bg-white pb-2 pt-2.5">
          <ManageShippingRate rate={rate} />
        </li>
      ))}
    </ul>
  );
}
