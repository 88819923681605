import { Key, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export function FeedFallbackIcon() {
  return (
    <div className="h-1.5 w-1.5 rounded-full bg-corso-gray-100 ring-1 ring-corso-gray-300" />
  );
}

export type FeedEvent =
  | Key
  | {
      id: Key;
      iconSize?: string;
    };

// ? possibly use/extend `ArrayCallback`
type RenderArrayCallback<T> = (
  event: T,
  index: number,
  events: readonly T[],
) => ReactNode;

/**
 * Inspired by [TailwindUI's `Feed` components](https://tailwindui.com/components/application-ui/lists/feeds).
 * A feed differs from a timeline, in that it is a list of events, but not necessarily in chronological order, nor does it require a time component.
 */
export default function Feed<T extends FeedEvent>({
  events,
  renderIcon,
  children,
}: {
  events: readonly T[];
  /**
   * Suggested to be a circle with a diameter no more than `1.5rem` (approximately `24px`, or default TailwindCSS `size-6`; aka `h-6 h-6`).
   * Falsy values will render the `FeedFallbackIcon`.
   */
  renderIcon?: RenderArrayCallback<T>;
  children: RenderArrayCallback<T>;
}) {
  if (!events.length) return null; // nothing to show, so don't even render the container
  return (
    <ul className="space-y-4">
      {events.map((event, index, eventsReference) => (
        <li
          key={typeof event === 'object' && 'id' in event ? event.id : event}
          className="relative flex gap-x-4"
          style={{
            '--feed-icon-size':
              typeof event === 'object' && 'iconSize' in event ?
                event.iconSize
              : '1.5rem',
          }}
        >
          <div
            className={twMerge(
              index === events.length - 1 ?
                'h-[--feed-icon-size]'
              : '-bottom-[--feed-icon-size]',
              'absolute left-0 top-0 flex w-[--feed-icon-size] justify-center',
            )}
          >
            <div className="w-px bg-corso-gray-200" />
          </div>
          <div className="relative flex size-[--feed-icon-size] flex-none items-center justify-center bg-white">
            {renderIcon?.(event, index, eventsReference) ?? (
              <FeedFallbackIcon />
            )}
          </div>
          {/* // ? maybe `flex-grow` instead of `w-full` */}
          <div className="flex w-full flex-col justify-between sm:flex-row sm:items-center sm:gap-4">
            {children(event, index, eventsReference)}
          </div>
        </li>
      ))}
    </ul>
  );
}
