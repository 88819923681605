import CorsoIcon from './CorsoIcon';

export default function FullPageLoader() {
  return (
    <main className="item-center flex min-h-full place-items-center justify-center bg-corso-gray-900">
      <div className="text-white">
        <span className="flex -rotate-90 animate-pulse items-center justify-center">
          <CorsoIcon size="xl" />
        </span>
      </div>
    </main>
  );
}
