import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/primitives/DropdownMenu';

// TODO consider renaming, or removing in favor of just `DropdownMenu` primitive
export default function Menu({
  buttonAs,
  children,
  align,
}: PropsWithChildren<{
  buttonAs: ReactNode;
  align?: ComponentProps<typeof DropdownMenuContent>['align'];
}>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{buttonAs}</DropdownMenuTrigger>
      <DropdownMenuContent align={align}>{children}</DropdownMenuContent>
    </DropdownMenu>
  );
}

const baseMenuItemStyles =
  'flex items-center w-full gap-2 text-left text-sm text-corso-gray-800';

Menu.ItemButton = ({
  children,
  onClick,
  disabled,
}: PropsWithChildren<{ onClick: () => void; disabled?: boolean }>) => (
  <DropdownMenuItem onClick={onClick} disabled={disabled}>
    <div className={baseMenuItemStyles}>{children}</div>
  </DropdownMenuItem>
);

type ItemNavLinkProps = Omit<ComponentProps<typeof NavLink>, 'className'> & {
  disabled?: boolean;
};

/** A `NavLink` wrapped within a `Menu.Item`. */
Menu.ItemNavLink = ({ children, disabled, ...props }: ItemNavLinkProps) => (
  <DropdownMenuItem disabled={disabled}>
    <NavLink
      className={baseMenuItemStyles}
      // eslint-disable-next-line react/jsx-props-no-spreading -- intentionally spreading props to passthrough `NavLink` functionality
      {...props}
    >
      {children}
    </NavLink>
  </DropdownMenuItem>
);

type ItemLinkProps = Omit<ComponentProps<typeof Link>, 'className'>;

/** A `Link` wrapped within a `Menu.Item`. */
Menu.ItemLink = ({ children, ...props }: ItemLinkProps) => (
  <DropdownMenuItem>
    <Link
      className={baseMenuItemStyles}
      // eslint-disable-next-line react/jsx-props-no-spreading -- intentionally spreading props to passthrough `NavLink` functionality
      {...props}
    >
      {children}
    </Link>
  </DropdownMenuItem>
);
