import { CrewClaimResolutionMethodEnum, CrewMerchantUi } from 'corso-types';

import { PropsWithChildren } from 'react';
import Alert from '~/components/Alert';
import LineItem from '~/components/LineItem';
import { Badge } from '~/components/ui/primitives/Badge';

type ClaimLineItemProps = PropsWithChildren<{
  claimLineItem: CrewMerchantUi.ClaimLineItem;
}>;
export default function ClaimLineItem({
  children,
  claimLineItem,
}: ClaimLineItemProps) {
  const { originalStoreOrderLineItem: orderLineItem } = claimLineItem;

  return (
    <div className="flex flex-col gap-4 text-sm">
      {claimLineItem.errors?.map((error) => (
        <Alert variant="warning" message={error} />
      ))}

      <LineItem
        sku={orderLineItem.sku}
        imageUrl={orderLineItem.imgUrl}
        name={orderLineItem.name}
        options={orderLineItem.optionsFromPlatform}
        unitPrice={claimLineItem.originalStoreOrderLineItem.unitPrice}
        unitTax={
          (
            claimLineItem.requestedResolutionMethodEnum !==
            CrewClaimResolutionMethodEnum.variantExchange
          ) ?
            claimLineItem.originalStoreOrderLineItem.unitTax
          : null
        }
        quantity={claimLineItem.quantity}
        subtitle={
          // TODO evaluate and redesign to remove wrapper `div`
          <div>
            <Badge>
              {claimLineItem.status.name}
              {' - '}
              {claimLineItem.status.detail.name}
            </Badge>
          </div>
        }
      />
      {children}
    </div>
  );
}
