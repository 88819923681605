import { twMerge } from 'tailwind-merge';
import FallbackImage from './FallbackImage';

export default function ProductImage({
  src,
  alt,
  className,
}: {
  src?: string | null;
  alt: string;
  className?: string;
}) {
  return (
    <FallbackImage
      src={src}
      alt={alt}
      className={twMerge(
        'aspect-square rounded-md border bg-gray-50 object-contain object-center',
        className,
      )}
    />
  );
}
