import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Card from '~/components/Card';
import { EmailInput, SwitchInput } from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from '~/hooks/useConfigSettings';
import {
  notificationSettingsFormSchema,
  NotificationSettingsFormValues,
} from '~/types';

const formId = 'notification-settings';
export default function NotificationsSettings() {
  const { data } = useConfigSettings(({ notifications }) => notifications);
  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty, isSubmitting },
    control,
    /** trigger is used to manually trigger validation - the switch and email inputs are connected in validation
     * but according to the docs, the switch input doesn't trigger the form state to updated even though the schema validation is ran
     * so we need to manually trigger the validation to update the form state (see resolver rules: https://react-hook-form.com/docs/useform#resolver)
     */
    trigger,
    reset,
  } = useForm<NotificationSettingsFormValues>({
    resolver: zodResolver(notificationSettingsFormSchema),
    values: data,
  });
  const isNewClaimEmailEnabled = watch('isNewClaimEmailEnabled');
  const isClaimReceivedEmailEnabled = watch('isClaimReceivedEmailEnabled');
  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => saveChanges({ notifications: values }))(
      event,
    ).catch(console.error);
  };

  return (
    <Page title="Notifications">
      <form
        id={formId}
        className="flex flex-col gap-4"
        onSubmit={submitHandler}
        onReset={(e) => {
          e.preventDefault();
          reset();
        }}
      >
        <FloatingSave
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          form={formId}
        />
        <Card>
          <Controller
            control={control}
            name="isNewClaimEmailEnabled"
            defaultValue={false} // prevent uncontrolled input warning
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="new-claim-submitted"
                label="New Claim Submitted"
                details="Email sent to the specified email addresses when a customer creates a warranty or return claim."
                checked={value}
                onChange={(event) => {
                  onChange(event);

                  // * trigger a validation check on the email field when the switch is toggled off to get rid of the require error message from the schema refinement
                  if (!event.target.checked) {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    trigger('emailsForNewClaim');
                  }
                }}
                error={fieldState.error?.message}
              />
            )}
          />
          <EmailInput
            multiple
            required
            id="new-claim-email-addresses"
            label="New Claim Submitted Email Addresses"
            placeholder="admin@brand.com, support@brand.com"
            disabled={!isNewClaimEmailEnabled}
            {...register('emailsForNewClaim')}
            error={
              // ! underlying type is wrong, as it's an array of zod errors because of the email list validation; this just simplifies the error to a more merchant friendly message
              errors.emailsForNewClaim &&
              'All comma-separated emails address must be valid.'
            }
          />

          <Controller
            control={control}
            name="isClaimReceivedEmailEnabled"
            defaultValue={false} // prevent uncontrolled input warning
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="return-items-received"
                label="Returned Items Received"
                details="Email sent to the specified email addresses when returned items have been received by the warehouse."
                checked={value}
                onChange={(event) => {
                  onChange(event);
                  // * trigger a validation check on the email field when the switch is toggled off to get rid of the require error message from the schema refinement
                  if (!event.target.checked) {
                    // eslint-disable-next-line no-void -- trigger returns a promise, but we don't need to wait for it
                    void trigger('emailsForClaimReceived');
                  }
                }}
                error={fieldState.error?.message}
              />
            )}
          />
          <EmailInput
            multiple
            required
            id="return-items-received-email-addresses"
            label="Returned Items Received Email Addresses"
            placeholder="admin@brand.com, support@brand.com"
            disabled={!isClaimReceivedEmailEnabled}
            {...register('emailsForClaimReceived')}
            error={
              // ! underlying type is wrong, as it's an array of zod errors because of the email list validation; this just simplifies the error to a more merchant friendly message
              errors.emailsForClaimReceived &&
              'All comma-separated emails address must be valid.'
            }
          />
        </Card>
      </form>
    </Page>
  );
}
