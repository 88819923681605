import { JSX } from 'react';
import { Link, LinkProps } from 'react-router-dom';

function EmptyStateButton({
  onClick,
  label,
  icon,
  description,
}: {
  onClick: () => void;
  label: string;
  description?: string;
  icon: JSX.Element;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-corso-gray-300 bg-corso-gray-50 p-8 hover:border-corso-gray-400 focus:outline-none focus:ring-2 focus:ring-corso-blue-600 focus:ring-offset-2"
    >
      <span className="text-corso-gray-500 *:h-12 *:w-12">{icon}</span>
      <span className="text-sm font-semibold text-corso-gray-800">{label}</span>
      {description && (
        <span className="text-left text-sm text-corso-gray-500">
          {description}
        </span>
      )}
    </button>
  );
}

function EmptyStateLink({
  to,
  label,
  icon,
  description,
}: {
  to: LinkProps['to'];
  label: string;
  description?: string;
  icon: JSX.Element;
}) {
  return (
    <Link
      to={to}
      className="flex w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-corso-gray-300 bg-corso-gray-50 p-8 hover:border-corso-gray-400 focus:outline-none focus:ring-2 focus:ring-corso-blue-600 focus:ring-offset-2"
    >
      <span className="text-corso-gray-500 *:h-12 *:w-12">{icon}</span>
      <span className="text-sm font-semibold text-corso-gray-800">{label}</span>
      {description && (
        <span className="text-left text-sm text-corso-gray-500">
          {description}
        </span>
      )}
    </Link>
  );
}

export default {
  Button: EmptyStateButton,
  Link: EmptyStateLink,
};
