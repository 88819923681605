import { useQuery } from '@tanstack/react-query';
import api from '~/api';
import { useStoreId } from './useStoreId';

export const useProductCollections = () => {
  const storeId = useStoreId();
  const queryKey = ['productCollections', storeId];

  return useQuery({
    queryKey,
    queryFn: () => api.store(storeId).product.collections(),
  });
};
