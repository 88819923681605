import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import Button, { LinkButton } from '~/components/Button';
import Separator from '~/components/Separator';
import { Label } from '~/components/ui/primitives/Label';
import { useUserWelcomeLoaderData } from '~/loaders/UserWelcomeLoader';
import Auth0ProviderWithNavigate from '~/providers/Auth0ProviderWithNavigate';

function UserWelcomeContent() {
  const { userEmails, accessCode } = useUserWelcomeLoaderData();
  const { loginWithRedirect } = useAuth0();
  const [selectedEmail, setSelectedEmail] = useState(userEmails[0]);

  const handleUserLogin = () => {
    if (!selectedEmail) return;

    loginWithRedirect({
      authorizationParams: {
        login_hint: selectedEmail,
      },
    }).catch(console.error);
  };

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-center text-lg">Welcome To The Crew</h1>

      <p className="text-center text-sm">Choose a user to login.</p>
      <div>
        <Label htmlFor="email">Login</Label>
        <select
          id="email"
          name="email"
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-corso-gray-800 ring-1 ring-inset ring-corso-gray-300 focus:ring-2 focus:ring-corso-blue-600 sm:text-sm sm:leading-6"
          onChange={(e) => setSelectedEmail(e.target.value)}
          defaultValue={selectedEmail}
        >
          {userEmails.map((email) => (
            <option key={email} value={email}>
              {email}
            </option>
          ))}
        </select>
      </div>
      <Button variant="primary" onClick={handleUserLogin}>
        Continue
      </Button>

      <Separator />

      <LinkButton
        className="mt-4"
        to={`../sign-up?${accessCode.key}=${accessCode.value}`}
      >
        Sign Up
      </LinkButton>
    </div>
  );
}

export default function UserWelcome() {
  return (
    <Auth0ProviderWithNavigate>
      <UserWelcomeContent />
    </Auth0ProviderWithNavigate>
  );
}
