import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { ReturnLocation, ReturnLocationCreate } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useStoreId } from './useStoreId';
import { useToast } from './useToast';

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['returnLocations', storeId] as const;
};

export const useReturnLocations = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).returnLocation.get(),
  });
};

export const useReturnLocationUpsert = () => {
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (location: ReturnLocation | ReturnLocationCreate) =>
      'id' in location ?
        api.store(storeId).returnLocation.update(String(location.id), location)
      : api.store(storeId).returnLocation.create(location),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (location: ReturnLocation | ReturnLocationCreate) => {
      const previousLocations =
        queryClient.getQueryData<ReturnLocation[]>(queryKey) ?? [];

      let updates: ReturnLocation[] = [];
      if ('id' in location) {
        const index = previousLocations.findIndex((g) => g.id === location.id);
        updates = [...previousLocations];
        updates.splice(index, 1, location);
      } else {
        updates = [
          ...previousLocations,
          {
            ...location,
            id: Number.MAX_SAFE_INTEGER,
          },
        ];
      }

      queryClient.setQueryData(queryKey, updates);

      return { previousLocations };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousLocations);

      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';
      toast.show(message);

      return error;
    },
  });
};

export const useReturnLocationDelete = () => {
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: ({ id }: ReturnLocation) =>
      api.store(storeId).returnLocation.delete(String(id)),
    onMutate: ({ id }: ReturnLocation) => {
      const previousLocations =
        queryClient.getQueryData<ReturnLocation[]>(queryKey) ?? [];

      queryClient.setQueryData(
        queryKey,
        previousLocations.filter((loc) => loc.id !== id),
      );

      return { previousLocations };
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousLocations);

      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';
      toast.show(message);

      return error;
    },
  });
};
