import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { ClaimReason, ClaimReasonCreate, ClaimReasonUpdate } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useToast } from './useToast';

const useStoreId = () => {
  const { storeUser } = useMerchantContext();
  return storeUser.storeId.toString();
};

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['claimReasons', storeId] as const;
};

export const useClaimReasons = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).claimReason.get(),
  });
};

export const useClaimReasonUpsert = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const toast = useToast();
  const queryKey = useQueryKey();

  return useMutation({
    mutationFn: (reason: ClaimReasonUpdate | ClaimReasonCreate) =>
      'id' in reason ?
        api.store(storeId).claimReason.update(String(reason.id), reason)
      : api.store(storeId).claimReason.create(reason),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (reason: ClaimReasonUpdate | ClaimReasonCreate) => {
      const previousReasons =
        queryClient.getQueryData<ClaimReason[]>(queryKey) ?? [];

      let updates: ClaimReason[] = [];
      if ('id' in reason) {
        const index = previousReasons.findIndex((g) => g.id === reason.id);
        updates = [...previousReasons];
        updates.splice(index, 1, {
          ...reason,
          claimReasonDetails: reason.claimReasonDetails.map((detail) => ({
            ...detail,
            id: detail.id ?? Math.floor(Math.random() * 100000000),
            isDeleted: detail.isDeleted,
          })),
        });
      } else {
        updates = [
          ...previousReasons,
          {
            ...reason,
            id: Number.MAX_SAFE_INTEGER,
            isDeleted: false,
            claimReasonDetails: [],
          },
        ];
      }

      queryClient.setQueryData(queryKey, updates);

      return { previousReasons };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousReasons);

      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
      return error;
    },
  });
};
