import { XMarkIcon } from '@heroicons/react/20/solid';
import { cva, type VariantProps } from 'class-variance-authority';
import { HTMLAttributes, useState } from 'react';

import { cn } from '~/utils/shadcn';

// TODO have this primitive replace our current `Badge` component

const bannerVariants = cva(
  'flex items-center justify-center gap-x-6 px-6 py-2.5 sm:px-3.5 data-[dismissible=true]:sm:before:flex-1',
  {
    variants: {
      variant: {
        default: 'bg-corso-gray-800 text-sm text-corso-gray-50',
        danger: 'bg-corso-red-700 text-sm text-corso-red-50',
        info: 'bg-corso-blue-700 text-sm text-corso-blue-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type BannerProps = {
  dismissible?: boolean;
} & HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof bannerVariants>;

function Banner({
  className,
  variant,
  dismissible = false,
  children,
  ...props
}: BannerProps) {
  const [dismissed, setDismissed] = useState(false);

  if (dismissed) return null;

  return (
    <div
      data-dismissible={dismissible}
      className={cn(bannerVariants({ variant }), className)}
      {...props}
    >
      {children}
      {dismissible && (
        <div className="flex flex-1 justify-end">
          <button type="button" onClick={() => setDismissed(true)}>
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="size-6" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
}

export { Banner, bannerVariants };
