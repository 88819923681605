import MediaDisplay from './MediaDisplay';

/** Shows a collection of media assets, with consistent style thumbnails. */
export default function MediaGallery({
  media,
}: {
  media: { src: string; alt: string }[];
}) {
  if (!media.length) return null;
  return (
    <div
      className="grid grid-cols-3 gap-1 md:grid-cols-[repeat(auto-fill,minmax(5rem,1fr))] md:gap-2" // we may want to revisit this layout, and maybe do something masonry-style, or an automatic number of columns via auto-fit
    >
      {media.map(({ src, alt }) => (
        // key may need to be something more unique, but in each gallery, the src is likely enough to be unique
        <div className="aspect-square" key={src}>
          <MediaDisplay key={src} src={src} alt={alt} />
        </div>
      ))}
    </div>
  );
}
