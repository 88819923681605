import { Fragment, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type Description = { term: string; details: ReactNode };

/**
 * Simple extension of the native `dl`, `dt`, and `dd` HTML elements.
 */
export default function DescriptionList({
  descriptions,
  layoutType = 'grid',
}: {
  /** Each `term` should be unique, as these are used as a `key` for each. */
  descriptions: Description[];
  /** Determines if the list should be displayed in 'grid' or 'list' layout. */
  layoutType?: 'grid' | 'list';
}) {
  return (
    <dl
      className={twMerge(
        'text-xs text-corso-gray-500',
        layoutType === 'grid' ?
          'sm:grid-auto-cols grid sm:gap-x-3 sm:gap-y-3'
        : '',
      )}
    >
      {descriptions.map(({ term, details }) => (
        <Fragment key={term}>
          <dt
            className={twMerge(
              'font-semibold',
              layoutType === 'grid' ?
                'col-span-2 sm:col-span-1 sm:col-start-1'
              : 'mb-1',
            )}
          >
            {term}
          </dt>
          <dd
            className={twMerge(
              'break-words',
              layoutType === 'grid' ?
                'mb-2 sm:col-span-3 sm:col-start-2 sm:mb-0'
              : 'mb-3',
            )}
          >
            {details}
          </dd>
        </Fragment>
      ))}
    </dl>
  );
}
