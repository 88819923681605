import { useState } from 'react';
import { TextInput } from '~/components/field';
import { ReviewLineItem } from '~/providers/ClaimReviewProvider';

export default function ClaimLineItemNoteToCustomer({
  reviewLineItem,
  onChange,
}: {
  reviewLineItem: ReviewLineItem;
  onChange: (reviewLineItem: ReviewLineItem) => void;
}) {
  const [noteToCustomer, setNoteToCustomer] = useState(
    reviewLineItem.noteToCustomer ?? '',
  );

  return (
    <TextInput
      id={`line-item-comment-${reviewLineItem.claimLineItem.id}`}
      label="Note to Customer"
      value={noteToCustomer}
      onChange={(e) => {
        const note = e.currentTarget.value;
        setNoteToCustomer(note);
        onChange({
          ...reviewLineItem,
          noteToCustomer: note,
        });
      }}
    />
  );
}
