import { CrewMerchantUi } from 'corso-types';
import { twMerge } from 'tailwind-merge';
import { shippingClaimStatusName } from '~/utils/enumNameMaps';
import { formatter } from '~/utils/formatter';

export function ShippingProtectionClaimDisplay({
  claim,
}: {
  claim: NonNullable<CrewMerchantUi.CrewOrder['shippingClaims']>[number];
}) {
  return (
    <article>
      <h3 className="font-medium text-corso-gray-900">
        Shipping Claim Already Exists
      </h3>
      <span className="text-sm text-corso-gray-500">
        A Shipping Protection claim has already been created for this order.
      </span>
      <dl
        className={twMerge(
          'divide-outline grid grid-cols-2 divide-y *:py-2 [&>dd]:text-right',
          // this is to fix tailwindcss issue with using divide in grid
          '[&>dd:first-of-type]:!border-t-0',
        )}
      >
        <dt id="claimId">Confirmation</dt>
        <dd aria-labelledby="claimId">#{claim.id}</dd>
        <dt id="status">Status</dt>
        <dd aria-labelledby="status">
          {shippingClaimStatusName[claim.status]}
        </dd>
        <dt id="createdDate">Created</dt>
        <dd aria-labelledby="createdDate">{formatter.date(claim.createdOn)}</dd>
      </dl>
    </article>
  );
}
