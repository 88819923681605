import Card from '~/components/Card';
import DetailCard from '~/components/DetailCard';
import MediaDisplay from '~/components/MediaDisplay';
import MediaGallery from '~/components/MediaGallery';
import { useStoreId } from '~/hooks/useStoreId';
import { useClaimReviewContext } from '~/providers/ClaimReviewProvider';

export function RegistrationSummary() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');
  const storeId = useStoreId();

  const { originalStoreOrder } = claim;
  const { registration } = originalStoreOrder;

  if (!registration) return null;

  const { externalId, proofOfPurchaseFileUrls } = registration;

  const [first] = proofOfPurchaseFileUrls;

  return (
    <DetailCard
      linkTo={`/${storeId}/registrations/${registration.id}`}
      externalId={externalId}
      title="Registration"
    >
      {proofOfPurchaseFileUrls.length > 0 && (
        <div className="flex flex-col gap-2">
          <Card.Title>Proof of Purchase</Card.Title>
          {proofOfPurchaseFileUrls.length === 1 && first ?
            <MediaDisplay src={first} alt="Proof of Purchase" />
          : <MediaGallery
              media={proofOfPurchaseFileUrls.map((url) => ({
                src: url,
                alt: 'Proof of Purchase',
              }))}
            />
          }
        </div>
      )}
    </DetailCard>
  );
}
