import { useMerchantContext } from '~/providers/MerchantProvider';

/**
 * Extracts the `currencyCode` and `currencySymbol` from the current `storeUser` found in the `MerchantContext`.
 */
export function useCurrency() {
  const {
    storeUser: {
      store: { currencyCode, currencySymbol },
    },
  } = useMerchantContext();

  return { currencyCode, currencySymbol };
}
