import { useMemo } from 'react';
import { useConfigSettings } from './useConfigSettings';

export function useEnabledClaimType() {
  const { data: configSettings, isLoading } = useConfigSettings(
    ({ shippingProtection, warranties, returns, registrations }) => ({
      shippingProtection,
      warranties,
      returns,
      registrations,
    }),
  );

  const isShippingProtectionEnabled = !!configSettings?.shippingProtection;
  const isReturnsEnabled = !!configSettings?.returns?.isReturnsEnabled;
  const isWarrantyEnabled = !!configSettings?.warranties?.isWarrantiesEnabled;
  const isRegistrationEnabled =
    !!configSettings?.registrations?.isRegistrationEnabled;

  // ? opted to add this here as I had duplicated this logic in a (3) places
  // ? according to Torick, this is a best practice to avoid duplication
  // ? this comment was generated with assistance from the 'copilot' extension
  const isReturnOrWarrantyEnabled = isReturnsEnabled || isWarrantyEnabled;

  const derivedData = useMemo(
    () => ({
      isLoading,
      isReturnsEnabled,
      isWarrantyEnabled,
      isShippingProtectionEnabled,
      isReturnOrWarrantyEnabled,
      isRegistrationEnabled,
    }),
    [
      isLoading,
      isReturnsEnabled,
      isWarrantyEnabled,
      isShippingProtectionEnabled,
      isReturnOrWarrantyEnabled,
      isRegistrationEnabled,
    ],
  );

  return derivedData;
}
