import {
  ArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon,
  EnvelopeIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { CrewClaimRollupStatusCode, isTruthy } from 'corso-types';
import { PropsWithChildren, useState } from 'react';
import Alert from '~/components/Alert';
import Page from '~/components/Page';
import RelativeDateTime from '~/components/RelativeDateTime';
import {
  reviewableResolutionMethods,
  ReviewMetaStatus,
  unsupportedResolutionMethods,
  useClaimReviewContext,
} from '~/providers/ClaimReviewProvider';
import CloseClaim from './claimActions/CloseClaim';
import FinalizeClaim from './claimActions/FinalizeClaim';
import RefundPayment from './claimActions/RefundPayment';
import RequestMoreInfo from './claimActions/RequestMoreInfo';

export default function ClaimPage({ children }: PropsWithChildren) {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.getValues('claim');

  const [showFinalizeClaim, setShowFinalizeClaim] = useState(false);
  const [showRequestMoreInfo, setShowRequestMoreInfo] = useState(false);
  const [showCloseClaim, setShowCloseClaim] = useState(false);
  const [showRefundPayment, setShowRefundPayment] = useState(false);

  const hasUnsupportedReviewLineItems = unsupportedResolutionMethods.some(
    (resolutionMethod) => !!claim.reviewLineItems[resolutionMethod].length,
  );
  const hasUndecidedReviewLineItems = reviewableResolutionMethods.some(
    (reviewableResolutionMethod) =>
      !!claim.reviewLineItems[reviewableResolutionMethod].some(
        ({ reviewMetadata }) =>
          reviewMetadata.status === ReviewMetaStatus.undecided,
      ),
  );

  const claimId = claimReview.getValues('claim.id');
  const claimRollupCode = claimReview.getValues('claim.claimRollup.code');
  const platformAdminUrl = claimReview.getValues(
    'claim.originalStoreOrder.platformAdminUrl',
  );
  const orderNo = claimReview.getValues('claim.originalStoreOrder.orderNo');
  const customerPayment = claimReview.getValues('claim.customerPayment');

  return (
    <Page
      backAction
      title={
        claim.linkToCustomerUi ?
          {
            content: `#${claim.externalId}`,
            to: claim.linkToCustomerUi,
            target: '_blank',
          }
        : `#${claim.externalId}`
      }
      subtitle={
        <>
          <p>{claim.claimRollup.name}</p>
          <p>
            Created <RelativeDateTime dateTime={claim.createdOn} full />
          </p>
        </>
      }
      primaryAction={
        claimRollupCode !== CrewClaimRollupStatusCode.completed ?
          {
            content: 'Finalize',
            onAction: () => setShowFinalizeClaim(true),
            disabled:
              hasUnsupportedReviewLineItems || hasUndecidedReviewLineItems,
          }
        : undefined
      }
      secondaryActions={[
        platformAdminUrl && {
          id: 'view-order',
          icon: ArrowTopRightOnSquareIcon,
          content: orderNo ? `View Order ${orderNo}` : 'View Order',
          to: platformAdminUrl,
          target: '_blank',
        },
        {
          id: 'more-actions',
          content: 'More Actions',
          actions: [
            {
              id: 'request-more-info',
              icon: EnvelopeIcon,
              content: 'Request More Info',
              onAction: () => setShowRequestMoreInfo(true),
            },
            claimRollupCode !== CrewClaimRollupStatusCode.completed && {
              id: 'close-claim',
              icon: XMarkIcon,
              content: 'Close Claim',
              onAction: () => setShowCloseClaim(true),
            },
            customerPayment?.status === 'Captured' && {
              id: 'refund-payment',
              icon: ArrowUturnLeftIcon,
              content: 'Refund Payment',
              onAction: () => setShowRefundPayment(true),
            },
          ].filter(isTruthy),
        },
      ].filter(isTruthy)}
    >
      {hasUnsupportedReviewLineItems && (
        <Alert
          variant="warning"
          title="Cannot Finalize Claim"
          message="Claim contains non-reviewable resolution methods and cannot be finalized."
        />
      )}
      {children}
      <FinalizeClaim
        show={showFinalizeClaim}
        onClose={() => setShowFinalizeClaim(false)}
      />
      <RequestMoreInfo
        show={showRequestMoreInfo}
        onClose={() => setShowRequestMoreInfo(false)}
      />
      <CloseClaim
        claimId={claimId}
        show={showCloseClaim}
        onClose={() => setShowCloseClaim(false)}
      />
      {customerPayment && (
        <RefundPayment
          payment={customerPayment}
          show={showRefundPayment}
          onClose={() => setShowRefundPayment(false)}
        />
      )}
    </Page>
  );
}
