import { CrewMerchantUi } from 'corso-types';
import { ReactNode } from 'react';
import RelativeDateTime from './RelativeDateTime';

type CustomField = CrewMerchantUi.AppliedCustomField;

// Retrieve the underlying enum or type for the valueType
type CustomFieldValueType = CustomField['valueType'];

export const customFieldValueTypeFormatter = {
  Text: (value: string) => value,
  Number: (value: string) => value,
  Date: (value: string) => <RelativeDateTime dateTime={value} />,
  Select: (value: string) => value,
} as const satisfies Record<CustomFieldValueType, (value: string) => ReactNode>;
