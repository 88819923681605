import { CorsoClaimType } from 'corso-types';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { Fieldset, TextInput } from '~/components/field';
import { customerSchema, type FormValues } from './claimCreateSchemas';

export default function CustomerFields({
  claimType,
}: {
  claimType: CorsoClaimType;
}) {
  const { register, getValues, formState, watch } =
    useFormContext<FormValues>();
  const customer = watch('customer'); // updates when input changes
  const defaultCustomerValues = getValues('customer');
  const defaultOpen = useMemo(
    () => !customerSchema.safeParse(defaultCustomerValues).success,
    [defaultCustomerValues],
  );

  return (
    <Disclosure
      defaultOpen={defaultOpen}
      renderSummary={(open) => (
        <SimpleSummary>
          <div className="flex flex-grow items-baseline gap-2">
            Customer
            <span className="text-xs" hidden={open}>
              {`${customer.firstName} ${customer.lastName}`.trim()} (
              {customer.email})
            </span>
          </div>
        </SimpleSummary>
      )}
    >
      {/* // TODO abstract address form to be reused between claim details and claim create */}
      <Fieldset legend="Customer">
        <div className="space-y-4">
          <div className="flex flex-wrap gap-4">
            <TextInput
              className="flex-grow"
              id="first-name"
              label="First Name"
              autoComplete="first-name"
              disabled={
                !!customer.firstName &&
                claimType === CorsoClaimType.shippingProtection
              }
              required
              {...register('customer.firstName')}
              error={formState.errors.customer?.firstName?.message}
            />
            <TextInput
              className="flex-grow"
              id="last-name"
              label="Last Name"
              autoComplete="last-name"
              disabled={
                !!customer.lastName &&
                claimType === CorsoClaimType.shippingProtection
              }
              required
              {...register('customer.lastName')}
              error={formState.errors.customer?.lastName?.message}
            />
          </div>
          <TextInput
            id="email"
            label="Email"
            autoComplete="email"
            disabled={claimType === CorsoClaimType.shippingProtection}
            required
            {...register('customer.email')}
            error={formState.errors.customer?.email?.message}
          />
          <TextInput
            id="phone"
            label="Phone"
            autoComplete="tel"
            {...register('customer.phone')}
            error={formState.errors.customer?.phone?.message}
          />
        </div>
      </Fieldset>
    </Disclosure>
  );
}
