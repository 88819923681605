import { Outlet } from 'react-router-dom';

export default function AuxiliaryLayout() {
  return (
    <main className="flex min-h-full items-center justify-center bg-corso-gray-800">
      <div className="flex max-w-md flex-grow flex-col rounded-md bg-white p-12 text-base text-corso-gray-900 shadow-md">
        <img
          className="mx-auto mb-8 block max-h-14 max-w-full object-contain"
          src="https://cdn.corso.com/img/Corso_Logo_Auth0.png"
          alt="Corso"
        />
        <Outlet />
      </div>
    </main>
  );
}
