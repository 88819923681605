import { cva, VariantProps } from 'class-variance-authority';
import { ReactNode, useId } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/primitives/Dialog';

type ModalProps = {
  show: boolean;
  title: string;
  icon?: ReactNode;
  description?: string;
  onClose: () => void;
  // ? might need to add support for a general/default modal vs a full width modal
  // ? might need to add support to pass through `initialFocus`
  children: ReactNode; // required
  /**
   * Suggested to provide multiple actions within a fragment and provide them in order of increasing importance, with the primary action being last.
   * The actions are render in a row on desktop, and a reversed column on mobile.
   */
  actions?: ReactNode;
} & VariantProps<typeof modalVariants>;

const modalVariants = cva('', {
  variants: {
    size: {
      xl: 'max-w-4xl',
      default: 'max-w-lg',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export default function Modal({
  show,
  title,
  icon,
  description,
  onClose,
  children,
  actions,
  size,
}: ModalProps) {
  const id = useId();
  return (
    <Dialog
      modal // prevent outside interaction
      open={show}
      onOpenChange={(open) => {
        if (!open) onClose(); // TODO revisit usage to be onOpenChange
      }}
    >
      <DialogContent
        className={modalVariants({ size })}
        aria-describedby={!description ? undefined : id} // explicitly not described through a description when missing (this is not the title/label), resolves relevant a11y warning
      >
        <DialogHeader>
          {icon}
          <DialogTitle>{title}</DialogTitle>
          {description && (
            <DialogDescription id={id}>{description}</DialogDescription>
          )}
        </DialogHeader>
        {children}
        {actions && <DialogFooter>{actions}</DialogFooter>}
      </DialogContent>
    </Dialog>
  );
}
