import { forwardRef, Ref } from 'react';
import Input, { InputTypeProps } from './Input';

const TextInput = forwardRef(
  ({ ...rest }: InputTypeProps<string>, ref: Ref<HTMLInputElement>) => (
    <Input type="text" ref={ref} {...rest} />
  ),
);

export default TextInput;
