import { Outlet } from 'react-router-dom';
import { z } from 'zod';
import Page from '~/components/Page';
import SearchTable from '~/components/searchTable/SearchTable';
import { usePathParams } from '~/hooks/usePathParams';

const paramsSchema = z.object({
  storeId: z.string(),
  registrationId: z.string().optional(),
});

/** Represents a sidebar and main content layout, which intelligently shows both on desktop, while separating the sidebar list navigation and individual items on mobile. */
export default function RegistrationsLayout() {
  const params = usePathParams(paramsSchema);

  if (params.registrationId) {
    return <Outlet />;
  }

  return (
    <Page title="Registrations" size="fullWidth">
      <SearchTable entity="Registration" />
    </Page>
  );
}
