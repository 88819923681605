import { ReactNode, useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from './primitives/Label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './primitives/Select';

/**
 * The `value` of each option is expected to be unique, as it is also used as the `key`.
 * The `label` can be any `ReactNode` to represent the option in the UI; however, it's suggested to keep it mostly prose.
 */
export type SimpleSelectOption<T extends string = string> = {
  value: T;
  label: ReactNode;
};

// TODO consider alternative names
// TODO add JSDoc comment for suggested use cases and even more complex examples
// TODO add and verify unbounded generic type `T` works as expected with non-string values

export default function SimpleSelect<T extends string>({
  label,
  labelVisuallyHidden = false,
  options,

  defaultValue,
  value,
  onChange,

  defaultOpen,
  open,
  onOpenChange,

  name,
  disabled,
  required,

  placeholder = 'Select an option',
  error,
  details,
}: {
  /**
   * A label for the selection, to provide context for the user.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  label: ReactNode;
  // TODO consider alternative names
  labelVisuallyHidden?: boolean;
  /** Should be a stable reference to a series of objects. Otherwise, this will result in undesirable behavior, as items cannot be uniquely identified between renders based by reference. */
  options: SimpleSelectOption<T>[];

  defaultValue?: T;
  value?: T;
  onChange?: NoInfer<(value: T) => void>;

  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;

  name?: string;
  disabled?: boolean;
  required?: boolean;

  placeholder?: string;
  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with prose elements.
   */
  error?: ReactNode;
}) {
  const id = useId();

  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor={id} className={twMerge(labelVisuallyHidden && 'sr-only')}>
        {label}
      </Label>
      <Select
        defaultValue={defaultValue}
        value={value}
        onValueChange={onChange}
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        name={name}
        disabled={disabled}
        required={required}
      >
        <SelectTrigger id={id}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {/* // TODO should these both use `SupportingText` */}
      {error && (
        <section className="text-xs text-corso-red-600">{error}</section>
      )}
      {details && (
        <section className="text-xs text-corso-gray-500">{details}</section>
      )}
    </div>
  );
}
