import { ChangeEvent, ReactNode, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import SupportingText from './SupportingText';

export default function RadioGroupInput<T extends Record<string, string>>({
  id,
  label,
  labelVisuallyHidden = false,
  details,
  error = false,
  value,
  onChange,
  options,
}: {
  /** Used as a base `id` for each option, but also used as the `name`. */
  id: string;
  label: string;
  labelVisuallyHidden?: boolean;
  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with multiple paragraphs for simplicity.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state of the `input`.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with multiple paragraphs for simplicity.
   */
  error?: ReactNode;
  /** Required to enforce being controlled. */
  value: keyof T;
  /** Required to enforce being controlled. */
  onChange: (value: keyof T) => void;
  options: T;
}) {
  const onOptionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const detailsId = `${id}-details`;
  const errorId = `${id}-error`;

  return (
    <div>
      <p
        className={twMerge(
          'flex items-center gap-1 text-sm font-medium leading-6 text-corso-gray-800',
          labelVisuallyHidden && 'sr-only',
        )}
      >
        {label}
      </p>
      {error && (
        <SupportingText error id={errorId}>
          {error}
        </SupportingText>
      )}
      {details && <SupportingText id={detailsId}>{details}</SupportingText>}
      <fieldset className={twMerge(!labelVisuallyHidden && 'mt-4')}>
        <legend className="sr-only">{label}</legend>
        <div className="space-y-4">
          {Object.entries(options).map(([optionKey, optionValue]) => (
            <div key={optionKey} className="flex items-center">
              <input
                id={`${id}-${optionKey}`}
                name={id}
                type="radio"
                value={optionKey}
                checked={value === optionKey}
                onChange={onOptionChange}
                className="h-4 w-4 border-corso-gray-300 text-corso-blue-600 accent-corso-blue-600 focus:ring-corso-blue-600"
              />
              <label
                htmlFor={`${id}-${optionKey}`}
                className="ml-3 block text-sm text-corso-gray-800"
              >
                {optionValue}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
