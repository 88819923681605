import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Card from '~/components/Card';
import ClipboardButton from '~/components/ClipboardButton';
import {
  ColorInput,
  SwitchInput,
  TextAreaInput,
  TextInput,
  UrlInput,
} from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import IconAction from '~/components/IconAction';
import Page from '~/components/Page';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import {
  useThemeSettings,
  useThemeSettingsUpdate,
} from '~/hooks/useThemeSettings';
import { themeSettingsFormSchema } from '~/types';

const formId = 'theme-settings';

export default function ThemeSettings() {
  const { data } = useThemeSettings();
  const { mutateAsync: saveChanges } = useThemeSettingsUpdate();

  const { isReturnOrWarrantyEnabled } = useEnabledClaimType();

  const {
    register,
    formState: { errors, isDirty, isSubmitting },
    reset,
    control,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(themeSettingsFormSchema),
    // * intentionally not using `defaultValues`, since the color inputs must be controlled to stay in sync
    values: {
      merchantPrimaryColor: data?.merchantPrimaryColor ?? '#000000',
      merchantBackgroundColor: data?.merchantBackgroundColor ?? '#ffffff',
      merchantBackgroundUrl: data?.merchantBackgroundUrl ?? '',
      merchantLogoUrl: data?.merchantLogoUrl ?? '',
      merchantFaviconUrl: data?.merchantFaviconUrl ?? '',
      footerText: data?.footerText ?? '',
      orderLookupDetailText: data?.orderLookupDetailText ?? '',
      merchantPageTitle: data?.merchantPageTitle ?? '',
      orderNumberDescriptionText: data?.orderNumberDescriptionText ?? '',
      noReturnRequiredItemsDetailText:
        data?.noReturnRequiredItemsDetailText ?? '',
      warrantyItemsDetailText: data?.warrantyItemsDetailText ?? '',
      offerGiftCardDetailText: data?.offerGiftCardDetailText ?? '',
      offerRefundDetailText: data?.offerRefundDetailText ?? '',
      noReturnRequiredItemsHeadline: data?.noReturnRequiredItemsHeadline ?? '',
      hideLabelDownloadWhenQrCodeIsPresent:
        data?.hideLabelDownloadWhenQrCodeIsPresent ?? false,
    },
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((updates) => saveChanges(updates))(event).catch(console.error);
  };

  return (
    <Page title="Theme">
      <form
        id={formId}
        className="flex flex-col gap-4"
        onSubmit={submitHandler}
        onReset={(e) => {
          e.preventDefault();
          reset();
        }}
      >
        <FloatingSave
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          form={formId}
        />
        <Card>
          <Card.Title as="h2">Customer Portal URL</Card.Title>
          <UrlInput
            id="customer-portal"
            label="Customer Portal URL"
            labelVisuallyHidden
            details="Use this link to direct users to the customer portal. Reach out to us if you would like to modify this URL."
            // maybe someday allow editing the slug in the url
            value={data?.customerAppUrl ?? ''}
            required
            readOnly
            disabled
            addon={{
              outsideEnd: (
                // using `grid` on mobile and `flex` on desktop avoid apply `flex-grow` to each child
                <div className="grid grid-cols-2 items-center gap-2 md:flex">
                  {/* // ? adjust to be styled as a button group  */}
                  <ClipboardButton onClick={() => data?.customerAppUrl ?? ''} />
                  <IconAction.Link
                    to={data?.customerAppUrl ?? ''}
                    target="_blank"
                    title="Visit"
                    icon={ArrowTopRightOnSquareIcon}
                  />
                </div>
              ),
            }}
          />
        </Card>
        <Card>
          <Card.Title as="h2">Branding</Card.Title>
          {/* the customer portal feels like it should go elsewhere; however, it will remain here until a better place it determined */}

          <Controller
            name="merchantPrimaryColor"
            control={control}
            render={({ field: { onChange, value } }) => (
              // maybe someday this can be revised as an uncontrolled component
              <ColorInput
                id="brand-color"
                label="Brand Color"
                details={
                  <p>
                    This color is used as a background for buttons and other
                    content areas. Choose a dark brand color for best results.{' '}
                    <a
                      className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
                      href="https://webaim.org/resources/contrastchecker/?fcolor=FFFFFF&bcolor=000000"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Explore dark backgrounds with accessibility in mind.
                    </a>
                  </p>
                }
                placeholder="#rrggbb"
                required
                // * values will be reflected in the preview once controlled by state
                value={value}
                onChange={onChange}
                error={errors.merchantPrimaryColor?.message}
              />
            )}
          />
          <Controller
            name="merchantBackgroundColor"
            control={control}
            render={({ field: { onChange, value } }) => (
              // maybe someday this can be revised as an uncontrolled component
              <ColorInput
                id="background-color"
                label="Background Color"
                placeholder="#rrggbb"
                required
                // * values will be reflected in the preview once controlled by state
                value={value}
                onChange={onChange}
                error={errors.merchantBackgroundColor?.message}
              />
            )}
          />
          {/* // TODO re-add image file uploads and revisit component design on mobile for removing file */}
          <UrlInput
            id="brand-background"
            label="Background Image"
            details="If provided, this will override the background color."
            placeholder="https://example.com/image.jpg"
            {...register('merchantBackgroundUrl')}
            error={errors.merchantBackgroundUrl?.message}
          />
          <UrlInput
            id="brand-logo"
            label="Logo"
            details="Required aspect ratio of 2:1. 200px x 100px recommended."
            placeholder="https://example.com/image.jpg"
            required
            {...register('merchantLogoUrl')}
            error={errors.merchantLogoUrl?.message}
          />
          <UrlInput
            id="brand-favicon"
            label="Favicon"
            details="64px x 64px recommended."
            placeholder="https://example.com/image.jpg"
            required
            {...register('merchantFaviconUrl')}
            error={errors.merchantFaviconUrl?.message}
          />
        </Card>
        <Card>
          <Card.Title as="h2">Customer Portal Text Customization</Card.Title>
          <TextInput
            id="page-title"
            label="Title"
            details="This will appear in the browser tab of the customer portal. Often used to display the company name or a brief description of the page."
            placeholder="Return, Exchange, and Warranty"
            {...register('merchantPageTitle')}
            error={errors.merchantPageTitle?.message}
          />
          <TextInput
            id="order-lookup-detail-text"
            label="Order Lookup Text"
            details="This will appear on the order lookup page of the customer portal. Often used to display custom instructions, or reminders about the request process."
            placeholder="Refer to our return policy for more information."
            {...register('orderLookupDetailText')}
            error={errors.orderLookupDetailText?.message}
          />
          <TextInput
            id="order-number-description"
            label="Order Number Description Text"
            details="This will appear on the order lookup page of the customer portal. Often used to display examples of order numbers."
            placeholder="123456, RS-98765, etc."
            {...register('orderNumberDescriptionText')}
            error={errors.orderNumberDescriptionText?.message}
          />
          <TextInput
            id="footer-text"
            label="Footer Text"
            details="This will appear in the footer of the customer portal. Often used to display a support email for your company."
            placeholder="support@company.com"
            required // ? probably should be optional; however is defined to be required in the schema
            {...register('footerText')}
            error={errors.footerText?.message}
          />
        </Card>

        {isReturnOrWarrantyEnabled && (
          <Card>
            <Card.Title as="h2">Return Customization</Card.Title>
            <Controller
              control={control}
              name="hideLabelDownloadWhenQrCodeIsPresent"
              render={({ field: { onChange, value } }) => (
                <SwitchInput
                  id="notify-customer"
                  label="Hide Label Download"
                  details="When enabled, the physical label will be hidden when a QR code is present. This is useful to prevent certain types of return label fraud. "
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
            <TextInput
              id="no-return-required-submission-headline"
              label="Return Not Required Submission Headline"
              details="This will appear on the overview page after the claim has been submitted above the Return Not Required Submission text."
              placeholder="Items not required to return"
              {...register('noReturnRequiredItemsHeadline')}
              error={errors.noReturnRequiredItemsHeadline?.message}
            />
            <TextInput
              id="no-return-required-text"
              label="Return Not Required Submission Text"
              details="This will appear on the overview page after the claim has been submitted on line items that are not required to be shipped back. "
              placeholder="You do not need to return these items."
              {...register('noReturnRequiredItemsDetailText')}
              error={errors.noReturnRequiredItemsDetailText?.message}
            />
            <TextAreaInput
              id="offer-gift-card-detail-text"
              label="Gift Card Detail Text"
              details="This will appear on the money back options page when a gift card is offered as one of the options."
              placeholder="Get a gift card for store credit. A redeemable code will be sent via email when your return has been approved."
              {...register('offerGiftCardDetailText')}
              error={errors.offerGiftCardDetailText?.message}
            />
            <TextAreaInput
              id="offer-refund-detail-text"
              label="Refund Detail Text"
              details="This will appear on the money back options page when a refund is offered as one of the options."
              placeholder="Disclaimer: Receive a refund to your original payment method once your return has been approved."
              {...register('offerRefundDetailText')}
              error={errors.offerRefundDetailText?.message}
            />
          </Card>
        )}

        {isReturnOrWarrantyEnabled && (
          <Card>
            <Card.Title as="h2">Warranty Customization</Card.Title>
            <TextInput
              id="warranty-items-detail-text"
              label="Warranty Submission Text"
              details="This will appear on the overview page after the claim has been submitted on warranty line items."
              placeholder="Thank you for submitting your warranty claim, we will be in touch soon."
              {...register('warrantyItemsDetailText')}
              error={errors.warrantyItemsDetailText?.message}
            />
          </Card>
        )}
      </form>
    </Page>
  );
}
