import { forwardRef, Ref } from 'react';
import Input, { InputTypeProps } from './Input';

// ? maybe address this quirk in the future
/**
 * The `Color` field parts are not synced unless a `value` and `onChange` handler are provided.
 * The forwarded ref is linked to the native color input, not the text input.
 */
const ColorInput = forwardRef(
  (
    {
      id,
      onChange,
      value,
      ...rest
    }: Omit<InputTypeProps<string, true>, 'addon'>,
    // ? might need to consider multiple values int the `ref` for the two underlying inputs
    ref: Ref<HTMLInputElement>,
  ) => {
    // ensures it's always prefixed with a hash, and is lowercase
    const preformattedValue =
      value?.startsWith('#') ?
        value.toLowerCase()
      : `#${value?.toLowerCase() ?? ''}`;
    return (
      <Input
        type="text"
        id={id}
        value={preformattedValue}
        onChange={onChange}
        pattern="^#[0-9a-f]{6}$"
        {...rest}
        addon={{
          insideStart: (
            <>
              <label
                htmlFor={`${id}-native`}
                className="block size-6 rounded-md border"
                style={{
                  background:
                    /^#[0-9a-f]{6}$/i.test(preformattedValue) ?
                      preformattedValue
                    : '#000000',
                }}
              >
                <span className="sr-only">Pick a color</span>
              </label>
              <input
                type="color"
                id={`${id}-native`}
                className="sr-only"
                value={preformattedValue}
                onChange={onChange}
                ref={ref}
                {...rest}
              />
            </>
          ),
          insideEnd: 'HEX',
        }}
      />
    );
  },
);

export default ColorInput;
