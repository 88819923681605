import { useVirtualizer } from '@tanstack/react-virtual';
import { ReactNode, useRef } from 'react';

/**
 * The default number of items to render in the visible area.
 * This is set at a global level, but can be overridden per-instance.
 */
export const DEFAULT_VISIBLE_COUNT = 10;

/**
 * Render a vertically virtualized list of `items` using [`@tanstack/react-virtual`](https://tanstack.com/virtual/latest/docs/framework/react/react-virtual).
 * Take care to try and make the `estimateSize` for an item as accurate as possible.
 *
 * Any excessive number of items should likely be paginated instead of virtualized; although, this will work for [Infinite Scroll](https://tanstack.com/virtual/latest/docs/framework/react/examples/infinite-scroll).
 *
 * Importantly, the if the size and quantity of the items exceeds the browser's precision for `height`, only part of the list will be accessible through scrolling.
 * This may be fixed with scaling in the future, or we may consider an alternative package.
 *
 * "CSS theoretically supports infinite precision and infinite ranges for all value types; however in reality implementations have finite capacity. UAs should support reasonably useful ranges and precisions." [(W3C CSS Values)](https://www.w3.org/TR/css3-values/#lengths:~:text=CSS%20theoretically%20supports%20infinite%20precision%20and%20infinite%20ranges%20for%20all%20value%20types%3B%20however%20in%20reality%20implementations%20have%20finite%20capacity.%20UAs%20should%20support%20reasonably%20useful%20ranges%20and%20precisions.)
 *
 * @see [Large Set of Data Not Fully Rendered #460](https://github.com/TanStack/virtual/issues/460)
 * @see [Reach Browser Max Height Pixels #616](https://github.com/TanStack/virtual/issues/616)
 * @see [What's the Maximum Pixel Value of CSS Width and Height Properties?](https://stackoverflow.com/questions/16637530/whats-the-maximum-pixel-value-of-css-width-and-height-properties/16637689#16637689)
 * @see [Should I Drop Scaling Support `react-virtualized` #396](https://github.com/bvaughn/react-virtualized/issues/396)
 *
 * Regarding unit testing the tests for this component provide a utility called `mockVirtualized` which can be used to mock the virtualized environment.
 */
export default function Virtualizer<T>({
  items,
  estimateSize,
  children: renderItem,
  visibleCount = DEFAULT_VISIBLE_COUNT,
}: {
  items: T[];
  estimateSize: (index: number) => number;
  children: (item: T) => ReactNode;
  /**
   * The maximum number of items visible in the scroll area. Determines the `max-height` of the wrapper with average item height.
   */
  visibleCount?: number;
}) {
  const parent = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parent.current,
    estimateSize,
  });

  const averageSize = Math.floor(virtualizer.getTotalSize() / items.length);

  // ? consider warning if the total size exceeds the current precision

  if (!items.length) return null;

  return (
    <div
      ref={parent}
      className="max-h-[--virtual-wrapper-height] overflow-auto"
      style={{
        '--virtual-wrapper-height': `min(${visibleCount * averageSize}px, 50svh)`,
      }}
      data-virtualized
    >
      <div
        className="relative h-[--virtual-height] w-full"
        style={{ '--virtual-height': `${virtualizer.getTotalSize()}px` }}
      >
        {virtualizer.getVirtualItems().map((virtualItem) => {
          const item = items[virtualItem.index];
          if (item === undefined) return null; // just really to satisfy TypeScript
          return (
            <div
              key={virtualItem.key}
              data-index={virtualItem.index}
              ref={virtualizer.measureElement}
              className="height-[--virtual-item-size] absolute left-0 top-0 w-full translate-y-[--virtual-item-start]"
              style={{
                '--virtual-item-size': `${virtualItem.size}px`,
                '--virtual-item-start': `${virtualItem.start}px`,
              }}
            >
              {renderItem(item)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
