import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  ClaimReasonGroup,
  ClaimReasonGroupCreate,
  ClaimReasonGroupUpdate,
} from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useToast } from './useToast';

const useStoreId = () => {
  const { storeUser } = useMerchantContext();

  return storeUser.storeId.toString();
};

const useQueryKey = () => {
  const storeId = useStoreId();

  return ['claimReasonGroups', storeId] as const;
};

export const useClaimReasonGroups = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).claimReason.groups.get(),
  });
};

export const useClaimReasonGroupUpsert = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const toast = useToast();
  const queryKey = useQueryKey();

  return useMutation({
    mutationFn: (group: ClaimReasonGroupUpdate | ClaimReasonGroupCreate) =>
      'id' in group ?
        api.store(storeId).claimReason.groups.update(group)
      : api.store(storeId).claimReason.groups.create(group),
    onMutate: (group: ClaimReasonGroupUpdate | ClaimReasonGroupCreate) => {
      const previousGroups =
        queryClient.getQueryData<ClaimReasonGroup[]>(queryKey) ?? [];

      let updates: ClaimReasonGroup[] = [];
      if ('id' in group) {
        const index = previousGroups.findIndex((g) => g.id === group.id);
        updates = [...previousGroups];
        updates.splice(index, 1, group);
      } else {
        updates = [
          ...previousGroups,
          { ...group, id: Number.MAX_SAFE_INTEGER },
        ];
      }

      queryClient.setQueryData(queryKey, updates);

      return { previousGroups };
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error, group, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
    },
  });
};

export const useClaimReasonGroupDelete = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const toast = useToast();
  const queryKey = useQueryKey();

  return useMutation({
    mutationFn: (group: ClaimReasonGroup) =>
      api.store(storeId).claimReason.groups.delete(String(group.id)),
    onMutate: ({ id }: ClaimReasonGroup) => {
      const previousGroups =
        queryClient.getQueryData<ClaimReasonGroup[]>(queryKey) ?? [];
      queryClient.setQueryData(
        queryKey,
        previousGroups.filter((g) => g.id !== id),
      );
      return { previousGroups };
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error, id, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
    },
  });
};
