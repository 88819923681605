import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook as faAddressBookLight,
  faBadgeCheck as faBadgeCheckLight,
  faBell as faBellLight,
  faBoxCheck as faBoxCheckLight,
  faChartPieSimple as faChartPieSimpleLight,
  faChevronLeft,
  faChevronRight,
  faExchange as faExchangeLight,
  faGear as faGearLight,
  faMagnifyingGlass as faMagnifyingGlassLight,
  faQuestionCircle,
  faSparkles as faSparklesLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAddressBook as faAddressBookSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBars,
  faBell as faBellSolid,
  faBoxCheck as faBoxCheckSolid,
  faChartPieSimple as faChartPieSimpleSolid,
  faCheck,
  faCircleRight,
  faExchange as faExchangeSolid,
  faGear as faGearSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faSparkles as faSparklesSolid,
  faStore,
  faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import {
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
  DbJsonSchema,
  RoleCode,
} from 'corso-types';
import { differenceInDays } from 'date-fns';
import {
  ComponentProps,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Toaster } from 'react-hot-toast';
import { NavLink, Outlet, To, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import CorsoLogo from '~/components/CorsoLogo';
import { LoadingBar, LoadingProvider } from '~/components/Loading';
import QuickSearch from '~/components/QuickSearch';
import SubscriptionExpired from '~/components/SubscriptionExpired';
import { Banner } from '~/components/ui/Banner';
import { Combobox, ComboboxOption } from '~/components/ui/Combobox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '~/components/ui/primitives/Popover';
import SimpleTooltip from '~/components/ui/SimpleTooltip';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import useIsAdmin from '~/hooks/useIsAdmin';
import { useThemeSettings } from '~/hooks/useThemeSettings';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { useSettingsActionsContext } from '~/providers/SettingsActionsProvider';
import { cn } from '~/utils/shadcn';
import { queryParamKey } from './SettingsLayout';

function SettingsChecklist() {
  const [isOpen, setIsOpen] = useState(false);
  const { actions } = useSettingsActionsContext();
  const hasIncompleteItems = !!actions.filter((item) => !item.isComplete)
    .length;

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button
          type="button"
          className="group relative flex size-8 items-center justify-center rounded-full bg-neutral-900 p-1 focus-within:bg-neutral-800 hover:bg-neutral-800 data-[state=open]:bg-neutral-800"
        >
          <span className="sr-only">Action Items</span>
          <FontAwesomeIcon
            icon={faBellLight}
            className="text-white group-data-[state=open]:hidden"
          />
          <FontAwesomeIcon
            icon={faBellSolid}
            className="hidden text-white group-data-[state=open]:block"
          />
          {hasIncompleteItems && (
            <span className="absolute right-2 top-2 size-2 rounded-full bg-corso-red-400" />
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent align="end">
        <section className="flex flex-col gap-2">
          <header>
            <p className="text-sm font-semibold">Settings Checklist</p>
            <p className="text-xs text-neutral-600">
              To get started, complete the following items to configure your
              settings.{' '}
              <a
                href="https://help.corso.com/getting-started/install-the-corso-app/"
                target="_blank"
                rel="noreferrer"
                className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
              >
                Learn More
              </a>
            </p>
          </header>
          <ul className="-mx-2 -mb-2 flex flex-col gap-px overflow-hidden rounded-lg border border-neutral-200 bg-neutral-200">
            {actions.map((action) => (
              <li key={action.label}>
                <NavLink
                  to={action.to}
                  className="flex items-center justify-between gap-2 bg-white px-3 py-2 text-sm text-neutral-800 hover:bg-neutral-50"
                  onClick={() => setIsOpen(false)}
                >
                  <div className="flex items-center gap-2">
                    {action.isComplete ?
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="size-4 shrink-0 text-corso-green-700"
                        aria-label="Completed"
                      />
                    : <FontAwesomeIcon
                        icon={faCircleRight}
                        className="size-4 shrink-0 text-neutral-600"
                        aria-label="Incomplete"
                      />
                    }
                    <span className="text-sm">{action.label}</span>
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </section>
      </PopoverContent>
    </Popover>
  );
}

// TODO better name based on the `StoreUser` component
function StoreUserNavLink({
  children,
  to,
}: PropsWithChildren<{
  to: To;
}>) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(
          'rounded-md px-2 py-1 text-sm focus-within:bg-neutral-100 hover:bg-neutral-100',
          isActive && 'bg-neutral-100',
        )
      }
    >
      {/* // TODO consider a toggleable link, like the `SidebarIcon` */}
      {children}
    </NavLink>
  );
}

// TODO better name
function StoreUser() {
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const { user, storeUser, changeStoreUser } = useMerchantContext();
  const { data: themeSettings } = useThemeSettings();

  const storeOptions = useMemo(() => {
    const { storeUserRoles } = user;
    const storeNameCount = new Map<string, number>();
    storeUserRoles.forEach((storeUserRole) => {
      const count = storeNameCount.get(storeUserRole.store.name) ?? 0;
      storeNameCount.set(storeUserRole.store.name, count + 1);
    });

    return storeUserRoles
      .map((storeUserRole) => {
        const isDuplicateName =
          (storeNameCount.get(storeUserRole.store.name) ?? 0) > 1;

        const text =
          isDuplicateName ?
            `${storeUserRole.store.name} (ID: ${storeUserRole.store.id})`
          : storeUserRole.store.name;
        return {
          value: `${storeUserRole.store.id}`,
          searchValue: text,
          label: text,
        } satisfies ComboboxOption;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [user]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          type="button"
          className="flex items-center gap-2 rounded-md bg-neutral-900 p-1 focus-within:bg-neutral-800 hover:bg-neutral-800 data-[state=open]:bg-neutral-800"
        >
          <div className="flex size-7 items-center justify-center rounded-lg bg-neutral-700 text-[0.625rem] font-medium text-white no-underline">
            {(
              themeSettings?.merchantFaviconUrl &&
              themeSettings.merchantFaviconUrl !==
                DbJsonSchema.StoreUiConfig.defaultStoreUiTheme
                  .merchantFaviconUrl
            ) ?
              <img
                src={themeSettings.merchantFaviconUrl}
                alt={storeUser.store.name}
                // ? maybe apply grayscale filter, but should test with transparent favicons and favicons with backgrounds
                className="size-7 rounded-lg bg-cover bg-center p-1"
              />
            : <FontAwesomeIcon icon={faStore} className="size-4" />}
          </div>
          <span
            className="hidden max-w-[24ch] truncate text-xs font-medium text-neutral-200 lg:block"
            title={storeUser.store.name}
          >
            {storeUser.store.name}
          </span>
        </button>
      </PopoverTrigger>
      <PopoverContent align="end" className="flex flex-col gap-2 p-2">
        {storeOptions.length > 1 && (
          <Combobox
            label="Select Store"
            options={storeOptions}
            placeholder="Search for a Store"
            value={`${storeUser.store.id}`}
            onChange={(storeId) =>
              storeId !== undefined &&
              changeStoreUser(Number.parseInt(storeId, 10))
            }
          />
        )}
        {storeOptions.length === 1 && (
          <p className="px-2 text-sm">{storeUser.store.name}</p>
        )}
        <div className="contents" hidden={!isAdmin}>
          <hr />
          <StoreUserNavLink to="billing">Billing</StoreUserNavLink>
          {/* // TODO remove this link to settings once users are more used to the sidebar */}
          <StoreUserNavLink
            to={{
              pathname: 'settings',
              search: new URLSearchParams({
                [queryParamKey.returnTo]: `${location.pathname}${location.search}`,
                [queryParamKey.settingsNavigation]: 'true',
              }).toString(),
            }}
          >
            Settings
          </StoreUserNavLink>
        </div>
        <hr />
        <div className="flex gap-2 px-2">
          <div className="truncate text-sm">
            <p>
              {user.firstName} {user.lastName}
            </p>
            <p className="text-xs text-neutral-600">{user.email}</p>
          </div>
        </div>
        <StoreUserNavLink to="/sign-out">Log Out</StoreUserNavLink>
      </PopoverContent>
    </Popover>
  );
}

function UserWarningAppBanner() {
  const { user, storeUser } = useMerchantContext();

  //! temporary sanity check as multiple people are using this login during the Beta
  const show = storeUser.roleCode === RoleCode.corsoAdmin;

  const currentStore = storeUser.store.name;

  if (!show) return null;

  return (
    <Banner variant="danger" data-testid="user-warning-app-banner" dismissible>
      <p>
        Logged in as <strong className="font-semibold">{user.email}</strong> on{' '}
        <strong className="font-semibold">{currentStore}</strong>
      </p>
    </Banner>
  );
}

function AppUninstalledBanner() {
  const {
    storeUser: {
      store: { isCrewInstalled },
    },
  } = useMerchantContext();
  if (isCrewInstalled) return null;
  return (
    <Banner variant="danger" data-testid="uninstall-app-banner">
      <p>
        The Corso app has been uninstalled from your store. Reinstall to
        continue using the app.
      </p>
    </Banner>
  );
}

/** Applies for any invoice definition, but is most prominent for free trials. */
export const EXPIRATION_WARNING_DAYS = 14;

function AppExpiringBanner() {
  const {
    storeUser: {
      store: { invoiceDefinition },
    },
  } = useMerchantContext();

  if (!invoiceDefinition) return null;

  const { endDate } = invoiceDefinition;

  const daysUntilExpiration = differenceInDays(new Date(endDate), new Date());

  if (daysUntilExpiration > EXPIRATION_WARNING_DAYS) return null;

  const days = daysUntilExpiration === 1 ? 'day' : 'days';

  return (
    <Banner variant="info" data-testid="subscription-expiring-banner">
      <p>
        You have <strong className="font-bold">{daysUntilExpiration}</strong>{' '}
        {days}
        {` `}
        remaining.{` `}
        <a
          className="underline hover:underline"
          href="https://corso.com/book-a-time"
          target="_blank"
          rel="noreferrer"
        >
          Contact Us
        </a>{' '}
        to continue using the Corso App, free forever.
      </p>
    </Banner>
  );
}

const sidebarActionVariants = cva(
  'flex items-center gap-x-3 rounded-md p-2 text-xs font-semibold',
  {
    variants: {
      active: {
        [`${false}`]: 'text-neutral-900 hover:bg-neutral-100',
        [`${true}`]: 'bg-neutral-50 text-corso-blue-700',
      },
      kind: {
        button: 'w-full', // special for the button so it fills the width like the other links
      },
    },
    defaultVariants: {
      active: `${false}`,
    },
  },
);

function SidebarIcon({
  active = false,
  icon,
  activeIcon,
}: {
  active?: boolean;
  icon: IconDefinition;
  activeIcon?: IconDefinition;
}) {
  return (
    <FontAwesomeIcon
      icon={active && activeIcon ? activeIcon : icon}
      className="size-5 shrink-0 lg:size-4 lg:group-data-[collapsed=true]/sidebar:size-5"
    />
  );
}

function SidebarAction({
  name,
  onClick,
  icon,
  activeIcon,
  active = false,
}: {
  name: ReactNode;
  onClick?: () => void;
  icon: IconDefinition;
  activeIcon?: IconDefinition;
  active?: boolean;
}) {
  return (
    <>
      <div className="hidden lg:group-data-[collapsed=true]/sidebar:block">
        {/* using `asChild`, so there isn't a button immediately within a button */}
        <SimpleTooltip content={name} side="right" asChild>
          <button
            type="button"
            onClick={onClick}
            className={sidebarActionVariants({
              active: `${active}`,
              kind: 'button',
            })}
          >
            <SidebarIcon active={active} icon={icon} activeIcon={activeIcon} />
            <span className="sr-only">{name}</span>
          </button>
        </SimpleTooltip>
      </div>
      <div className="lg:group-data-[collapsed=true]/sidebar:hidden">
        <button
          type="button"
          onClick={onClick}
          className={sidebarActionVariants({
            active: `${active}`,
            kind: 'button',
          })}
        >
          <SidebarIcon active={active} icon={icon} activeIcon={activeIcon} />
          <div className="truncate">{name}</div>
        </button>
      </div>
    </>
  );
}

function SidebarLink({
  name,
  to,
  icon,
  activeIcon,
  onClick,
}: {
  name: ReactNode;
  to: To;
  icon: IconDefinition;
  activeIcon?: IconDefinition;
  onClick?: () => void;
}) {
  const isExternal = typeof to === 'string' && /^https?/.test(to);

  return (
    <>
      <div className="hidden lg:group-data-[collapsed=true]/sidebar:block">
        <SimpleTooltip content={name} side="right">
          {/* using `NavLink`, but if it doesn't match any in-app navigation it'll link to the relevant page anyway */}
          <NavLink
            to={to}
            target={isExternal ? '_blank' : undefined}
            className={({ isActive }) =>
              sidebarActionVariants({ active: `${isActive}` })
            }
          >
            {({ isActive }) => (
              <>
                <SidebarIcon
                  active={isActive}
                  icon={icon}
                  activeIcon={activeIcon}
                />
                <span className="sr-only">{name}</span>
              </>
            )}
          </NavLink>
        </SimpleTooltip>
      </div>
      <div className="lg:group-data-[collapsed=true]/sidebar:hidden">
        <NavLink
          to={to}
          target={isExternal ? '_blank' : undefined}
          className={({ isActive }) =>
            sidebarActionVariants({ active: `${isActive}`, className: 'group' })
          }
          onClick={onClick}
        >
          {({ isActive }) => (
            <>
              <SidebarIcon
                active={isActive}
                icon={icon}
                activeIcon={activeIcon}
              />
              <div className="truncate">{name}</div>
            </>
          )}
        </NavLink>
      </div>
    </>
  );
}

type SidebarLinkGroup = {
  name?: ReactNode;
  links: (ComponentProps<typeof SidebarLink> & { hidden?: boolean })[];
  hidden?: boolean;
};

function SidebarLinkGroups({
  linkGroups,
  onLinkClick,
}: {
  linkGroups: SidebarLinkGroup[];
  onLinkClick?: () => void;
}) {
  return (
    <ul className="flex flex-1 flex-col gap-y-6 lg:group-data-[collapsed=true]/sidebar:gap-2">
      {linkGroups.map((group) => (
        <li key={crypto.randomUUID()} hidden={group.hidden}>
          {group.name && (
            <div className="text-xs font-medium leading-normal text-neutral-700 lg:group-data-[collapsed=true]/sidebar:sr-only">
              {group.name}
            </div>
          )}
          <ul className="space-y-1">
            {group.links.map((link) => (
              <li
                key={
                  // ? might need to revisit this key
                  typeof link.to === 'string' ?
                    link.to
                  : `${link.to.pathname ?? ''}${link.to.hash ?? ''}${link.to.search ?? ''}`
                }
                hidden={link.hidden}
              >
                <SidebarLink
                  name={link.name}
                  to={link.to}
                  icon={link.icon}
                  activeIcon={link.activeIcon}
                  onClick={onLinkClick}
                />
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}

export default function AppLayout() {
  const location = useLocation();

  const {
    isReturnsEnabled,
    isWarrantyEnabled,
    isShippingProtectionEnabled,
    isRegistrationEnabled,
  } = useEnabledClaimType();

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const closeMobileSidebar = useCallback(
    () => setIsMobileSidebarOpen(false),
    [],
  );

  // TODO consider a way to get all stored values and reset any that are no longer needed
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    'sidebar-collapsed',
    false,
  );

  const isAdmin = useIsAdmin();

  const primaryLinkGroups = useMemo<SidebarLinkGroup[]>(
    () =>
      [
        {
          links: [
            {
              name: 'Order Lookup',
              hidden:
                !isReturnsEnabled &&
                !isWarrantyEnabled &&
                !isShippingProtectionEnabled,
              to: 'orders/lookup',
              icon: faMagnifyingGlassLight,
              activeIcon: faMagnifyingGlassSolid,
            },
            {
              name: crewClaimTypeEnumPluralizedName[CrewClaimTypeEnum.return],
              hidden: !isReturnsEnabled,
              to: `claims/${CrewClaimTypeEnum.return.toLowerCase()}`,
              icon: faExchangeLight,
              activeIcon: faExchangeSolid,
            },
            {
              name: crewClaimTypeEnumPluralizedName[CrewClaimTypeEnum.warranty],
              hidden: !isWarrantyEnabled,
              to: `claims/${CrewClaimTypeEnum.warranty.toLowerCase()}`,
              icon: faBadgeCheckLight,
              activeIcon: faBadgeCheckSolid,
            },
            {
              name: 'Shipping',
              hidden: !isShippingProtectionEnabled,
              to: 'claims/shipping',
              icon: faBoxCheckLight,
              activeIcon: faBoxCheckSolid,
            },
            {
              name: 'Registrations',
              hidden: !isRegistrationEnabled,
              to: 'registrations',
              icon: faAddressBookLight,
              activeIcon: faAddressBookSolid,
            },
          ],
        },
        {
          name: 'Analytics',
          hidden: !isAdmin,
          links: [
            {
              name: 'Dashboards',
              to: 'analytics/dashboards',
              icon: faChartPieSimpleLight,
              activeIcon: faChartPieSimpleSolid,
            },
            {
              name: 'Corso AI',
              to: 'analytics/ai',
              icon: faSparklesLight,
              activeIcon: faSparklesSolid,
            },
          ],
        },
      ] satisfies SidebarLinkGroup[],
    [
      isRegistrationEnabled,
      isReturnsEnabled,
      isShippingProtectionEnabled,
      isWarrantyEnabled,
      isAdmin,
    ],
  );

  const {
    storeUser: { store },
  } = useMerchantContext();

  const isSubscriptionMissingOrExpired =
    !store.invoiceDefinition ||
    new Date(store.invoiceDefinition.endDate) < new Date();

  return (
    <LoadingProvider>
      <div
        className="relative grid h-dvh grid-cols-1 grid-rows-[auto_1fr] overflow-hidden bg-neutral-900 lg:grid-cols-[var(--sidebar-col)_1fr]"
        style={{
          '--sidebar-col': isCollapsed ? 'auto' : 'minmax(auto, 15rem)',
        }}
      >
        <header className="col-span-full">
          <UserWarningAppBanner />
          <AppUninstalledBanner />
          <AppExpiringBanner />
          <div className="relative grid grid-cols-[auto_1fr_auto] grid-rows-1 place-items-center gap-4 bg-neutral-900 px-4 py-2">
            <LoadingBar className="absolute left-0 right-0 top-0" />
            <button
              type="button"
              onClick={() => setIsMobileSidebarOpen((open) => !open)}
              className={cn(
                'flex size-8 items-center justify-center rounded-full p-1 focus-within:bg-neutral-700 hover:bg-neutral-700 lg:hidden',
                isMobileSidebarOpen && 'bg-neutral-700',
              )}
            >
              <span className="sr-only">
                {isMobileSidebarOpen ? 'Close' : 'Open'} Menu
              </span>
              <FontAwesomeIcon
                icon={isMobileSidebarOpen ? faXmarkLarge : faBars}
                className="text-white"
              />
            </button>
            <div className="hidden text-white lg:block">
              <CorsoLogo />
            </div>
            <QuickSearch />
            <nav className="flex items-center gap-2">
              {isAdmin && <SettingsChecklist />}
              <StoreUser />
            </nav>
          </div>
        </header>
        <aside
          className={cn(
            'group/sidebar hidden flex-col overflow-auto bg-neutral-200 lg:flex lg:rounded-tl-lg',
            isMobileSidebarOpen && 'flex',
          )}
          data-collapsed={isCollapsed}
        >
          <nav className="flex flex-1 flex-col gap-1 overflow-y-auto p-2">
            <SidebarLinkGroups
              linkGroups={primaryLinkGroups}
              onLinkClick={closeMobileSidebar}
            />
            <ul className="mt-auto space-y-1">
              <li hidden={!isAdmin}>
                <SidebarLink
                  name="Settings"
                  to={{
                    pathname: 'settings',
                    search: new URLSearchParams({
                      [queryParamKey.returnTo]: `${location.pathname}${location.search}`,
                      [queryParamKey.settingsNavigation]: 'true',
                    }).toString(),
                  }}
                  icon={faGearLight}
                  activeIcon={faGearSolid}
                />
              </li>
              <li>
                <SidebarLink
                  name="Help"
                  to="https://help.corso.com"
                  icon={faQuestionCircle}
                  activeIcon={faQuestionCircle}
                  onClick={closeMobileSidebar}
                />
              </li>
              <li className="hidden pb-2 lg:list-item">
                <div hidden={!isCollapsed}>
                  <SidebarAction
                    name="Expand"
                    onClick={() => setIsCollapsed(false)}
                    icon={faChevronRight}
                  />
                </div>
                <div hidden={isCollapsed}>
                  <SidebarAction
                    name="Collapse"
                    onClick={() => setIsCollapsed(true)}
                    icon={faChevronLeft}
                  />
                </div>
              </li>
            </ul>
          </nav>
        </aside>

        <div
          className={cn(
            'hidden overflow-auto bg-neutral-100 p-4 lg:block lg:rounded-tr-lg lg:px-12',
            !isMobileSidebarOpen && 'block',
          )}
        >
          {isSubscriptionMissingOrExpired ?
            <SubscriptionExpired />
          : <>
              <Outlet />
              <Toaster />
            </>
          }
        </div>
      </div>
    </LoadingProvider>
  );
}
