import { forwardRef, Ref } from 'react';
import Input, { InputTypeProps } from './Input';

const NumberInput = forwardRef(
  ({ ...rest }: InputTypeProps<number>, ref: Ref<HTMLInputElement>) => (
    <Input type="number" ref={ref} {...rest} />
  ),
);

export default NumberInput;
