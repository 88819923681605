import * as Sentry from '@sentry/react';

const {
  VITE_NODE_ENV: environment,
  VITE_SENTRY_DSN: dsn,
  VITE_BUILD_SHA: release,
} = import.meta.env;

window.sessionStorage.setItem('appBuildSha', release ?? '');

if (environment === 'prod') {
  Sentry.init({
    environment,
    dsn,
    normalizeDepth: Infinity,
    attachStacktrace: true,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        minReplayDuration: 15000,
        networkDetailAllowUrls: [
          window.location.origin,
          'api.corso.com',
          'api.stg.corso.com',
        ],
        networkCaptureBodies: true,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    tracePropagationTargets: [/^https:\/\/api\.corso\.com/],
    release,
    // sentry recommends adjusting this value in production for sampling transactions for performance monitoring
    tracesSampleRate: 0.2,

    // replay integration configuration
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
  });
}
