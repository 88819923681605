/**
 * This file is configured to link to shadcn/ui as defined in `components.json`.
 * This helps when adding new components via the CLI.
 * @see https://ui.shadcn.com/docs/cli
 */
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
