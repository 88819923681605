import LineItem from '~/components/LineItem';
import LineItemList, { LineItemListLineItem } from '~/components/LineItemList';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';

export default function WarrantyReviewLineItem({
  lineItems,
}: {
  lineItems: LineItemListLineItem[];
}) {
  return (
    <ResolutionLineItemTitle title="In Review">
      <LineItem variant="no-image">
        <LineItemList lineItems={lineItems} />
      </LineItem>
    </ResolutionLineItemTitle>
  );
}
