import { isNonNullable } from 'corso-types';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';
import ProductImage from './ProductImage';

// TODO account to discounts // TODO create utility to easily default nullish values in a type safe way; similar to `??` but for all nullish properties
/**
 * A function that takes the `unitPrice`, `unitTax`, and `quantity` into account to calculate a total price.
 * Values are expected to all be in the same currency, and the result will then be in that same currency.
 */
// ? possibly extract as utility
export function totalPrice({
  unitPrice,
  unitTax = 0,
  quantity = 1,
}: {
  /** The cost or value of a single item of a given product. */
  unitPrice: number;
  /**
   * The taxes charged for a single item of a given product.
   * When omitted or nullish, defaults to `0`.
   */
  unitTax?: number | null;
  /**
   * The number of items of a given product to calculate into the total price.
   * When omitted or nullish, defaults to `1`.
   */
  quantity?: number | null;
}) {
  const returnPrice = (unitPrice + (unitTax ?? 0)) * (quantity ?? 1);
  return Number(returnPrice.toFixed(2));
}

// TODO alternative/better name of component or this type
export type LineItemProps = PropsWithChildren<{
  // ? might want to consider `productName` and an optional `variantName` instead, or maybe even allow this to be a `ReactNode` for more flexibility
  /** When nullish, omits the `name` line. */
  name?: string | null;

  options?: Record<string, string> | { value: string }[];
  /** When nullish, the fallback image will still show instead. */
  imageUrl?: string | null;
  variant?: 'DEFAULT' | 'small' | 'no-image';
  /**
   * When nullish, it's not displayed, but semantically means one.
   */
  quantity?: number | null;
  /**
   * When nullish, it's not displayed; otherwise, shows a value next to the price that's gray with a slash in it.
   * Only displays if different than the `price`.
   */
  compareUnitPrice?: number | null;
  /** When nullish, it's not displayed, and semantically means either it's unknown or just not provided. */
  // ? maybe this should be named `value` or `amount` instead, to better semantically match when it's used for gift cards or refunds; maybe even make the props a union
  unitPrice?: number | null;

  // ? maybe re-add `compareUnitTax` and add `compareQuantity`; maybe these just become `lineItem` and `compareLineItem` props instead
  unitTax?: number | null;

  sku?: string | null;

  /**
   * Content that's displayed in a secondary line just below the `name`.
   * While `children` have the full width, they may be in a separate visual row, depending on monetary information, such as `unitTax` being provided, this will be below the `name`.
   */
  subtitle?: ReactNode;
}>;

const optionsSummary = (options: LineItemProps['options']) => {
  if (!options) {
    return '';
  }

  const values =
    Array.isArray(options) ?
      options.map(({ value }) => value)
    : Object.values(options);

  return values.filter((t) => t !== 'Default Title').join(' / ');
};

// ? refine line item to support strictly `price` and `comparePrice` or `unitPrice` and `compareUnitPrice`; without `unitPrice`, `quantity` wouldn't be allowed and would only show prices
export default function LineItem({
  name,
  imageUrl,
  quantity,
  compareUnitPrice,
  sku,
  unitPrice,
  variant = 'DEFAULT',
  unitTax,
  subtitle,
  children,
  options,
}: LineItemProps) {
  // TODO find an alternative to this; maybe something that's purely CSS based
  // line item has something that would be in the primary lines of content; requiring whitespace between the content and any children
  const hasContent = useMemo(
    () => [name, subtitle, unitPrice].some(isNonNullable),
    [name, subtitle, unitPrice],
  );
  const {
    storeUser: {
      store: { currencyCode },
    },
  } = useMerchantContext();

  const optSummary = optionsSummary(options);
  return (
    <article className="flex gap-x-3">
      {variant !== 'no-image' && (
        <div className="relative">
          <ProductImage
            src={imageUrl}
            alt={name ?? 'Unknown/Missing Name'}
            className={variant === 'small' ? 'size-8' : 'size-14'}
          />
          {isNonNullable(quantity) && (
            <span
              className={twMerge(
                variant === 'small' ?
                  'h-4 w-4 text-[0.65rem]'
                : 'h-5 w-5 text-xs',
                'absolute -right-0 -top-0 flex min-w-fit -translate-y-1/3 translate-x-1/3 items-center justify-center rounded-full border border-corso-gray-300 bg-white p-1 text-corso-gray-800',
              )}
              title={formatter.count(quantity)}
            >
              {formatter.count(quantity, true)}
            </span>
          )}
        </div>
      )}
      <section className="flex-auto">
        {hasContent && (
          <div className="flex justify-between gap-2">
            <div className="flex flex-col gap-1 font-medium text-corso-gray-800">
              {name && (
                <p
                  className={twMerge(
                    variant === 'small' ? 'text-xs' : 'text-sm',
                  )}
                >
                  {name}
                </p>
              )}
              {/* if the options summary and name are the same, don't show the options summary */}
              {options && optSummary !== name && (
                <p className="text-xs text-corso-gray-500"> {optSummary}</p>
              )}

              {sku && (
                <p className="text-xs text-corso-gray-500"> SKU: {sku}</p>
              )}

              {subtitle}
            </div>
            {isNonNullable(unitPrice) && (
              <div className="flex flex-col gap-1 justify-self-end text-right text-sm">
                <p
                  className={twMerge(
                    'inline-block',
                    isNonNullable(compareUnitPrice) && 'flex gap-1', // for some reason the gap is visible even when there's only one child element
                  )}
                >
                  {isNonNullable(compareUnitPrice) &&
                    compareUnitPrice !== unitPrice && (
                      <span className="text-corso-gray-500 line-through">
                        {formatter.currency(compareUnitPrice, currencyCode)}
                      </span>
                    )}
                  {/* // TODO find a better place or way to show the total on mobile */}
                  <span
                    className="flex gap-1 font-medium text-corso-gray-800"
                    title={formatter.currency(
                      totalPrice({ unitPrice, unitTax, quantity }),
                      currencyCode,
                    )}
                  >
                    <span>{formatter.currency(unitPrice, currencyCode)}</span>
                    {isNonNullable(quantity) && (
                      <>
                        <span>&times;</span>
                        <span>{formatter.count(quantity)}</span>
                      </>
                    )}
                  </span>
                </p>
                {isNonNullable(unitTax) && (
                  <p className="flex items-baseline justify-end gap-1 text-xs text-corso-gray-500">
                    <span>Tax</span>{' '}
                    {formatter.currency(
                      unitTax * (quantity ?? 1),
                      currencyCode,
                    )}
                  </p>
                )}
              </div>
            )}
          </div>
        )}
        {children && (
          <div className={twMerge(hasContent && 'mt-3')}>{children}</div>
        )}
      </section>
    </article>
  );
}
