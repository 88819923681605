import {
  json,
  LoaderFunction,
  redirect,
  useLoaderData,
} from 'react-router-dom';
import api from '~/api';
import { queryClient } from '~/providers/QueryProvider';
import { ACCESS_CODE_KEY } from '~/types';

type UserData = Awaited<ReturnType<typeof api.user.getWithAccessCode>>;
export type LoaderData = UserData & {
  accessCode: { key: string; value: string };
};

const UserWelcomeLoader = (async ({ request }) => {
  const url = new URL(request.url);
  const accessCode = url.searchParams.get(ACCESS_CODE_KEY);

  if (!accessCode) {
    return redirect('/');
  }

  const queryKey = [ACCESS_CODE_KEY, accessCode];

  try {
    const state = queryClient.getQueryState(queryKey);
    const user =
      state && !state.isInvalidated ?
        queryClient.getQueryData<UserData>(queryKey)
      : await api.user.getWithAccessCode(accessCode);

    queryClient.setQueryData(queryKey, user);

    // no users to choose so go directly to sign up
    if (!user || user.userEmails.length === 0) {
      return redirect(`/sign-up?${ACCESS_CODE_KEY}=${accessCode}`);
    }

    return json<LoaderData>({
      ...user,
      accessCode: { key: ACCESS_CODE_KEY, value: accessCode },
    });
  } catch (e) {
    return redirect('/');
  }
}) satisfies LoaderFunction;

export default UserWelcomeLoader;

export const useUserWelcomeLoaderData = () => useLoaderData() as LoaderData;
