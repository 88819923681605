import { TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import ConfirmModal from '~/components/ConfirmModal';
import IconAction from '~/components/IconAction';
import { useStoreRuleDelete } from '~/hooks/useStoreRules';
import { StoreRule } from '~/types';

export default function StoreRuleDelete({
  storeRule,
}: {
  storeRule: StoreRule;
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const { mutate: storeRuleDelete } = useStoreRuleDelete();

  return (
    <>
      <ConfirmModal
        show={showConfirm}
        title="Delete Rule"
        prompt={`Are you sure you want to delete "${storeRule.name}"?`}
        cancelText="Cancel"
        confirmText="Delete"
        onCancel={() => setShowConfirm(false)}
        onConfirm={() => {
          storeRuleDelete(storeRule.id);
          setShowConfirm(false);
        }}
      />
      <IconAction.Button
        title={`Delete ${storeRule.name} Rule`}
        icon={TrashIcon}
        onClick={() => setShowConfirm(true)}
      />
    </>
  );
}
