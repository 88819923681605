import { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

type Pixel = `${number}px`;
type Percentage = `${number}%`;
type Rem = `${number}rem`;
type Em = `${number}em`;
type CSSValue = Pixel | Percentage | Em | Rem;

const skeletonStyles = 'animate-pulse bg-corso-gray-200';

function Circle({
  diameter = '40px',
  className,
}: {
  diameter?: CSSValue;
  className?: string;
}) {
  return (
    <div
      className={twMerge('rounded-full', skeletonStyles, className)}
      style={{ width: diameter, height: diameter }}
    />
  );
}

function Rectangle({
  height,
  width,
  className,
}: {
  height: CSSValue;
  width: CSSValue;
  className?: string;
}) {
  return (
    <div
      className={twMerge('rounded-md', skeletonStyles, className)}
      style={{ height, width }}
    />
  );
}

const randomWidth = (min = 70, max = 100): Percentage =>
  `${Math.floor(Math.random() * (max - min) + min)}%`;

function* keyGeneration() {
  let gen = 0;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- always want to generate keys
  while (true) {
    gen += 1;
    yield `key-${gen}`;
  }
}

function Text({ lineHeight = '1em' }: { lineHeight?: CSSValue }) {
  return <Rectangle height={lineHeight} width={randomWidth()} />;
}

const keygen = keyGeneration();

function TextBlock({
  lines = 3,
  lineHeight = '1em',
  className,
}: {
  lines?: number;
  lineHeight?: CSSValue;
  className?: string;
}) {
  const paragraph = useMemo(
    () =>
      Array.from({ length: lines }).map(() => (
        <Text key={String(keygen.next().value)} lineHeight={lineHeight} />
      )),
    [lines, lineHeight],
  );
  return (
    <div className={twMerge('flex flex-col gap-1', className)}>{paragraph}</div>
  );
}

function Skeleton({
  children = null, // ReactNode can be undefined`
  skeleton: Component,
  instances = 1,
  isLoading = false,
}: PropsWithChildren<{
  skeleton: FunctionComponent;
  instances?: number;
  isLoading?: boolean;
}>) {
  const skeletons = useMemo(
    () =>
      Array.from({ length: instances }).map(() => (
        <Component key={String(keygen.next().value)} />
      )),
    [instances, Component],
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment -- to be used as a JSX element it needs to return a JSX element, but children could be a string etc.
  return <>{isLoading ? skeletons : children}</>;
}

Skeleton.Circle = Circle;
Skeleton.Rectangle = Rectangle;
Skeleton.Text = Text;
Skeleton.TextBlock = TextBlock;

export default Skeleton;
