import toast from 'react-hot-toast';
import Snackbar from '~/components/Snackbar';

export const useToast = () => {
  const show = (message: string, id?: string) =>
    toast.custom(<Snackbar message={message} />, {
      id,
      duration: 3000,
      position: 'bottom-center',
    });

  const remove = (id?: string) => toast.remove(id);

  const dismiss = (id?: string) => toast.dismiss(id);

  return {
    show,
    remove,
    dismiss,
  };
};
