import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { cn } from '~/utils/shadcn';
import { buttonVariants } from './Button';

function IconLeft() {
  return <ChevronLeftIcon className="h-4 w-4" />;
}

function IconRight() {
  return <ChevronRightIcon className="h-4 w-4" />;
}

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('group/calendar p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }), // TODO iterate to remove
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-neutral-500 rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'group/cell relative flex h-9 w-9 items-center justify-center p-0 text-sm focus-within:relative focus-within:z-20',
        day: cn(
          'h-9 w-9 rounded-none p-0 font-normal hover:[&:not([aria-selected])]:rounded-md hover:[&:not([aria-selected])]:bg-neutral-100 hover:[&:not([aria-selected])]:text-neutral-900 hover:[&:not([aria-selected])]:opacity-100',
          'aria-selected:bg-neutral-950 aria-selected:text-neutral-50 aria-selected:opacity-100 aria-selected:hover:bg-neutral-950 hover:aria-selected:text-neutral-50', // all selected (base)
          'aria-selected:[&:where(.day-range-middle)]:bg-neutral-800', // middle of range
          'aria-selected:[&:where(.day-range-start)]:rounded-l-md', // start of range
          'aria-selected:[&:where(.day-range-end)]:rounded-r-md', // end of range
          'group-first/cell:group-has-[[aria-selected]]/cell:rounded-l-md group-last/cell:group-has-[[aria-selected]]/cell:rounded-r-md', // first and last of row within range
        ),
        day_selected:
          'day-selected group-[&:not(:has(.day-range-start,.day-range-end))]/calendar:rounded-md',
        day_range_start: 'day-range-start',
        day_range_middle: 'day-range-middle',
        day_range_end: 'day-range-end',
        day_today:
          '[&:not([aria-selected])]:bg-neutral-100 [&:not([aria-selected])]:text-neutral-900 [&:not([aria-selected])]:rounded-md',
        day_outside:
          'day-outside text-neutral-500 opacity-50 aria-selected:bg-neutral-800 aria-selected:text-neutral-50/50 aria-selected:opacity-50',
        day_disabled:
          'text-neutral-500 opacity-50 hover:bg-transparent hover:text-neutral-500 hover:opacity-50', // disabled resets hover states
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft,
        IconRight,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
