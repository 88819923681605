import { CheckCircleIcon } from '@heroicons/react/20/solid';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { CorsoClaimType, corsoClaimTypeName } from 'corso-types';
import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import Alert from '~/components/Alert';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';

const corsoClaimTypeDescription = {
  [CorsoClaimType.return]: 'Refunds, exchanges, and store credit.',
  [CorsoClaimType.warranty]: 'Product defects, malfunctions, etc.',
  [CorsoClaimType.shippingProtection]:
    'Shipping issues, carrier damage, lost packages, etc.',
} satisfies Record<CorsoClaimType, string>;

export default function ClaimTypeSelection({
  onClaimTypeSelect: onValueChange,
}: {
  onClaimTypeSelect: (claimType: CorsoClaimType) => void;
}) {
  const { isWarrantyEnabled, isReturnsEnabled, isShippingProtectionEnabled } =
    useEnabledClaimType();

  const claimTypes = Object.values(CorsoClaimType).map((claimType) => ({
    claimType,
    enabled:
      // eslint-disable-next-line no-nested-ternary
      claimType === CorsoClaimType.warranty ? isWarrantyEnabled
      : claimType === CorsoClaimType.return ? isReturnsEnabled
      : isShippingProtectionEnabled,
  }));

  // if there's only one claim type available, select it automatically
  useEffect(() => {
    const enabledClaimTypes = claimTypes.filter(({ enabled }) => enabled);
    const [firstClaimType, ...additionalClaimTypes] = enabledClaimTypes;

    if (additionalClaimTypes.length) return; // more than one enabled
    if (!firstClaimType) return; // means we didn't have any enabled

    onValueChange(firstClaimType.claimType);
  }, [claimTypes, onValueChange]);

  if (!claimTypes.length) {
    return <Alert variant="DEFAULT" message="No Claim Types Available" />;
  }

  return (
    <fieldset className="space-y-3">
      <legend className="text-sm font-semibold leading-6 text-corso-gray-900">
        What type of claim would you like to create?
      </legend>
      <RadioGroup.Root
        onValueChange={onValueChange} // not totally sure why TypeScript is alright with this, because string !== CorsoClaimType
        className="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
      >
        {claimTypes.map(
          ({ claimType, enabled }) =>
            enabled && (
              <RadioGroup.Item
                key={claimType}
                value={claimType}
                disabled={!enabled}
                className="relative flex cursor-pointer rounded-lg border bg-white p-4 text-left shadow-sm hover:border-gray-300 focus:border-gray-300 focus:outline-none data-[disabled]:cursor-not-allowed data-[state=checked]:border-corso-blue-600 data-[disabled]:bg-corso-gray-50 data-[disabled]:opacity-80 data-[state=checked]:ring-2 data-[state=checked]:ring-corso-blue-600"
              >
                <div className="space-y-1">
                  <p className="text-sm font-medium text-corso-gray-900">
                    {corsoClaimTypeName[claimType]}
                  </p>
                  <p className="text-sm text-corso-gray-500">
                    {corsoClaimTypeDescription[claimType]}
                  </p>
                </div>
                <RadioGroup.Indicator>
                  <span className="sr-only">Selected</span>
                  <CheckCircleIcon
                    className={twMerge(
                      'absolute right-2 top-2 size-5 text-corso-blue-600',
                    )}
                    aria-hidden="true"
                  />
                </RadioGroup.Indicator>
              </RadioGroup.Item>
            ),
        )}
      </RadioGroup.Root>
    </fieldset>
  );
}
