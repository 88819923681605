import { PropsWithChildren } from 'react';
import { useDocumentTitle } from 'usehooks-ts';
import CorsoIcon from './CorsoIcon';

export default function FullPageStatus({
  title,
  details,
  children,
}: PropsWithChildren<{ title: string; details: string }>) {
  useDocumentTitle(`Corso · ${title}`, {
    preserveTitleOnUnmount: false,
  });

  return (
    <main className="grid min-h-full grid-rows-[auto,_1fr] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-corso-gray-800">
      <div className="text-corso-blue-600 dark:text-white">
        <CorsoIcon size="lg" />
      </div>
      <div className="max-w-prose space-y-12 text-center">
        <h1 className="text-3xl font-medium tracking-tight text-corso-gray-800 sm:text-5xl dark:text-corso-gray-100">
          {title}
        </h1>
        <p className="text-base leading-7 text-corso-gray-600 dark:text-corso-gray-500">
          {details}
        </p>
        {children}
      </div>
    </main>
  );
}
