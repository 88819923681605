// * CDN Asset URLs

// ? maybe use a data URL of an SVG for these instead to guarantee it's always available
export const IMAGE_NOT_AVAILABLE =
  'https://cdn.corso.com/img/image-not-available.jpeg'; // used as a fallback for missing/invalid images
export const GIFT_CARD =
  'https://cdn.corso.com/img/crew-gift-cards-edge-sq.png';
export const CREDIT_CARD = 'https://cdn.corso.com/img/crew-refunds-edge-sq.png'; // use for refunds
export const PACKAGE_EXCHANGE =
  'https://cdn.corso.com/img/crew-exchange-edge-sq.png'; // use for aggregate variant exchanges
