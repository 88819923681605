import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { z } from 'zod';
import PageStatus from '~/components/PageStatus';
import { Button } from '~/components/ui/primitives/Button';
import { usePathParams } from '~/hooks/usePathParams';

export default function NotFound() {
  // this cannot use the relevant hook, since it exists at a top level outside the provider
  const { storeId } = usePathParams(
    z.object({ storeId: z.string().optional() }),
  );
  return (
    <PageStatus
      title="Page Not Found"
      detail="Sorry, we couldn't find the page you're looking for."
      icon={faQuestionCircle}
    >
      <Button asChild className="w-full">
        <NavLink to={storeId ? `/${storeId}` : '/'} replace reloadDocument>
          Go to Home
        </NavLink>
      </Button>
    </PageStatus>
  );
}
