import { PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CrewClaimReasonCategoryCode,
  crewClaimReasonCategoryCodeName,
} from 'corso-types';
import { ComponentType, FormEventHandler, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import useClaimReasonCategories from '~/hooks/useClaimReasonCategories';
import {
  ClaimReason,
  ClaimReasonCreate,
  claimReasonCreate,
  ClaimReasonUpdate,
} from '~/types';
import Button from './Button';
import Disclosure, { SimpleSummary } from './Disclosure';
import { TextInput } from './field';
import IconAction from './IconAction';
import { Badge } from './ui/primitives/Badge';
import SimpleSelect from './ui/SimpleSelect';

type ClaimReasonDetailCreate = ClaimReasonUpdate['claimReasonDetails'][number];
type ClaimReasonDetail = ClaimReason['claimReasonDetails'][number];

function ReasonCreate({
  onCreate,
  onCancel,
}: {
  onCreate: (reason: ClaimReasonCreate) => void;
  onCancel: () => void;
}) {
  const categories = useClaimReasonCategories();
  const { control, register, handleSubmit } = useForm<ClaimReasonCreate>({
    resolver: zodResolver(claimReasonCreate),
    defaultValues: {
      name: '',
      category: {
        code: CrewClaimReasonCategoryCode.notAsExpected,
        name: crewClaimReasonCategoryCodeName[
          CrewClaimReasonCategoryCode.notAsExpected
        ],
      },
      claimReasonDetails: [],
    },
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => onCreate(values))(event).catch(console.error);
  };

  return (
    <form
      className="flex flex-col flex-wrap gap-2 md:flex-row md:items-center"
      onSubmit={submitHandler}
    >
      <TextInput
        className="flex-1"
        id="claim-reason-name"
        label="Claim Reason Name"
        placeholder="Reason" // ? possibly address if not visually clear enough
        labelVisuallyHidden
        required
        {...register('name')}
      />
      <Controller
        control={control}
        name="category"
        render={({ field: { value, onChange } }) => (
          <SimpleSelect
            label="Category"
            labelVisuallyHidden
            // TODO consider allowing any type for the `value`, may require memoization of options
            options={categories.map((category) => ({
              value: category.code,
              label: category.name,
            }))}
            value={value.code}
            onChange={(code) =>
              onChange(categories.find((c) => c.code === code))
            }
          />
        )}
      />
      <div className="grid grid-cols-2 gap-2">
        <IconAction.Button type="submit" icon={PlusIcon} title="Add Reason" />
        <IconAction.Button icon={TrashIcon} title="Cancel" onClick={onCancel} />
      </div>
    </form>
  );
}

function ReasonDetailsCreate({
  onCreate,
  onCancel,
}: {
  onCreate: (reasonDetail: ClaimReasonDetailCreate) => void;
  onCancel: () => void;
}) {
  const { register, handleSubmit, reset } = useForm<ClaimReasonDetailCreate>({
    defaultValues: {
      name: '',
      isDeleted: false,
    },
  });
  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => {
      onCreate(values);
      reset();
    })(event).catch(console.error);
  };
  return (
    <form
      className="flex flex-col gap-2 sm:flex-row sm:items-center"
      onSubmit={submitHandler}
    >
      <TextInput
        className="flex-1"
        id="claim-reason-detail-name"
        label="Claim Reason Detail Name"
        placeholder="Reason Detail" // ? possibly address if not visually clear enough
        labelVisuallyHidden
        required
        {...register('name', { required: true })}
      />
      <div className="grid grid-cols-2 gap-2">
        <IconAction.Button
          iconSize="sm"
          type="submit"
          icon={PlusIcon}
          title="Add Reason"
        />
        <IconAction.Button
          iconSize="sm"
          icon={XMarkIcon}
          title="Cancel"
          onClick={onCancel}
        />
      </div>
    </form>
  );
}

function ReasonDetailsList({
  details,
  onChange,
  editable = false,
  dataTestId,
}: {
  dataTestId?: string;
  details: ClaimReasonDetail[];
  editable?: boolean;
  onChange: (reasonDetail: ClaimReasonDetail | ClaimReasonDetailCreate) => void;
}) {
  const [showForm, setShowForm] = useState(false);

  const removeDetail = (reasonDetail: ClaimReasonDetail) => () =>
    onChange({ ...reasonDetail, isDeleted: true });
  const addDetail = (reasonDetail: ClaimReasonDetailCreate) => {
    onChange(reasonDetail);
    setShowForm(false);
  };

  return (
    <>
      <ul data-testid={dataTestId} className="pb-2">
        {details.map((detail) => (
          <li
            key={detail.id}
            className="rounded px-2 py-1 text-sm odd:bg-corso-gray-100"
          >
            <div className="flex items-center justify-between">
              {detail.name}
              {editable && (
                <IconAction.Button
                  iconSize="sm"
                  title={`Remove Reason Detail: ${detail.name}`}
                  icon={XMarkIcon}
                  onClick={removeDetail(detail)}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
      {editable && (
        <div className="pr-2">
          {
            showForm ?
              <ReasonDetailsCreate
                onCreate={addDetail}
                onCancel={() => setShowForm(false)}
              />
              // ? possibly make a small variant of the classic button to match the icon action button size
            : <Button onClick={() => setShowForm(true)}>
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                Add Reason Detail
              </Button>

          }
        </div>
      )}
    </>
  );
}

type ReasonListProps = {
  dataTestId?: string;
  className?: string;
  reasons?: ClaimReason[];
  Action?: ComponentType<{ reason: ClaimReason }>;
} & (
  | {
      editable?: true;
      onChange: (reason: ClaimReasonCreate | ClaimReasonUpdate) => void;
    }
  | {
      editable?: false;
      onChange?: never;
    }
);

/** Represents a series of reasons and their details in a mutable way. */
export default function ReasonList({
  className,
  reasons = [],
  onChange,
  editable = false,
  Action,
  dataTestId,
}: ReasonListProps) {
  const [showForm, setShowForm] = useState(false);

  const addReason = (reason: ClaimReasonCreate) => {
    onChange?.(reason);
    setShowForm(false);
  };
  const removeReason = (reason: ClaimReason) => () =>
    onChange?.({
      ...reason,
      isDeleted: true,
      claimReasonDetails: reason.claimReasonDetails.map((detail) => ({
        ...detail,
        isDeleted: true,
      })),
    });

  const onReasonDetailChange =
    (reason: ClaimReason) =>
    (reasonDetail: ClaimReasonDetailCreate | ClaimReasonDetail) => {
      if (!reasonDetail.id) {
        // create new reason detail
        onChange?.({
          ...reason,
          claimReasonDetails: [...reason.claimReasonDetails, reasonDetail],
        });
      } else {
        // update existing reason detail
        onChange?.({
          ...reason,
          claimReasonDetails: reason.claimReasonDetails.map((detail) =>
            detail.id === reasonDetail.id ? reasonDetail : detail,
          ),
        });
      }
    };
  return (
    <ul
      data-testid={dataTestId}
      className={twMerge('divide-y rounded border', className)}
    >
      {reasons.map((reason) => (
        <li key={reason.id}>
          <Disclosure
            renderSummary={
              <SimpleSummary>
                <span className="text-sm font-medium">{reason.name}</span>
                <Badge>{reason.category.name}</Badge>
                <div className="ml-auto">
                  {Action && <Action reason={reason} />}
                  {editable && (
                    <IconAction.Button
                      title={`Remove ${reason.name}`}
                      icon={TrashIcon}
                      onClick={removeReason(reason)}
                    />
                  )}
                </div>
              </SimpleSummary>
            }
          >
            <div className="pb-4 pl-4">
              <ReasonDetailsList
                dataTestId={`reason-details-list-${reason.id}`}
                editable={editable}
                details={reason.claimReasonDetails}
                onChange={onReasonDetailChange(reason)}
              />
            </div>
          </Disclosure>
        </li>
      ))}
      {editable && (
        <li className="p-2">
          {showForm ?
            <ReasonCreate
              onCreate={addReason}
              onCancel={() => setShowForm(false)}
            />
          : <Button onClick={() => setShowForm(true)}>
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
              Add Reason
            </Button>
          }
        </li>
      )}
    </ul>
  );
}
