import { NavLink, NavLinkProps } from 'react-router-dom';

/**
 * A `NavLink` that stops the propagation of click events.
 */
export default function NonPropagatingNavLink({
  onClick,
  ...props
}: NavLinkProps) {
  return (
    <NavLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
    />
  );
}
