import '../sentry.config';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { useRegisterSW } from 'virtual:pwa-register/react';
import './main.css';
import { QueryProvider } from './providers/QueryProvider';
import Router from './Router';
import { periodicServiceWorkerUpdates } from './utils/periodicServiceWorkerUpdates';

/** If `VITE_MSW` is set to any truthy value, and we're in development mode, start the mock service worker.. */
async function enableMocking() {
  if (!import.meta.env.DEV) return;
  if (!import.meta.env.VITE_MSW) return;

  const { worker } = await import('./mocks/browser');
  await worker.start();
}

const root = document.getElementById('root');
if (!root) {
  throw new Error('#root Element Missing');
}

/**
 * Small wrapper around routing and other application logic to trigger automatic page reload with the service worker.
 * @see https://vite-pwa-org.netlify.app/guide/auto-update.html
 */
function App() {
  useRegisterSW({
    immediate: true,
    onRegisteredSW: periodicServiceWorkerUpdates,
  });

  return (
    <QueryProvider>
      <Router />
    </QueryProvider>
  );
}

enableMocking()
  .then(() => {
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  })
  .catch((error) => {
    console.error(error);
  });
