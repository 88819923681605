import {
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
} from 'corso-types';
import { Outlet } from 'react-router-dom';
import { z } from 'zod';
import Page from '~/components/Page';
import SearchTable from '~/components/searchTable/SearchTable';
import { usePathParams } from '~/hooks/usePathParams';

const paramsSchema = z.object({
  storeId: z.string(),
  claimType: z
    .string()
    .transform((value) => value.replace(/^./, (s) => s.toUpperCase()))
    .pipe(z.nativeEnum(CrewClaimTypeEnum)),
  claimId: z.string().optional(),
});

export default function ClaimsLayout() {
  const params = usePathParams(paramsSchema);

  // ? Might consider reworking the router to to have index for table and claim review for as a sibling route to the index
  if (params.claimId) return <Outlet />;

  // * temporary using key to force re-render on claimType change so filter definitions are reloaded will be fixed with the control bar refactor
  return (
    <Page
      title={crewClaimTypeEnumPluralizedName[params.claimType]}
      size="fullWidth"
      primaryAction={{
        to: `/${params.storeId}/orders/lookup`,
        content: 'Order Lookup',
      }}
    >
      <SearchTable key={params.claimType} entity={params.claimType} />
    </Page>
  );
}
