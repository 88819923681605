export default function Address({
  name,
  address,
}: {
  name?: string;
  address: {
    line1: string;
    line2?: string | null;
    city: string;
    stateOrProvinceCode?: string | null;
    postalCode: string;
    countryCode: string;
  };
}) {
  return (
    <div className="flex flex-col gap-1 text-xs text-corso-gray-500">
      {name && <p>{name}</p>}
      <p>{address.line1}</p>
      {address.line2 && <p>{address.line2}</p>}
      <p>
        {address.city} {address.stateOrProvinceCode} {address.postalCode}
      </p>
      <p>{address.countryCode}</p>
    </div>
  );
}
