import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Fieldset, TextInput } from '~/components/field';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { type FormValues, shippingAddressSchema } from './claimCreateSchemas';

export default function ShippingAddressFields() {
  const { register, getValues, formState } = useFormContext<FormValues>();
  const defaultValues = getValues('shippingAddress');
  const defaultOpen = useMemo(
    () => !shippingAddressSchema.safeParse(defaultValues).success,
    [defaultValues],
  );

  return (
    <Disclosure
      defaultOpen={defaultOpen}
      renderSummary={() => <SimpleSummary>Shipping Address</SimpleSummary>}
    >
      <Fieldset legend="Shipping Address">
        <div className="space-y-4">
          <TextInput
            id="shipping-address-line1"
            label="Street Address"
            autoComplete="address-line1"
            required
            {...register('shippingAddress.line1')}
            error={formState.errors.shippingAddress?.line1?.message}
          />
          <TextInput
            id="shipping-address-line2"
            label="Street Address Line 2"
            autoComplete="address-line2"
            {...register('shippingAddress.line2')}
            error={formState.errors.shippingAddress?.line2?.message}
          />
          <TextInput
            id="shipping-address-city"
            label="City"
            autoComplete="address-level2"
            required
            {...register('shippingAddress.city')}
            error={formState.errors.shippingAddress?.city?.message}
          />
          <TextInput
            id="shipping-address-state-or-province"
            label="State or Province Code"
            autoComplete="address-level1"
            required
            {...register('shippingAddress.stateOrProvinceCode')}
            error={
              formState.errors.shippingAddress?.stateOrProvinceCode?.message
            }
          />
          <TextInput
            id="shipping-address-postal-code"
            label="Postal Code"
            autoComplete="postal-code"
            required
            {...register('shippingAddress.postalCode')}
            error={formState.errors.shippingAddress?.postalCode?.message}
          />
          <TextInput
            id="shipping-address-country"
            label="Country Code"
            autoComplete="country"
            required
            {...register('shippingAddress.countryCode')}
            error={formState.errors.shippingAddress?.countryCode?.message}
          />
        </div>
      </Fieldset>
    </Disclosure>
  );
}
