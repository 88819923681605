import { PropsWithChildren } from 'react';

export default function Fieldset({
  legend,
  children,
}: PropsWithChildren<{ legend: string }>) {
  return (
    <fieldset className="rounded-md border p-4 pt-2">
      <legend className="rounded-md border px-2 py-1 text-xs">{legend}</legend>
      {children}
    </fieldset>
  );
}
