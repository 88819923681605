import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import {
  ClaimResolutionMethodEnum,
  CorsoClaimType,
  crewClaimTypeEnumName,
  CrewMerchantUi,
} from 'corso-types';
import { useCallback } from 'react';
import { DefaultValues, FormProvider, useForm } from 'react-hook-form';
import { useBeforeUnload, useBlocker, useNavigate } from 'react-router-dom';
import api from '~/api';
import Alert from '~/components/Alert';
import Button, { LinkButton } from '~/components/Button';
import ConfirmModal from '~/components/ConfirmModal';
import { useStoreId } from '~/hooks/useStoreId';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { toLowerCase } from '~/utils/casing';
import {
  FormValues,
  formValuesSchema,
  type CrewClaimFormValues,
  type ShippingProtectionFormValues,
} from './claimCreateSchemas';
import { CrewClaimFields } from './CrewClaimFields';
import CustomerFields from './CustomerInfoFields';
import ShippingAddressFields from './ShippingAddressFields';
import { ShippingProtectionClaimDisplay } from './ShippingProtectionClaimDisplay';
import { ShippingProtectionClaimFields } from './ShippingProtectionClaimFields';

const extractCustomerValues = (order: CrewMerchantUi.CrewOrder) =>
  ({
    firstName: order.shippingAddress?.firstName,
    lastName: order.shippingAddress?.lastName,
    email: order.email,
    phone: order.shippingAddress?.phone,
  }) satisfies DefaultValues<FormValues['customer']>;

const extractShippingAddressValues = (order: CrewMerchantUi.CrewOrder) =>
  ({
    line1: order.shippingAddress?.line1,
    line2: order.shippingAddress?.line2,
    city: order.shippingAddress?.city,
    stateOrProvinceCode: order.shippingAddress?.stateOrProvinceCode,
    postalCode: order.shippingAddress?.postalCode,
    countryCode: order.shippingAddress?.countryCode,
  }) satisfies DefaultValues<FormValues['shippingAddress']>;

const getFormValues = (
  order: CrewMerchantUi.CrewOrder,
  claimType: CorsoClaimType,
): DefaultValues<FormValues> => {
  const customer = extractCustomerValues(order);
  const shippingAddress = extractShippingAddressValues(order);

  if (
    claimType === CorsoClaimType.shippingProtection &&
    !order.shippingClaimReasons[0]
  ) {
    throw new Error('Shipping claim reasons not found in order');
  }

  return claimType === CorsoClaimType.shippingProtection ?
      ({
        claimType,
        customer,
        shippingAddress,
        lineItems: [],
        notifyCustomer: true,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- check shippingClaimReasons[0] above to ensure it's defined
        reason: order.shippingClaimReasons[0]!,
        resolutionMethod: ClaimResolutionMethodEnum.reorder,
      } satisfies DefaultValues<ShippingProtectionFormValues>)
    : ({
        claimType,
        customer,
        shippingAddress,
        claimLineItems: [],
      } satisfies DefaultValues<CrewClaimFormValues>);
};

const crewClaimLineItemFormValuesToClaimCreate =
  (
    order: CrewMerchantUi.CrewOrder,
    claimType: CrewClaimFormValues['claimType'],
  ) =>
  (claimLineItem: CrewClaimFormValues['claimLineItems'][number]) => {
    const lineItem = order.lineItems.find(
      (orderLineItem) =>
        orderLineItem.id === claimLineItem.originalStoreOrderLineItemId,
    );
    if (!lineItem) {
      throw new Error(
        `Line item ${claimLineItem.originalStoreOrderLineItemId} not found in order`,
      );
    }

    const claimReason = lineItem[toLowerCase(claimType)].claimReasons.find(
      ({ id }) => id === claimLineItem.reasonId,
    );
    if (!claimReason) {
      throw new Error(
        `Claim reason ${claimLineItem.reasonId} not found in line item ${lineItem.id}`,
      );
    }

    const claimReasonDetail = claimReason.claimReasonDetails.find(
      ({ id }) => id === claimLineItem.reasonDetailId,
    );
    if (!claimReasonDetail && claimLineItem.reasonDetailId) {
      throw new Error(
        `Claim reason detail ${claimLineItem.reasonDetailId} not found in line item ${lineItem.id}`,
      );
    }

    return {
      originalStoreOrderLineItemId: claimLineItem.originalStoreOrderLineItemId,
      quantity: claimLineItem.quantity,
      comments: null,
      requestedResolutionMethodEnum:
        claimLineItem.requestedResolutionMethodEnum,

      variantExchangeLineItem: claimLineItem.variantExchangeLineItem,
      unitPrice: lineItem.unitPrice,
      unitTax: lineItem.unitTax,
      // ! `amount` unavailable to represent the requested amount of the claim line item, this is only available for modification after the claim is created

      reason: {
        id: claimLineItem.reasonId,
        name: claimReason.name,
        ...(claimLineItem.reasonDetailId &&
          claimReasonDetail && {
            detail: {
              id: claimLineItem.reasonDetailId,
              name: claimReasonDetail.name,
            },
          }),
      },
    };
  };

const crewFormValuesToClaimCreate = ({
  order,
  formValues: { claimType, customer, shippingAddress, claimLineItems },
}: {
  order: CrewMerchantUi.CrewOrder;
  formValues: CrewClaimFormValues;
}) =>
  ({
    source: 'Merchant_App' as const,
    customerEmail: customer.email,
    customerName: `${customer.firstName} ${customer.lastName}`.trim(),

    claimType,
    originalStoreOrderId: order.id,

    shippingAddress: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
      ...shippingAddress,
    },
    // only the Shipping Address is validated on the claim create page
    billingAddress: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      ...shippingAddress,
    },
    giftCardIncentiveAmountApplied: 0,
    exchangeOrderShippingAmountApplied: 0,
    feeApplied: 0,
    returnShippingAmountApplied: 0,
    claimLineItems: claimLineItems.map(
      crewClaimLineItemFormValuesToClaimCreate(order, claimType),
    ),
  }) satisfies CrewMerchantUi.ClaimCreate;

const shippingProtectionFormValuesToClaimCreate = ({
  order,
  formValues: { customer, shippingAddress, lineItems, ...rest },
}: {
  order: CrewMerchantUi.CrewOrder;
  formValues: ShippingProtectionFormValues;
}) =>
  ({
    ...rest,
    shippingAddress: {
      ...shippingAddress,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phone,
    },
    originalStoreOrder: {
      id: order.id,
      lineItems,
    },
  }) satisfies CrewMerchantUi.ShippingClaimCreate;

const useCreateClaim = (
  order: CrewMerchantUi.CrewOrder,
  claimType: CorsoClaimType,
) => {
  const storeId = useStoreId();
  const { userFullName } = useMerchantContext();
  const navigate = useNavigate();
  const storeApi = api.store(storeId);

  return useMutation({
    mutationFn: (formValues: FormValues) =>
      formValues.claimType === CorsoClaimType.shippingProtection ?
        storeApi.shippingProtection.claimCreate(
          shippingProtectionFormValuesToClaimCreate({ order, formValues }),
        )
      : storeApi.claimCreate(
          crewFormValuesToClaimCreate({ order, formValues }),
          userFullName,
        ),
    onSuccess: (
      createdClaim: CrewMerchantUi.Claim | CrewMerchantUi.ShippingClaim,
    ) => {
      const claimSegment =
        claimType === CorsoClaimType.shippingProtection ?
          'shipping'
        : claimType.toLowerCase();
      navigate(`/${storeId}/claims/${claimSegment}/${createdClaim.id}`);
    },
  });
};

const claimTypeLabels = {
  ...crewClaimTypeEnumName,
  [CorsoClaimType.shippingProtection]: 'Shipping Protection',
} as const;

export default function ClaimCreateForm({
  order,
  claimType,
  onCancel,
}: {
  order: CrewMerchantUi.CrewOrder;
  claimType: CorsoClaimType;
  onCancel: () => void;
}) {
  const { isPending, mutate: createClaim } = useCreateClaim(order, claimType);

  const form = useForm<FormValues>({
    resolver: zodResolver(formValuesSchema),
    defaultValues: getFormValues(order, claimType),
  });

  const blocker = useBlocker(form.formState.isDirty && !isPending);

  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!form.formState.isDirty) return;

      // cancel the event as stated by the standard
      event.preventDefault();
      /** set `returnValue` to help with compatibility @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [form.formState.isDirty],
  );

  useBeforeUnload(onBeforeUnload);

  const discardClaim = useCallback(() => {
    blocker.proceed?.(); // proceed if blocked
    form.reset();
    onCancel();
  }, [blocker, onCancel, form]);

  const isAllowedToCreateShippingClaim =
    order.wasShippingProtected || order.isUnprotectedShippingClaimPermitted;

  if (
    claimType === CorsoClaimType.shippingProtection &&
    !!order?.shippingClaims?.[0]
  ) {
    const [existingClaim] = order.shippingClaims ?? [];
    return (
      <>
        <ShippingProtectionClaimDisplay claim={order.shippingClaims[0]} />
        <div className="flex flex-col gap-2 md:flex-row md:justify-end">
          <Button onClick={discardClaim}>Return to Lookup</Button>
          {existingClaim && (
            <LinkButton
              variant="primary"
              to={`/${order.storeId}/claims/shipping/${existingClaim.id}`}
            >
              View Claim
            </LinkButton>
          )}
        </div>
      </>
    );
  }

  if (
    claimType === CorsoClaimType.shippingProtection &&
    !isAllowedToCreateShippingClaim
  ) {
    return (
      <>
        <Alert
          title="Shipping Protection Claim Not Available"
          message={`Order ${order.orderNo} was not protected, and is ineligible for a Shipping Protection claim.`}
          variant="warning"
        />
        <div className="flex flex-col gap-2 md:flex-row md:justify-end">
          <Button onClick={discardClaim}>Return to Lookup</Button>
        </div>
      </>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <form
        className="space-y-4"
        onSubmit={(e) => {
          form
            .handleSubmit(
              (formFields) => createClaim(formFields),
              /* eslint-disable-next-line no-console */ // TODO consider allowing `warn`; log field errors to console
              (fieldErrors) => console.warn(fieldErrors),
            )(e)
            .catch((error) => console.error(error));
        }}
      >
        <CustomerFields claimType={claimType} />
        <ShippingAddressFields />

        {claimType === CorsoClaimType.shippingProtection ?
          <ShippingProtectionClaimFields order={order} />
        : <CrewClaimFields order={order} claimType={claimType} />}

        <div className="flex flex-col gap-2 md:flex-row md:justify-end">
          <Button type="submit" loading={isPending} variant="primary">
            Create {claimTypeLabels[claimType]} Claim
          </Button>
        </div>
      </form>

      <ConfirmModal
        title="Claim Creation Incomplete"
        prompt="You have an incomplete claim. Are you sure you want to discard them?"
        show={blocker.state === 'blocked'}
        onCancel={() => blocker.reset?.()}
        cancelText="Return to Claim"
        onConfirm={discardClaim}
        confirmText="Discard Claim"
      />
    </FormProvider>
  );
}
