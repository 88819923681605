import { forwardRef, Ref, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from '~/components/ui/primitives/Label';
import useCombinedRefs from '~/hooks/useCombinedRefs';
import { InputTypeProps } from './Input';
import SupportingText from './SupportingText';

/**
 * A `SwitchInput` is a form control that allows the user to toggle/switch between two states.
 * It should support uncontrolled and controlled usage; however, it's recommended to use as a controlled input.
 * This is preferred over the [HeadlessUI Switch](https://headlessui.com/react/switch), since this connects to an underlying forwarded `HTMLInputElement`.
 *
 * This should represent a binary system state or preference.
 * So this should **NOT** be used to represent tabs, or contextual state, because that belongs to move of a toggle button or button group.
 *
 * @see https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components
 */
const SwitchInput = forwardRef(
  (
    {
      id,
      label,
      name,
      checked: checkedControlled,
      defaultChecked: checkedUncontrolled,
      onChange,
      details,
      disabled,
      error,
      labelVisuallyHidden = false,
      hidden = false,
      ...rest
    }: Omit<InputTypeProps<never>, 'value' | 'defaultValue'> & {
      labelVisuallyHidden?: boolean;
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const visuallyChecked = checkedControlled ?? checkedUncontrolled ?? false;
    const innerRef = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, innerRef);
    const detailsId = `${id}-details`;
    const errorId = `${id}-error`;

    return (
      <div className="flex items-center justify-between gap-2" hidden={hidden}>
        <span className="flex flex-grow flex-col">
          <Label
            htmlFor={id}
            className={twMerge(labelVisuallyHidden && 'sr-only')}
          >
            {label}
          </Label>
          {error && (
            <SupportingText error id={errorId}>
              {error}
            </SupportingText>
          )}
          <SupportingText id={detailsId}>{details}</SupportingText>
        </span>
        <input
          className="hidden"
          type="checkbox"
          name={name}
          id={id}
          aria-invalid={!!error}
          aria-describedby={[details && detailsId, error && errorId]
            .filter(Boolean)
            .join(' ')}
          checked={checkedControlled}
          defaultChecked={checkedUncontrolled}
          onChange={onChange}
          ref={combinedRef}
          disabled={disabled}
          {...rest}
        />
        <button
          id={`${id}-switch-button`}
          aria-label={visuallyChecked ? 'Toggle off' : 'Toggle on'}
          type="button"
          className={twMerge(
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-corso-gray-300 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-corso-blue-600 focus:ring-offset-2',
            'disabled:cursor-not-allowed disabled:opacity-60',
            visuallyChecked && 'bg-corso-blue-600 disabled:bg-corso-blue-600',
          )}
          disabled={disabled}
          onClick={() => {
            combinedRef.current?.click();
          }}
        >
          <span
            aria-hidden="true"
            className={twMerge(
              visuallyChecked ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </button>
      </div>
    );
  },
);

export default SwitchInput;
