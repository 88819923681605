import {
  CheckCircleIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  PlusIcon,
  TruckIcon,
  UserCircleIcon,
} from '@heroicons/react/20/solid';
import { useMutation } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import { ReactNode, useState } from 'react';
import api from '~/api';
import Card from '~/components/Card';
import Feed, { FeedFallbackIcon } from '~/components/Feed';
import { TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import RelativeDateTime from '~/components/RelativeDateTime';
import { useStoreId } from '~/hooks/useStoreId';
import {
  useClaimReviewContext,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';

const eventTypeIconStyles = 'h-4 w-4 text-corso-gray-300';
const eventTypeIcons: Readonly<
  Record<CrewMerchantUi.ClaimTimeline[number]['type'], ReactNode>
> = {
  simple: <FeedFallbackIcon />,
  email: <EnvelopeIcon className={eventTypeIconStyles} />,
  shipment: <TruckIcon className={eventTypeIconStyles} />,
  processing: <CheckCircleIcon className={eventTypeIconStyles} />,
  money: <CurrencyDollarIcon className={eventTypeIconStyles} />,
  comment: <UserCircleIcon className={eventTypeIconStyles} />,
} as const;

export default function ClaimTimeline() {
  const { claimReview } = useClaimReviewContext();
  const { user, userFullName } = useMerchantContext();
  const storeId = useStoreId();
  const invalidateClaim = useInvalidateClaimReview();

  const id = claimReview.watch('claim.id');
  const events = claimReview.watch('claim.timeline') ?? [];

  const [comment, setComment] = useState('');

  const { mutateAsync: addTimelineEvent, isPending } = useMutation({
    mutationFn: api.store(storeId).claim(`${id}`, userFullName)
      .addTimelineEvent,
    onSuccess: () => invalidateClaim(),
  });

  return (
    <Card>
      <Card.Title>Timeline</Card.Title>
      <div className="flex items-center gap-2">
        <UserCircleIcon className="h-7 w-7 text-corso-gray-300" />
        <form
          className="flex-auto"
          onSubmit={(e) => {
            e.preventDefault();
            addTimelineEvent({
              id: crypto.randomUUID(),
              type: 'comment',
              message: comment,
              datetime: new Date().toISOString(),
              subject: `${user.firstName} ${user.lastName}`.trim(),
            })
              .then(() => setComment(''))
              .catch((error) => console.error(error));
          }}
        >
          <TextInput
            id="timeline-comment"
            label="Timeline Comment"
            labelVisuallyHidden
            placeholder="Add a comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            addon={{
              outsideEnd: (
                <IconAction.Button
                  iconSize="sm"
                  icon={PlusIcon}
                  type="submit"
                  title="Add Comment"
                  disabled={!comment || isPending}
                  loading={isPending}
                />
              ),
            }}
          />
        </form>
      </div>
      <Feed events={events} renderIcon={(event) => eventTypeIcons[event.type]}>
        {(event) => {
          // someday we may want to revisit these and make the events more standardized or abstract the parts, because they're nearly the same
          if (event.type === 'comment') {
            return (
              <div className="flex-auto text-xs">
                <div className="flex items-center justify-between">
                  {event.subject && (
                    <p>
                      <span className="font-medium text-corso-gray-800">
                        {event.subject}
                      </span>{' '}
                      <span className="text-corso-gray-500">commented</span>
                    </p>
                  )}
                  <RelativeDateTime
                    dateTime={event.datetime}
                    className="flex-none text-xs text-corso-gray-500"
                  />
                </div>
                <p className="text-corso-gray-800">{event.message}</p>
              </div>
            );
          }

          return (
            <div className="flex-auto text-xs">
              <div className="flex items-center justify-between">
                {/* adjust the message to not be capitalized as a statement if the subject exists before it */}
                {event.subject ?
                  <p>
                    <span className="font-medium text-corso-gray-800">
                      {event.subject}
                    </span>{' '}
                    <span className="text-corso-gray-500">
                      {event.message.charAt(0).toLowerCase()}
                      {event.message.slice(1)}
                    </span>
                  </p>
                : <p className="text-corso-gray-800">{event.message}</p>}
                <RelativeDateTime
                  dateTime={event.datetime}
                  className="flex-none py-0.5 text-xs leading-5 text-corso-gray-500"
                />
              </div>
            </div>
          );
        }}
      </Feed>
    </Card>
  );
}
