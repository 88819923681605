import AmountLine, { ComparableAmount } from './AmountLine';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';

export default function GiftCardLineItem({
  compareAmount,
  amount,
  merchandiseAmount,
  incentiveAmount,
  replacementOrder,
}: ComparableAmount & {
  merchandiseAmount?: number;
  incentiveAmount?: number;
  // * replacement order is not always present on gift-cards
  // * ex: shopify plus customers will be able to issue gift-cards without a replacement order
  replacementOrder?: {
    nameFromPlatform: string | undefined;
    adminLink: string | undefined;
  };
}) {
  return (
    <ResolutionLineItemTitle
      title="Gift Card"
      orderNumber={replacementOrder?.nameFromPlatform}
      orderHref={replacementOrder?.adminLink}
    >
      <AmountLine name="Amount" amount={amount} compareAmount={compareAmount} />
      {/* showing this value is only necessary if the incentive amount is greater than 0 */}
      {merchandiseAmount !== undefined &&
        incentiveAmount !== undefined &&
        incentiveAmount > 0 && (
          <AmountLine
            name="Merchandise Amount"
            amount={merchandiseAmount}
            compareAmount={merchandiseAmount}
          />
        )}
      {/* show this value if the incentive amount is greater than 0 */}
      {incentiveAmount !== undefined && incentiveAmount > 0 && (
        <AmountLine name="Bonus Incentive Offered" amount={incentiveAmount} />
      )}
    </ResolutionLineItemTitle>
  );
}
