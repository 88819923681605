import { useMutation } from '@tanstack/react-query';
import api from '~/api';
import ConfirmModal from '~/components/ConfirmModal';
import { useStoreId } from '~/hooks/useStoreId';
import {
  ClaimReview,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { formatter } from '~/utils/formatter';

export default function RefundPayment({
  payment,
  show,
  onClose,
}: {
  payment: NonNullable<ClaimReview['claim']['customerPayment']>;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();
  const invalidateClaim = useInvalidateClaimReview();

  const { amount, currencyCode } = payment;

  const { mutate: refundPayment } = useMutation({
    mutationKey: [payment.id],
    mutationFn: () => api.store(storeId).payment.refund(`${payment.id}`),
    onSuccess: () => invalidateClaim(),
  });

  return (
    <ConfirmModal
      title="Refund Payment"
      prompt={`Are you sure you want to process a refund for ${formatter.currency(
        amount,
        currencyCode,
      )} on Stripe?`}
      confirmText="Refund"
      cancelText="Cancel"
      show={show}
      onConfirm={() => {
        refundPayment();
        onClose();
      }}
      onCancel={onClose}
    />
  );
}
