import {
  faChevronLeft,
  faChevronRight,
  faCircleExclamation,
  faSearch,
} from '@fortawesome/pro-solid-svg-icons';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { CrewMerchantApi } from 'corso-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '~/components/Button';
import ButtonGroup from '~/components/ButtonGroup';
import Card from '~/components/Card';
import Icon, { SupportedIcon } from '~/components/Icon';
import IconAction from '~/components/IconAction';
import { Loading } from '~/components/Loading';
import SearchControlBar, {
  SearchControlBarRef,
} from './searchControlBar/SearchControlBar';
import { useColumnDefinition } from './useColumnDefinition';
import useSearchData from './useSearchData';

type Search = Omit<
  CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>,
  'take' | 'cursor'
>;
type Cursor = CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>['cursor'];

const entityLabel = {
  Return: 'claims',
  Warranty: 'claims',
  Registration: 'registrations',
  Shipping: 'claims',
  Order: 'orders',
} satisfies Record<Search['kind'], string>;

function TableStatus({
  title,
  icon,
  details,
  onAction,
  actionLabel,
}: {
  title: string;
  details: string;
  icon: SupportedIcon;
  onAction: () => void;
  actionLabel: string;
}) {
  return (
    <section className="flex flex-col items-center justify-center gap-4 p-8 text-sm">
      <header className="flex flex-col items-center gap-2 text-xl font-semibold">
        <Icon icon={icon} className="size-10 text-neutral-500" />
        <h2>{title}</h2>
      </header>
      {details}
      <Button onClick={onAction}>{actionLabel}</Button>
    </section>
  );
}

function SearchTableContent({
  entity,
  searchData,
  onClearFilters,
  onPrevious,
  onNext,
}: {
  entity: Search['kind'];
  searchData: ReturnType<typeof useSearchData>;
  onClearFilters: () => void;
  onPrevious: () => void;
  onNext: () => void;
}) {
  const { data, pageInfo, isFetching, isError, refetch } = searchData;
  const columns = useColumnDefinition(entity);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const navigate = useNavigate();

  if (isError) {
    return (
      <TableStatus
        title="Something went wrong"
        details="We encountered an error while fetching the data."
        icon={faCircleExclamation}
        onAction={() => {
          // eslint-disable-next-line no-void -- error/result will be provided through the hook again
          void refetch();
        }}
        actionLabel="Retry"
      />
    );
  }

  if (data.length === 0) {
    if (isFetching) return null; // initial loading state displays nothing

    return (
      <TableStatus
        title={`No ${entityLabel[entity]} found`}
        icon={faSearch}
        details="Try changing the filters or search terms for this view."
        onAction={onClearFilters}
        actionLabel={`View all ${entityLabel[entity]}`}
      />
    );
  }

  return (
    <>
      <div className="relative overflow-auto">
        <table className="min-w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="sticky top-0 bg-neutral-100 py-2 pl-4 pr-3 text-left text-xs font-semibold text-neutral-900 shadow-sm sm:pl-6 lg:pl-8"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className="group/row cursor-pointer hover:bg-neutral-100"
                // TODO evaluate alternatives to using a `table` and associated elements so that a link can be used instead
                // ! only valid non-order tables, unless we add a relevant page for it
                onClick={() => navigate(`${row.original.id}`)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="py-2 pl-4 pr-3 text-xs text-neutral-800 group-[&:not(:last-child)]/row:border-b group-[&:not(:last-child)]/row:border-neutral-200 sm:pl-6 lg:pl-8"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {(pageInfo?.nextCursor !== null || pageInfo?.prevCursor !== null) && (
        <div className="flex justify-center border-t bg-neutral-100 p-2">
          <ButtonGroup horizontal>
            <IconAction.Button
              icon={faChevronLeft}
              disabled={pageInfo?.prevCursor === null}
              onClick={onPrevious}
              title="Previous page"
              iconSize="xs"
            />
            <IconAction.Button
              icon={faChevronRight}
              disabled={pageInfo?.nextCursor === null}
              onClick={onNext}
              title="Next page"
              iconSize="xs"
            />
          </ButtonGroup>
        </div>
      )}
    </>
  );
}

export default function SearchTable({ entity }: { entity: Search['kind'] }) {
  const searchControlBarRef = useRef<SearchControlBarRef>(null);
  const [search, setSearch] = useState<Search>({
    kind: entity,
    orderBy: 'desc',
    filters: {},
  });
  const [cursor, setCursor] = useState<Cursor>(null);

  const searchData = useSearchData({ ...search, cursor });

  return (
    <Card flush>
      <div
        // max height is for the sticky header to work otherwise it will scroll with the table and be overlapped by the app bar
        className="flex max-h-[80dvh] flex-col gap-0 divide-y divide-neutral-200"
        data-testid="searchTable"
      >
        <SearchControlBar
          entity={entity}
          onSearchChange={(changed) => setSearch(changed)}
          isSearchPending={searchData.isFetching} // TODO consider renaming `isSearchPending`
          ref={searchControlBarRef}
        />
        {searchData.isFetching && <Loading />}
        <SearchTableContent
          entity={entity}
          searchData={searchData}
          onClearFilters={() => searchControlBarRef.current?.clearFilters()}
          onPrevious={() => {
            const { pageInfo } = searchData;
            if (pageInfo?.prevCursor === null) return;
            setCursor({
              id: pageInfo.prevCursor,
              direction: 'prev',
            });
          }}
          onNext={() => {
            const { pageInfo } = searchData;
            if (pageInfo.nextCursor === null) return;
            setCursor({
              id: pageInfo.nextCursor,
              direction: 'next',
            });
          }}
        />
      </div>
    </Card>
  );
}
