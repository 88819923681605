import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useEffect, useRef, useState } from 'react';
import Card from '~/components/Card';
import Page from '~/components/Page';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useDashboardQuery,
  useLoadDashboardOptions,
} from '~/hooks/useDashboardQuery';
import { useStoreId } from '~/hooks/useStoreId';

// TODO: dynamic set height on iFrame

function LoadDashboard({ dashboardId }: { dashboardId: string }) {
  const iframeRef = useRef<HTMLDivElement>(null);

  const { data } = useDashboardQuery(dashboardId);
  const storeId = useStoreId();

  useEffect(() => {
    if (!data) {
      return;
    }

    const mountPoint = iframeRef.current;
    if (!mountPoint) {
      console.error('embedded dashboard mount point not found');
      return;
    }

    const embed = async () => {
      const { domain: supersetDomain, token } = data;
      await embedDashboard({
        id: dashboardId,
        supersetDomain,
        mountPoint,
        fetchGuestToken: () => Promise.resolve(token),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: false,
          hideTab: false,
          filters: {
            visible: true,
            expanded: false,
          },
        },
      });
    };

    embed().catch((error) => {
      console.error('Error embedding dashboard', error);
    });
  }, [data, dashboardId, storeId]);

  return (
    <div
      ref={iframeRef}
      className="flex flex-col bg-[#F7F7F7] [&>iframe]:min-h-[2500px]"
    />
  );
}

export default function Dashboards() {
  const storeId = useStoreId();
  const { data } = useLoadDashboardOptions();
  const dashboards = data?.dashboards ?? [];

  const [selectedDashboard, setDashboard] = useState<
    (typeof dashboards)[number] | null
  >(null);

  const [defaultDashboard] = dashboards;
  if (defaultDashboard && !selectedDashboard) {
    setDashboard(defaultDashboard);
  }

  useEffect(() => {
    setDashboard(null);
  }, [storeId]);

  return (
    <Page title="Dashboards" size="fullWidth">
      <Card>
        <div className="flex flex-col gap-6">
          {/* make the select list box go to the end of the div */}
          <div className="flex flex-row items-end">
            <SimpleSelect
              label="Select A Dashboard"
              labelVisuallyHidden
              options={dashboards.map((dashboard) => ({
                value: dashboard.id,
                label: dashboard.name,
              }))}
              value={selectedDashboard?.id}
              onChange={(id) =>
                setDashboard(dashboards.find((d) => d.id === id) ?? null)
              }
            />
          </div>

          {selectedDashboard && (
            <LoadDashboard dashboardId={selectedDashboard.id} />
          )}
        </div>
      </Card>
    </Page>
  );
}
