import { CrewClaimTypeEnum } from 'corso-types';
import { Navigate } from 'react-router-dom';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useStoreId } from '~/hooks/useStoreId';

/** Given the current configuration, changes what the default route is when initially opening the app. */
export default function DefaultStoreNavigation() {
  const storeId = useStoreId();
  const {
    isLoading,
    isWarrantyEnabled,
    isReturnsEnabled,
    isShippingProtectionEnabled,
    isRegistrationEnabled,
  } = useEnabledClaimType();

  if (isLoading) return null; // ? might need some kind of better loader here, once this changes to use the `actions`, `loaders` model it'll be better

  if (isReturnsEnabled) {
    return (
      <Navigate
        to={`/${storeId}/claims/${CrewClaimTypeEnum.return.toLowerCase()}`}
        replace
      />
    );
  }

  if (isWarrantyEnabled) {
    return (
      <Navigate
        to={`/${storeId}/claims/${CrewClaimTypeEnum.warranty.toLowerCase()}`}
        replace
      />
    );
  }

  if (isShippingProtectionEnabled) {
    return <Navigate to={`/${storeId}/claims/shipping`} replace />;
  }

  if (isRegistrationEnabled) {
    return <Navigate to={`/${storeId}/registrations`} replace />;
  }

  // a final fallback, but this will be blocked for non-admins, but that's fine, since they wouldn't have anything active
  return <Navigate to={`/${storeId}/analytics`} replace />;
}
