import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './primitives/Tooltip';

export default function SimpleTooltip({
  children: trigger,
  content,
  side,
  asChild,
}: PropsWithChildren<{
  content?: ReactNode;
  side?: ComponentProps<typeof TooltipContent>['side'];
  asChild?: ComponentProps<typeof TooltipTrigger>['asChild'];
}>) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild={asChild}>{trigger}</TooltipTrigger>
        <TooltipContent side={side}>{content}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
