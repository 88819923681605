import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import ConfirmModal from '~/components/ConfirmModal';
import { useStoreId } from '~/hooks/useStoreId';
import { useInvalidateClaimReview } from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';

export default function CloseClaim({
  show,
  onClose,
  claimId,
}: {
  claimId: number;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();

  const queryClient = useQueryClient();
  const invalidateClaim = useInvalidateClaimReview();

  const { userFullName } = useMerchantContext();

  const { mutate: closeClaim } = useMutation({
    mutationKey: [claimId],
    mutationFn: () =>
      api.store(storeId).claim(`${claimId}`, userFullName).close(),
    onSuccess: () =>
      Promise.all([
        invalidateClaim(),
        queryClient.invalidateQueries({
          queryKey: ['claims'],
        }),
      ]),
  });

  return (
    <ConfirmModal
      title="Permanently Cancel Claim"
      prompt="Cancelled claims cannot be reopened and nothing will be issued to the customer. A customer can still create a new claim against this order."
      confirmText="Cancel Claim"
      cancelText="Cancel"
      show={show}
      onConfirm={() => {
        closeClaim();
        onClose();
      }}
      onCancel={onClose}
    />
  );
}
