import { forwardRef, Ref } from 'react';
import Input, { InputTypeProps } from './Input';

type DateLike = string | number | Date;

/**
 * Gets the timezone offset of the current location in milliseconds.
 * Leverages, `Date.getTimezoneOffset` to get the local timezone offset.
 * Importantly, it is always the local timezone offset, regardless of the date.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#description
 */
function currentTimezoneOffset() {
  const MINUTE_TO_MILLISECOND = 60_000;
  const now = new Date();
  return now.getTimezoneOffset() * MINUTE_TO_MILLISECOND;
}

function offsetLocalToUtc(local: DateLike) {
  const localDatetime = new Date(local);
  const utcDatetime = new Date(
    localDatetime.getTime() + currentTimezoneOffset(),
  );

  return utcDatetime.toISOString();
}

function offsetUtcToLocal(utc: DateLike) {
  const utcDatetime = new Date(utc);
  const localDatetime = new Date(
    utcDatetime.getTime() - currentTimezoneOffset(),
  );

  return localDatetime.toISOString();
}

// `addon` props are not supported, since this leverages the native date input display
const DateInput = forwardRef(
  (
    {
      value,
      onChange,
      ...rest
    }: Omit<InputTypeProps<never>, 'addon' | 'value' | 'onChange'> & {
      /**
       * Accepts any valid date-like value, or `null` to fallback to an empty value while still being controlled.
       * Assumed to be UTC, but will be shown in the local timezone.
       */
      value: string | number | Date | null;
      /**
       * Takes the native `yyyy-MM-dd` date and results in a valid UTC ISO string.
       * Although there is no time component, the entered date is midnight local time, and converted to the related UTC time.
       */
      onChange: (value: string) => void;
    },
    ref: Ref<HTMLInputElement>,
  ) => (
    <Input
      type="date"
      ref={ref}
      value={value ? offsetUtcToLocal(value).substring(0, 10) : ''}
      // * explicit type assertion based off the valid value expected from the native date input; reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Date_and_time_formats#date_strings
      onChange={(e) => {
        onChange(e.target.value ? offsetLocalToUtc(e.target.value) : '');
      }}
      {...rest}
    />
  ),
);

export default DateInput;
