import { startOfDay, subDays, subWeeks, subYears } from 'date-fns';
import { ComponentProps, forwardRef } from 'react';

/** Any value that can be coerced to a `Date`. */
export type DateTime = Date | number | string;

/**
 * Checks if a date is invalid.
 */
export function isInvalidDate(dateTime: DateTime) {
  const date = new Date(dateTime);
  return Number.isNaN(date.getTime());
}

/**
 * Formats a date as a relative time from now.
 * Different representations will be shown based on the duration of the time frame in the current locale.
 * Formats include: "Yesterday at {time}", "Today at {time}", "{weekday} at {time}", "{abbreviated month} {day} at {time}", "{abbreviated month} {day}, {year}".
 * If `full` is `true` or the date is in the future a full date and time will be shown in the format of: "{full month} {day}, {year} at {time}".
 *
 * Inspired by how Shopify shows relative times for orders in the admin.
 */
// * consider elevating to `formatter` utility
function formatRelativeTime(date: Date, full = false) {
  if (isInvalidDate(date)) return 'Invalid Date';

  const now = new Date();
  const today = startOfDay(now);
  const yesterday = subDays(today, 1);
  const aWeekAgo = subWeeks(today, 1);
  const aYearAgo = subYears(today, 1);

  const timePart = date
    .toLocaleTimeString(undefined, { timeStyle: 'short' })
    .toLocaleLowerCase();

  if (full) {
    // {full month} {day}, {year} at {time}
    const datePart = date.toLocaleDateString(undefined, { dateStyle: 'long' });
    return `${datePart} at ${timePart}`;
  }

  if (date > now) {
    // {abbreviated month} {day}, {year} at {time}
    const datePart = date.toLocaleDateString(undefined, {
      dateStyle: 'medium',
    });
    return `${datePart} at ${timePart}`;
  }

  // yesterday or today
  if (date >= yesterday && date < now) {
    if (date < today) {
      return `Yesterday at ${timePart}`;
    }
    return `Today at ${timePart}`;
  }

  // within a week
  if (date >= aWeekAgo) {
    // {weekday} at {time}
    const datePart = date.toLocaleDateString(undefined, { weekday: 'long' });
    return `${datePart} at ${timePart}`;
  }

  // within a year
  if (date >= aYearAgo) {
    // {abbreviated month} {day} at {time}
    const datePart = date.toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
    });
    return `${datePart} at ${timePart}`;
  }

  // beyond a year
  // {full month} {day}, {year}
  return Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
  }).format(date);
}

/**
 * Shows a relative date time from now.
 *
 * When provided an invalid date, it will show "Invalid Date" and set a `data-invalid-date` attribute, so that it can be styled if desired in addition to the `datetime` attribute.
 */
const RelativeDateTime = forwardRef<
  HTMLTimeElement,
  Omit<ComponentProps<'time'>, 'dateTime'> & {
    dateTime: DateTime;
    full?: boolean;
  }
>(({ dateTime, full = false, ...props }, ref) => {
  const date = new Date(dateTime);

  return (
    <time
      dateTime={!isInvalidDate(date) ? date.toISOString() : 'Invalid Date'}
      data-invalid-date={isInvalidDate(date)}
      aria-invalid
      ref={ref}
      {...props}
    >
      {formatRelativeTime(date, full)}
    </time>
  );
});

RelativeDateTime.displayName = 'RelativeDateTime';

export default RelativeDateTime;
