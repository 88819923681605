import * as Sentry from '@sentry/react';
import env from '~/env';

type IdentifyUserParams = {
  email: string;
  storeName?: string;
  storeId?: number;
};

export function identifyUser({
  email,
  storeId,
  storeName,
}: IdentifyUserParams) {
  try {
    if (env.VITE_NODE_ENV === 'prod') {
      Sentry.setUser({
        email,
        username: email,
      });
      if (storeName) Sentry.setTag('storeName', storeName);
      if (storeId) Sentry.setTag('storeId', storeId.toString());
    }
  } catch (error) {
    console.error(error);
  }
}
