import { PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import api from '~/api';
import Address from '~/components/Address';
import Button from '~/components/Button';
import ConfirmModal from '~/components/ConfirmModal';
import DescriptionList, { Description } from '~/components/DescriptionList';
import { DateInput, SwitchInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import LineItem from '~/components/LineItem';
import MediaGallery from '~/components/MediaGallery';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';

import Card from '~/components/Card';
import ProductReplacement from '~/components/claim/resolutionModifiers/ProductReplacement';

import ClipboardButton from '~/components/ClipboardButton';
import DetailCard from '~/components/DetailCard';
import { customFieldValueTypeFormatter } from '~/components/FormatCustomField';
import { Loading } from '~/components/Loading';
import Page from '~/components/Page';
import PageStatus from '~/components/PageStatus';
import { Badge } from '~/components/ui/primitives/Badge';
import { useStoreId } from '~/hooks/useStoreId';
import { queryClient } from '~/providers/QueryProvider';
import {
  RegistrationUpdate,
  registrationUpdate,
  SelectedVariant,
} from '~/types';
import { formatter } from '~/utils/formatter';

function RegistrationClaimSummary({
  storeId,
  claims,
}: {
  storeId: string;
  claims: CrewMerchantUi.Registration['claims'];
}) {
  return (
    claims.length > 0 &&
    claims.map((claim) => {
      const { externalId, claimType, id: claimId } = claim;

      return (
        <DetailCard
          key={claimId}
          linkTo={`/${storeId}/claims/${claimType}/${claimId}`}
          externalId={externalId}
          title={`${claimType} Claim`}
        />
      );
    })
  );
}

function EditRegistration({
  show,
  registration,
  onClose,
}: {
  registration: CrewMerchantUi.Registration;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();

  const queryKey = [
    'registration',
    {
      registrationId: `${registration.id}`,
      storeId,
    },
  ];

  const { mutate: updateRegistration, isPending } = useMutation({
    mutationFn: api.store(storeId).registrations.update,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const [showEditAddress, setEditAddress] = useState(!!registration.address);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    register,
  } = useForm<RegistrationUpdate>({
    values: {
      estimatedPurchaseDate: registration.estimatedPurchaseDate,
      address: registration.address,
    },
    resolver: zodResolver(registrationUpdate),
  });

  const newPurchaseDate = watch('estimatedPurchaseDate');

  const handleShowEditAddress = () => {
    setEditAddress(!showEditAddress);
    reset({ estimatedPurchaseDate: newPurchaseDate });
  };

  const closeAndReset = () => {
    onClose();
    reset();
    setEditAddress(false);
  };

  const formId = 'edit-registration-form';
  return (
    <Modal
      title="Edit Registration"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((body) => {
          updateRegistration({
            registrationId: `${registration.id}`,
            body,
          });
          onClose();
        })}
        className="flex flex-col gap-4"
      >
        <Controller
          control={control}
          name="estimatedPurchaseDate"
          render={({ field, fieldState }) => (
            <DateInput
              id="estimated-purchase-date"
              label="Estimated Purchase Date"
              value={(field.value as string | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
              onChange={field.onChange}
              error={fieldState.error?.message}
              details={
                !(field.value as string | undefined) &&
                'Using Store Defaults for Warranty Reviews'
              } // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
            />
          )}
        />

        <SwitchInput
          id="is-estimated-purchase-date"
          label="Edit Address"
          onChange={handleShowEditAddress}
          checked={showEditAddress}
        />

        {showEditAddress && (
          <>
            <TextInput
              id="shipping-address-first-name"
              label="First Name"
              autoComplete="given-name"
              {...register('address.firstName')}
              error={errors.address?.line1?.message}
            />
            <TextInput
              id="shipping-address-last-name"
              label="Last Name"
              autoComplete="family-name"
              {...register('address.lastName')}
              error={errors.address?.line1?.message}
            />

            <TextInput
              id="shipping-address-line1"
              label="Street Address Line 1"
              autoComplete="address-line1"
              {...register('address.line1')}
              error={errors.address?.line1?.message}
            />

            <TextInput
              id="shipping-address-line2"
              label="Street Address Line 2"
              autoComplete="address-line2"
              {...register('address.line2')}
              error={errors.address?.line2?.message}
            />

            <TextInput
              id="shipping-address-city"
              label="City"
              autoComplete="address-level2"
              {...register('address.city')}
              error={errors.address?.city?.message}
            />

            <TextInput
              id="shipping-address-state-or-province"
              label="State or Province Code"
              autoComplete="address-level1"
              {...register('address.stateOrProvinceCode')}
              error={errors.address?.stateOrProvinceCode?.message}
            />

            <TextInput
              id="shipping-address-postal-code"
              label="Postal Code"
              autoComplete="postal-code"
              {...register('address.postalCode')}
              error={errors.address?.postalCode?.message}
            />

            <TextInput
              id="shipping-address-country"
              label="Country Code"
              autoComplete="country"
              {...register('address.countryCode')}
              error={errors.address?.countryCode?.message}
            />
          </>
        )}
      </form>
    </Modal>
  );
}

function DeleteRegistrationProduct({
  show,
  lineItemId,
  registrationId,
  onClose,
}: {
  lineItemId: number;
  registrationId: number;
  show: boolean;
  onClose: () => void;
}) {
  const storeId = useStoreId();

  const queryKey = [
    'registration',
    {
      registrationId: `${registrationId}`,
      storeId,
    },
  ];

  const { mutate: updateRegistration } = useMutation({
    mutationFn: api.store(storeId).registrations.update,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const confirmDelete = () => {
    updateRegistration({
      registrationId: `${registrationId}`,
      body: {
        lineItemIdToRemove: lineItemId,
      },
    });
    onClose();
  };

  return (
    <ConfirmModal
      title="Delete Product"
      prompt="Are you sure you would like to remove this product from the Registration?"
      confirmText="Delete"
      cancelText="Cancel"
      show={show}
      onConfirm={confirmDelete}
      onCancel={onClose}
    />
  );
}

// TODO: could be consolidated with the other customer cards, I'm just not smart enough to
// TODO: figure out how to do it right now

function RegistrationCustomerDetail({
  registration,
}: {
  registration: CrewMerchantUi.Registration;
}) {
  const { email, firstName, lastName, address } = registration;

  const customerName =
    firstName && lastName ? `${firstName} ${lastName}` : 'No name';

  return (
    <Card key={registration.id}>
      <div className="flex flex-col gap-1.5">
        <div className="flex items-center justify-between">
          <Card.Title>Customer</Card.Title>
        </div>
        <p className="text-xs text-corso-gray-500">{customerName}</p>
      </div>

      <div className="flex flex-col gap-1">
        <Card.Title>Contact information</Card.Title>
        <div className="flex items-center justify-between">
          <p className="text-xs text-corso-gray-500">{email}</p>
          <ClipboardButton
            onClick={() => email}
            variant="ghost"
            iconSize="sm"
          />
        </div>

        <p className="text-xs text-corso-gray-500">
          {address?.phone ?? 'No phone'}
        </p>
      </div>
      {address && (
        <div className="flex flex-col gap-1">
          <Card.Title>Shipping address</Card.Title>
          <Address address={address} />
        </div>
      )}
    </Card>
  );
}

function RegistrationRequest({
  registration,
}: {
  registration: CrewMerchantUi.Registration;
}) {
  const {
    estimatedPurchaseDate,
    lineItems,
    proofOfPurchaseFileUrls,
    registrationChannelName,
    createdOn,

    customerProvidedOrderNumber,
    id: registrationId,
    customFields,
    canceledOn,
  } = registration;

  const gridDescriptions: Description[] = [];

  if (canceledOn) {
    gridDescriptions.push({
      details: <Badge variant="danger">Canceled</Badge>,
      term: 'Status',
    });
  } else {
    gridDescriptions.push({
      details: <Badge variant="success">Active</Badge>,
      term: 'Status',
    });
  }

  gridDescriptions.push({
    details: registrationChannelName,
    term: 'Channel',
  });

  if (customerProvidedOrderNumber) {
    gridDescriptions.push({
      details: customerProvidedOrderNumber,
      term: 'Customer Provided Order #',
    });
  }

  if (estimatedPurchaseDate) {
    gridDescriptions.push({
      details: formatter.date(estimatedPurchaseDate),
      term: 'Estimated Purchase Date',
    });
  }

  customFields?.forEach(({ displayName, valueType, value }) => {
    gridDescriptions.push({
      term: displayName,
      details: customFieldValueTypeFormatter[valueType](value),
    });
  });

  gridDescriptions.push({
    details: <RelativeDateTime dateTime={createdOn} />,
    term: 'Created',
  });

  if (canceledOn) {
    gridDescriptions.push({
      details: <RelativeDateTime dateTime={canceledOn} />,
      term: 'Canceled',
    });
  }

  const listDescriptions: Description[] = [];

  if (proofOfPurchaseFileUrls.length) {
    listDescriptions.push({
      term: 'Proof Of Purchase',
      details: (
        <MediaGallery
          media={proofOfPurchaseFileUrls.map((url) => ({
            src: url,
            alt: 'Proof of Purchase',
          }))}
        />
      ),
    });
  }

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [replacementItems, setReplacementItems] = useState<SelectedVariant[]>(
    [],
  );

  const storeId = useStoreId();

  const queryKey = [
    'registration',
    {
      registrationId: `${registration.id}`,
      storeId,
    },
  ];

  const { mutate: updateRegistration, isPending } = useMutation({
    mutationFn: api.store(storeId).registrations.update,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const handleSave = () => {
    updateRegistration({
      registrationId: `${registrationId}`,
      body: {
        storeProductVariants: replacementItems.map((item) => ({
          idFromPlatform: item.idFromPlatform,
          quantity: item.quantity,
        })),
      },
    });
    setReplacementItems([]);
  };

  const isRegistrationEditable =
    registration.claims.length === 0 && !canceledOn;

  return (
    <>
      <Card>
        <Card.Title>Registration Request Details</Card.Title>

        <DescriptionList descriptions={gridDescriptions} />
        {listDescriptions.length > 0 && (
          <DescriptionList descriptions={listDescriptions} layoutType="list" />
        )}
      </Card>

      <Card>
        <Card.Title>Products</Card.Title>

        {lineItems?.map((li) => (
          <div className="flex justify-between" key={li.id}>
            <LineItem
              variant="DEFAULT"
              key={li.id}
              imageUrl={li.imgUrl}
              sku={li.sku}
              name={li.name}
              quantity={li.quantity}
              options={li.optionsFromPlatform}
            />

            {isRegistrationEditable && (
              <div>
                <IconAction.Button
                  icon={TrashIcon}
                  title="Remove Product"
                  onClick={() => setShowDeleteConfirmation(true)}
                />
              </div>
            )}

            <DeleteRegistrationProduct
              lineItemId={li.id}
              registrationId={registrationId}
              show={showDeleteConfirmation}
              onClose={() => setShowDeleteConfirmation(false)}
            />
          </div>
        ))}
      </Card>

      {isRegistrationEditable && (
        <Card>
          <div className="flex items-center justify-between">
            <Card.Title>Add Products</Card.Title>
            {replacementItems.length > 0 && (
              <Button
                title="Save Replacement Products"
                variant="primary"
                loading={isPending}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            )}
          </div>
          <div className="flex flex-col">
            <ProductReplacement
              products={replacementItems}
              onChange={setReplacementItems}
              originalProductName=""
              buttonText="Add Product"
            />
          </div>
        </Card>
      )}
    </>
  );
}

const queryKeyPrefix = 'registration';

export default function RegistrationOverview() {
  const { registrationId } = useParams();
  const storeId = useStoreId();

  const {
    data: registration,
    isPending,
    isError,
    error,
  } = useQuery({
    enabled: !!registrationId,
    queryKey: [queryKeyPrefix, { registrationId, storeId }],
    queryFn: () => {
      if (!registrationId) throw new Error('Missing Registration ID');
      return api.store(storeId).registrations.get({
        registrationId,
      });
    },
  });

  const { mutate: cancelRegistration } = useMutation({
    mutationFn: api.store(storeId).registrations.cancel,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          queryKeyPrefix,
          {
            registrationId,
            storeId,
          },
        ],
      }),
  });

  const [showEditRegistration, setShowEditRegistration] = useState(false);

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  if (isPending) return <Loading />;

  if (isError) {
    // TODO differentiate client and server errors, and only propagate server/request errors
    return <PageStatus.Error error={error} />;
  }

  const { claims, canceledOn } = registration;

  const canRegistrationBeCanceled = !canceledOn;
  const isRegistrationEditable =
    claims.length === 0 && canRegistrationBeCanceled;

  return (
    <Page
      backAction
      title={`#${registration.externalId}`}
      subtitle={
        <>
          <p>{registration.registrationChannelName}</p>
          <p>
            Created <RelativeDateTime dateTime={registration.createdOn} full />
          </p>
        </>
      }
      primaryAction={
        canceledOn ? undefined : (
          {
            to: `/${storeId}/orders/lookup/${registration.orderIdFromPlatform}`,
            content: 'Create Claim',
          }
        )
      }
      secondaryActions={[
        {
          id: 'more-actions',
          content: 'More Actions',
          actions: [
            {
              id: 'edit-registration',
              icon: PencilIcon,
              content: 'Edit Registration',
              disabled: !isRegistrationEditable,
              onAction: () => setShowEditRegistration(true),
            },
            {
              id: 'cancel-registration',
              content: 'Cancel Registration',
              disabled: !canRegistrationBeCanceled,
              icon: XMarkIcon,
              onAction: () => setShowCancelConfirmation(true),
            },
          ],
        },
      ]}
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[3fr_1fr]">
        <div className="space-y-4">
          <RegistrationRequest registration={registration} />
        </div>
        <div className="order-first space-y-4 md:order-none">
          <RegistrationClaimSummary storeId={storeId} claims={claims} />
          <RegistrationCustomerDetail registration={registration} />
        </div>
      </div>
      <EditRegistration
        registration={registration}
        show={showEditRegistration}
        onClose={() => setShowEditRegistration(false)}
      />
      <ConfirmModal
        onCancel={() => setShowCancelConfirmation(false)}
        title="Cancel Registration"
        prompt={
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <p>Are you sure you want to cancel this registration?</p>
              <p>
                Once cancelled, claims can no longer be created for this
                registration, and it will no longer be editable.
              </p>
            </div>
          </div>
        }
        show={showCancelConfirmation}
        cancelText="Keep Registration"
        confirmText="Cancel"
        onConfirm={() => {
          setShowCancelConfirmation(false);
          cancelRegistration({
            registrationId: `${registration.id}`,
          });
        }}
      />
    </Page>
  );
}
