import {
  BellAlertIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { UIStatusVariant } from '~/types';

// might also want `success` and `info` variants, but these should also be reviewed
const variantStyles = {
  DEFAULT: {
    wrapperStyles: 'border-corso-gray-400 bg-corso-gray-100',
    icon: (
      <BellAlertIcon
        className="h-5 w-5 text-corso-gray-500"
        aria-hidden="true"
      />
    ),
    textStyles: 'text-corso-gray-800',
  },
  info: {
    wrapperStyles: 'border-corso-blue-600 bg-corso-blue-50',
    icon: (
      <InformationCircleIcon
        className="h-5 w-5 text-corso-blue-600"
        aria-hidden="true"
      />
    ),
    textStyles: 'text-corso-blue-600',
  },
  warning: {
    wrapperStyles: 'border-corso-orange-700 bg-corso-orange-50',
    icon: (
      <ExclamationTriangleIcon
        className="h-5 w-5 text-corso-orange-700"
        aria-hidden="true"
      />
    ),
    textStyles: 'text-corso-orange-700',
  },
  danger: {
    wrapperStyles: 'border-corso-red-600 bg-corso-red-50',
    icon: (
      <ExclamationCircleIcon
        className="h-5 w-5 text-corso-red-600"
        aria-hidden="true"
      />
    ),
    textStyles: 'text-corso-red-700',
  },
} as const satisfies Record<
  Exclude<UIStatusVariant, 'success'>,
  {
    wrapperStyles: string;
    icon: React.ReactNode;
    textStyles: string;
  }
>;

export default function Alert({
  variant = 'DEFAULT',
  title,
  message,
}: {
  variant?: keyof typeof variantStyles;
  title?: string;
  message: ReactNode;
}) {
  const { wrapperStyles, icon, textStyles } = variantStyles[variant];

  return (
    <div
      className={twMerge(
        'flex rounded-sm border-l-4 px-3 py-2',
        title ? 'flex-row gap-2' : 'flex-col gap-1',
        wrapperStyles,
      )}
    >
      <div className="flex items-start gap-2">
        <div className="flex-shrink-0">{icon}</div>
        <div className={twMerge('text-sm', textStyles)}>
          {title && <p className="font-semibold">{title}</p>}
          {message}
        </div>
      </div>
    </div>
  );
}
