import { Presence } from '@radix-ui/react-presence';
import { ReactElement } from 'react';

// ! This might be unstable, as the underlying component was not intended for public use.
/**
 * This is not a component taken from shadcn/ui; however, it is based on the `Presence` component created for `@radix-ui`.
 * The `Presence` component is not intended for public use, but is used by other components created by `@radix-ui`.
 *
 * Provided through `Transition` as an abstraction to help maintaining mounted components during animations or transitions of `children`.
 * Because `Presence` is not intended for public use, this could be unstable.
 *
 * The child element of this component, can then use animations and once they complete, the element can be unmounted if `show` is `false`, and will mount then animate in if `show` is `true`.
 *
 * From the `@radix-ui` documentation guide for animation:
 * > You can use CSS animation to animate both mount and unmount phases. The latter is possible because the Radix Primitives will suspend unmount while your animation plays out.
 *
 * This provides that "suspend unmount" feature, which can be useful for other components too.
 *
 * For anything that requires more complex animations, we should consider using `framer-motion`, `react-spring`, or GSAP (GreenSock Animation Platform).
 *
 * @see https://github.com/radix-ui/primitives/tree/main/packages/react/presence
 * @see https://www.radix-ui.com/primitives/docs/guides/animation
 * @see https://www.framer.com/motion/
 * @see https://www.react-spring.dev/
 * @see https://gsap.com/
 */
export function Transition({
  children,
  show,
}: {
  children: ReactElement;
  show: boolean;
}) {
  return <Presence present={show}>{children}</Presence>;
}
