import { CrewMerchantUi, isTruthy } from 'corso-types';
import DescriptionList from '~/components/DescriptionList';
import { customFieldValueTypeFormatter } from '~/components/FormatCustomField';

export default function ClaimLineItemDetails({
  claimLineItem,
}: {
  claimLineItem: CrewMerchantUi.ClaimLineItem;
}) {
  const descriptions = [
    {
      term: 'Reason',
      details: (
        <>
          {claimLineItem.reason.name}
          {claimLineItem.reason.detail?.name &&
            ` - ${claimLineItem.reason.detail.name}`}
        </>
      ),
    },
    !!claimLineItem.comments?.length && {
      term: 'Comment',
      details: claimLineItem.comments,
    },
    ...claimLineItem.customFields.map(({ displayName, valueType, value }) => ({
      term: displayName,
      details: customFieldValueTypeFormatter[valueType](value),
    })),
    !!claimLineItem.noteToCustomer?.length && {
      term: 'Note to Customer',
      details: claimLineItem.noteToCustomer,
    },
  ].filter(isTruthy);

  return <DescriptionList descriptions={descriptions} layoutType="list" />;
}
