import { zodResolver } from '@hookform/resolvers/zod';
import { isTruthy } from 'corso-types';
import { FormEventHandler, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Alert from '~/components/Alert';
import Card from '~/components/Card';
import {
  NumberInput,
  SwitchInput,
  TextInput,
  UrlInput,
} from '~/components/field';
import FloatingSave from '~/components/FloatingSave';
import Page from '~/components/Page';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from '~/hooks/useConfigSettings';
import {
  warrantySettingsFormSchema,
  WarrantySettingsFormValues,
} from '~/types';

export default function WarrantiesSettings() {
  const formId = 'warranty-settings';
  const formRef = useRef<HTMLFormElement>(null);

  const { data } = useConfigSettings(({ warranties }) => warranties);
  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,
    setValue,
    watch,
  } = useForm<WarrantySettingsFormValues>({
    resolver: zodResolver(warrantySettingsFormSchema),
    values: data,
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => saveChanges({ warranties: values }))(event).catch(
      console.error,
    );
  };

  const isWarrantiesEnabled = watch('isWarrantiesEnabled');

  const orderPrefix = watch('warrantyOrderPrefix');

  return (
    <Page
      title="Warranties"
      secondaryActions={[
        isWarrantiesEnabled && {
          id: 'disable-returns',
          content: 'Turn Off',
          onAction: () => {
            setValue('isWarrantiesEnabled', false, {
              shouldDirty: true,
            });
            formRef.current?.requestSubmit();
          },
        },
      ].filter(isTruthy)}
      primaryAction={
        isWarrantiesEnabled ? undefined : (
          {
            onAction: () => {
              setValue('isWarrantiesEnabled', true, {
                shouldDirty: true,
              });
              formRef.current?.requestSubmit();
            },
            content: 'Turn On',
          }
        )
      }
    >
      <form
        id={formId}
        ref={formRef}
        className="flex flex-col gap-4"
        onSubmit={submitHandler}
        onReset={(e) => {
          e.preventDefault();
          reset();
        }}
      >
        {!isWarrantiesEnabled && (
          <Alert
            variant="info"
            title="Warranties are Disabled"
            message="Settings can be configured, but warranties will not be offered to customers."
          />
        )}

        <FloatingSave
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          form={formId}
        />
        <Card>
          {/* // ? do we want an addon or require full URLs that are HTTPS; this would better with input masking */}
          <UrlInput
            id="warranty-policy-url"
            label="Policy URL"
            placeholder="https://company.com/warranties-policy"
            {...register('warrantyPolicyUrl')}
            error={errors.warrantyPolicyUrl?.message}
          />
          <NumberInput
            id="warranty-validity-days"
            label="Warranty Window"
            placeholder="30"
            details="The number of days from the date an order was fulfilled that a customer can request a warranty review."
            required
            {...register('defaultWarrantyDays', {
              valueAsNumber: true,
            })}
            error={errors.isWarrantyInspectionEnabled?.message}
            addon={{
              insideEnd: 'days',
            }}
          />

          <TextInput
            id="warranty-order-prefix"
            label="Warranty Order Prefix"
            details={
              orderPrefix ?
                `The prefix to add to warranty replacement orders, e.g. ${orderPrefix}1234`
              : 'The prefix to add to warranty replacement orders.'
            }
            placeholder="#"
            {...register('warrantyOrderPrefix')}
            error={errors.warrantyOrderPrefix?.message}
          />

          <SwitchInput // this is just for visual reference to the user
            defaultChecked
            disabled
            id="customer-image-required"
            label="Require Media Upload"
            details="Enforce that customers provide an image or video when submitting a warranty request."
          />

          <Controller
            control={control}
            name="isWarrantyInspectionEnabled"
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="warranty-inspection-enabled"
                label="Inspections"
                details="Ability to add an inspection to a warranty request."
                checked={!!value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="isMultipleWarrantyClaimsAllowed"
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="is-multiple-warranty-claims-allowed"
                label="Allow Multiple Claims"
                details="Allow customers to submit multiple warranty claims for the same line item."
                checked={!!value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Card>
      </form>
    </Page>
  );
}
