import {
  faArrowDownToBracket,
  faBarsFilter,
  faMagnifyingGlass,
} from '@fortawesome/pro-solid-svg-icons';
import {
  CrewClaimResolutionMethodEnum,
  CrewClaimRollupStatusCode,
  CrewMerchantApi,
  isEnum,
  stringToJSON,
} from 'corso-types';
import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebounceCallback, useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';
import Button from '~/components/Button';
import RunReport from '~/components/claim/RunReport';
import { TextInput } from '~/components/field';
import Icon from '~/components/Icon';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Transition } from '~/components/ui/primitives/Transition';
import { useStoreId } from '~/hooks/useStoreId';
import { Filter } from './Filter';
import Filters from './Filters';
import SortMenu from './SortMenu';
import {
  convertFiltersToSearch,
  orderFilterSchema,
  registrationFiltersSchema,
  returnFiltersSchema,
  shippingFiltersSchema,
  useFilterDefinition,
  warrantyFiltersSchema,
} from './useFilterDefinition';

type SearchReq = Omit<
  CrewMerchantApi.RequestBody<'/:storeId/search', 'post'>,
  'cursor' | 'take'
>;

// todo find a home for this
const useSortOptions = () =>
  useMemo(() => [{ value: 'createdOn', label: 'Date' }], []);

type SortValue = Parameters<ComponentProps<typeof SortMenu>['onChange']>[0];
const stateSchema = z.intersection(
  z.object({
    orderBy: z.object({
      column: z.string(),
      direction: z.union([z.literal('asc'), z.literal('desc')]),
    }),
    showFilters: z.boolean(),
    searchTerm: z.string(),
  }),
  z.discriminatedUnion('kind', [
    z.object({
      kind: z.literal('Return'),
      filters: returnFiltersSchema.partial(),
    }),
    z.object({
      kind: z.literal('Warranty'),
      filters: warrantyFiltersSchema.partial(),
    }),
    z.object({
      kind: z.literal('Shipping'),
      filters: shippingFiltersSchema.partial(),
    }),
    z.object({
      kind: z.literal('Registration'),
      filters: registrationFiltersSchema.partial(),
    }),
    z.object({
      kind: z.literal('Order'),
      filters: orderFilterSchema.partial(),
    }),
  ]),
);

type State = z.infer<typeof stateSchema>;

type Action =
  | {
      type: 'toggleFilters';
      show?: boolean;
    }
  | {
      type: 'filterChange';
      filter: Filter;
    }
  | {
      type: 'removeFilters';
      ids?: string[];
    }
  | {
      type: 'sortChange';
      orderBy: SortValue;
    }
  | {
      type: 'searchChange';
      searchTerm: string;
    }
  | {
      type: 'initialize';
      state: State;
    };

type ActionHandlers<Type extends Action['type'] = Action['type']> = {
  [K in Type]: (state: State, action: Extract<Action, { type: K }>) => State;
};

const actionHandlers: ActionHandlers = {
  toggleFilters: (state, { show }) => ({
    ...state,
    showFilters: show ?? !state.showFilters,
    searchTerm: '',
    filters: state.showFilters ? {} : state.filters,
  }),
  filterChange: (state, { filter }) => ({
    ...state,
    filters: { ...state.filters, [filter.id]: filter },
  }),
  removeFilters: (state, { ids }) => {
    const filters =
      ids ?
        Object.fromEntries(
          Object.entries(state.filters).filter(([id]) => !ids.includes(id)),
        )
      : {};

    return {
      ...state,
      filters,
      searchTerm: '',
    };
  },
  sortChange: (state, { orderBy }) => ({
    ...state,
    orderBy,
  }),
  searchChange: (state, { searchTerm }) => ({
    ...state,
    searchTerm,
  }),
  initialize: (_, { state }) => state,
};

const covertStateToSearch = (state: State) =>
  ({
    kind: state.kind,
    orderBy: state.orderBy.direction,
    filters: {
      ...convertFiltersToSearch(state.filters),
      searchTerm: state.searchTerm,
    },
  }) satisfies SearchReq;

type SearchControlBarProps<Entity extends SearchReq['kind']> = {
  entity: Entity;
  onSearchChange: (search: SearchReq) => void;
  isSearchPending?: boolean;
  className?: string;
};

export type SearchControlBarRef = {
  clearFilters: () => void;
};

const useSearchControlBarState = <Entity extends SearchReq['kind']>(
  entity: Entity,
  sortOptions: ReturnType<typeof useSortOptions>,
  onStateChange: (state: State, action: Action) => void,
) => {
  const storeId = useStoreId();
  const initialState = {
    kind: entity,
    filters: {},
    orderBy: { column: sortOptions[0]?.value ?? '', direction: 'desc' },
    showFilters: true,
    searchTerm: '',
  } satisfies State;

  const [state, persistState] = useLocalStorage<State>(
    `${storeId}-${entity}-search`,
    initialState,
    {
      deserializer: (serialized) => {
        const parsed = stringToJSON().pipe(stateSchema).safeParse(serialized);
        if (!parsed.success || parsed.data.kind !== entity) {
          // eslint-disable-next-line no-console
          console.warn(`Invalid ${entity} SearchControlBar State`, {
            parsed,
            error: parsed.error,
          });

          return initialState;
        }

        // ? maybe should check the filter values against the definition options
        return parsed.data;
      },
    },
  );

  const dispatch = useCallback(
    <Type extends Action['type']>(action: Extract<Action, { type: Type }>) => {
      const newState = actionHandlers[action.type](state, action);

      persistState(newState);

      onStateChange(newState, action);
    },
    [state, persistState, onStateChange],
  );

  useEffect(() => {
    onStateChange(state, { type: 'initialize', state });
    // eslint-disable-next-line react-hooks/exhaustive-deps --- just want to run once
  }, []);

  return [state, dispatch] as const;
};

function SearchControlBar<Entity extends SearchReq['kind']>(
  {
    entity,
    onSearchChange,
    isSearchPending,
    className,
  }: SearchControlBarProps<Entity>,
  ref: ForwardedRef<SearchControlBarRef>,
) {
  const filtersDefinition = useFilterDefinition(entity);
  const sortOptions = useSortOptions();

  const debouncedOnSearchChange = useDebounceCallback(onSearchChange, 300);

  const onStateChange = (state: State, action: Action) => {
    // if just showing filters, don't trigger a search
    if (action.type === 'toggleFilters' && state.showFilters) {
      return;
    }

    const search = covertStateToSearch(state);

    // it seems like if onSearchChange is called when initializing it will cause an error
    if (action.type === 'searchChange' || action.type === 'initialize') {
      debouncedOnSearchChange(search);
    } else {
      onSearchChange(search);
    }
  };

  const [state, dispatch] = useSearchControlBarState(
    entity,
    sortOptions,
    onStateChange,
  );
  const [showExport, setShowExport] = useState(false);

  useImperativeHandle(ref, () => ({
    clearFilters: () => {
      dispatch({ type: 'removeFilters' });
    },
  }));

  const allowExport = entity === 'Return' || entity === 'Warranty';
  const search = covertStateToSearch(state);

  return (
    <div
      className={twMerge(
        'w-full divide-y divide-corso-gray-200 bg-white',
        className,
      )}
    >
      <div className="flex items-center">
        <div className="flex-grow p-2">
          <Transition show={state.showFilters}>
            <TextInput
              id="search"
              label="Search"
              className={twMerge(
                'flex-grow',
                state.showFilters ?
                  'animate-in fade-in-25'
                : 'animate-out fade-out-25',
              )}
              onChange={(e) => {
                const { value } = e.target;
                dispatch({ type: 'searchChange', searchTerm: value });
              }}
              value={state.searchTerm}
              labelVisuallyHidden
              placeholder="Search"
              addon={{
                insideStart: (
                  <Icon icon={faMagnifyingGlass} className="size-3" />
                ),
              }}
            />
          </Transition>
        </div>
        <div className="flex items-center gap-2 self-end p-2">
          {isSearchPending && (
            <SpinnerIcon className="size-5 text-corso-gray-600" />
          )}

          <Button
            className="gap-1 py-2 text-xs"
            onClick={() => dispatch({ type: 'toggleFilters' })}
            data-testid="toggle"
          >
            {state.showFilters ?
              'Cancel'
            : <>
                <span className="sr-only">Search and filter</span>
                <Icon icon={faMagnifyingGlass} className="size-4" />
                <Icon icon={faBarsFilter} className="size-4" />
              </>
            }
          </Button>
          <SortMenu
            options={sortOptions}
            value={state.orderBy}
            onChange={(orderBy) => dispatch({ type: 'sortChange', orderBy })}
          />
          {allowExport && (
            <Button
              className="gap-1 py-2 text-xs"
              onClick={() => setShowExport(true)}
              disabled={isSearchPending}
            >
              <span className="sr-only">Export</span>
              <Icon icon={faArrowDownToBracket} className="size-4" />
            </Button>
          )}
        </div>
      </div>

      <Transition show={state.showFilters}>
        <Filters
          className={twMerge(
            'p-2',
            state.showFilters ?
              'animate-in fade-in-25'
            : 'animate-out fade-out-25',
          )}
          filterOptions={filtersDefinition}
          activeFilters={state.filters}
          onChange={(filter) => dispatch({ type: 'filterChange', filter })}
          onRemoveFilters={(ids) => dispatch({ type: 'removeFilters', ids })}
        />
      </Transition>

      {/* // TODO adjust how search is evaluated to help assert the types */}
      <RunReport
        searchTerm={search.filters.searchTerm}
        orderBy={search.orderBy}
        show={showExport}
        claimType="Return"
        createdOn={search.filters.createdOn}
        statusCodes={
          (
            'statusCodes' in search &&
            Array.isArray(search.statusCodes) &&
            search.statusCodes.length
          ) ?
            search.statusCodes.filter((status) =>
              isEnum(status, CrewClaimRollupStatusCode),
            )
          : undefined
        }
        resolutionMethods={
          (
            'requestedResolutionMethods' in search &&
            Array.isArray(search.requestedResolutionMethods) &&
            search.requestedResolutionMethods.length
          ) ?
            search.requestedResolutionMethods.filter((method) =>
              isEnum(method, CrewClaimResolutionMethodEnum),
            )
          : undefined
        }
        onClose={() => setShowExport(false)}
      />
    </div>
  );
}

export default forwardRef(SearchControlBar);
