const sizeStyle = {
  base: 'w-24',
  lg: 'w-64',
  sm: 'w-16',
};

export default function CorsoLogo({
  size = 'base',
}: {
  size?: keyof typeof sizeStyle;
}) {
  return (
    <svg
      className={sizeStyle[size]}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      fill="currentColor"
      viewBox="0 0 1080 234.59"
    >
      <g>
        <path d="M356.55,160.79c14.58,0,25.51-4.62,37.17-12.63v47.38c-14.58,7.78-28.67,10.21-40.09,10.21 c-51.02,0-87.96-37.42-87.96-88.44c0-49.57,37.9-88.44,88.2-88.44c13.12,0,26.97,3.4,39.6,10.45v47.62 c-10.69-8.26-22.11-13.12-36.93-13.12c-26.48,0-43.73,19.19-43.73,43.49C312.82,141.35,330.07,160.79,356.55,160.79z M588.76,117.3 c0,49.32-40.33,88.44-90.14,88.44c-49.81,0-90.14-39.12-90.14-88.44s40.33-88.44,90.14-88.44 C548.43,28.85,588.76,67.97,588.76,117.3z M541.87,117.3c0-24.54-18.95-43.98-43.25-43.98c-24.3,0-43.25,19.44-43.25,43.98 s18.95,43.98,43.25,43.98C522.92,161.27,541.87,141.83,541.87,117.3z M701.07,139.16l43.49,63.17h-53.45l-35.72-56.12h-5.35v56.12 h-45.43V32.26h60.74c39.6,0,66.82,20.65,66.82,57.58C732.17,113.41,719.54,129.93,701.07,139.16z M686.25,91.05 c0-11.42-8.5-18.22-20.17-18.22h-16.04v36.44h16.04C677.75,109.28,686.25,102.47,686.25,91.05z M802.45,82.07 c0-9.47,11.91-11.42,19.92-11.42c18.47,0,40.09,8.26,55.15,18.47V42.71c-17.25-9.48-36.45-13.85-56.13-13.85 c-35.23,0-66.33,18.22-66.33,56.13c0,63.66,82.37,42.76,82.37,66.09c0,10.93-13.85,12.63-21.14,12.63 c-19.92,0-43.01-8.75-58.8-20.41v48.35c17.49,9.48,40.09,14.09,60.01,14.09c34.74,0,68.03-18.22,68.03-57.58 C885.55,87.41,802.45,104.66,802.45,82.07z M1080,117.3c0,49.32-40.33,88.44-90.14,88.44c-49.81,0-90.14-39.12-90.14-88.44 s40.33-88.44,90.14-88.44C1039.67,28.85,1080,67.97,1080,117.3z M1033.11,117.3c0-24.54-18.95-43.98-43.25-43.98 c-24.3,0-43.25,19.44-43.25,43.98s18.95,43.98,43.25,43.98C1014.16,161.27,1033.11,141.83,1033.11,117.3z" />
        <g>
          <path d="M0,117.3c0,58.36,42.95,106.67,98.96,115.09v-62.37c-22.13-7.31-38.12-28.13-38.12-52.72s15.99-45.4,38.12-52.72V2.21 C42.95,10.62,0,58.94,0,117.3z" />
          <path d="M161.04,76.44c17.83,0,32.29-14.46,32.29-32.29c0-17.83-14.46-32.29-32.29-32.29s-32.29,14.46-32.29,32.29 C128.75,61.98,143.2,76.44,161.04,76.44z" />
          <circle cx="161.04" cy="190.45" r="32.29" />
        </g>
      </g>
    </svg>
  );
}
