import { CrewClaimResolutionMethodEnum } from 'corso-types';
import { useState } from 'react';
import Button from '~/components/Button';
import {
  createBaseReview,
  ReviewMetaStatus,
} from '~/providers/ClaimReviewProvider';
import { SelectedVariant } from '~/types';
import ProductReplacement from './ProductReplacement';
import { ResolutionModifierProps } from './types';

export default function ReplacementOrderModifier({
  reviewLineItem,
  onChange,
}: ResolutionModifierProps) {
  const suggestedItem =
    reviewLineItem.claimLineItem.suggestedWarrantyReplacementItem ?
      ([
        {
          ...reviewLineItem.claimLineItem.suggestedWarrantyReplacementItem,
          quantity: reviewLineItem.claimLineItem.quantity,
        },
      ] satisfies SelectedVariant[])
    : [];
  const [replacementItems, setReplacementItems] =
    useState<SelectedVariant[]>(suggestedItem);

  const { name } = reviewLineItem.claimLineItem.originalStoreOrderLineItem;

  return (
    <>
      <ProductReplacement
        products={replacementItems}
        onChange={setReplacementItems}
        originalProductName={name ?? ''}
      />
      <Button
        className="self-end"
        variant="primary"
        size="intrinsic"
        disabled={!replacementItems.length}
        onClick={() =>
          onChange({
            claimLineItem: reviewLineItem.claimLineItem,
            noteToCustomer: reviewLineItem.noteToCustomer,
            reviewMetadata: {
              status: ReviewMetaStatus.approvingWithModification,
              approval: {
                // ? should quantity be available to be changed
                ...createBaseReview(reviewLineItem.claimLineItem),
                resolutionMethodEnum:
                  CrewClaimResolutionMethodEnum.replacementOrder,
                replacementItems: replacementItems.map((item) => ({
                  idFromPlatform: item.idFromPlatform,
                  quantity: item.quantity,
                  name: `${item.productName}- ${item.label}`,
                  imgUrl: item.imageUrl,
                  sku: item.sku ?? undefined,
                })),
              },
            },
          })
        }
      >
        Confirm Resolution
      </Button>
    </>
  );
}
