import { Pegasus } from 'corso-types';
import { z } from 'zod';
import Card from '~/components/Card';
import EmptyStateAction from '~/components/EmptyStateAction';
import Page from '~/components/Page';
import Skeleton from '~/components/Skeleton';
import { useSearchParam } from '~/hooks/useSearchParam';
import { useStoreId } from '~/hooks/useStoreId';
import { useStoreRules } from '~/hooks/useStoreRules';
import { StoreRuleDisplay } from './StoreRuleDisplay';
import { displayName, eventTypeDescription } from './StoreRuleForm';
import { getEventTypeIcon } from './StoreRulesOverview';

function StoreRuleDisplaySkeleton() {
  return (
    <ul className="flex flex-col gap-2">
      <li className="flex justify-between">
        <Skeleton.Rectangle width="100%" height="2rem" />
        <div>
          <Skeleton.Rectangle width="3rem" height="2rem" />
          <Skeleton.Rectangle width="3rem" height="2rem" />
        </div>
      </li>
    </ul>
  );
}

export default function StoreRulesList() {
  const storeId = useStoreId();
  const eventTypeParam = useSearchParam('eventType');
  const { data: storeRules = [], isLoading } = useStoreRules();

  const eventType = z
    .nativeEnum(Pegasus.EventType)
    .nullable()
    .parse(eventTypeParam);

  const filteredStoreRules = storeRules
    .filter(
      (storeRule) => !eventType || storeRule.rule.event.type === eventType,
    )
    .sort((a, b) => a.name.localeCompare(b.name)) // alphabetical
    .sort((a, b) => b.rule.priority - a.rule.priority) // descending priority; higher will execute first
    .sort((a, b) => {
      // only those that are enabled will execute and apply
      if (a.isEnabled === b.isEnabled) return 0; // keep the order if both are enabled or disabled
      return a.isEnabled ? -1 : 1; // a before b if a is enabled
    });

  const returnTo = `/${storeId}/settings/automations/list?${new URLSearchParams({ ...(eventType && { eventType }) }).toString()}`;
  const search = new URLSearchParams({
    ...(eventType && { eventType }),
    returnTo,
  }).toString();

  return (
    <Page
      backAction
      title={`${eventType ? displayName.eventType[eventType] : 'All'} Automations`}
      subtitle="Rules are shown in the order they will apply."
      primaryAction={{
        content: 'Create Rule',
        to: {
          pathname: `/${storeId}/settings/automations/create`,
          search,
        },
      }}
    >
      <Card>
        <Skeleton skeleton={StoreRuleDisplaySkeleton} isLoading={isLoading}>
          {/* // TODO error state */}
          {!filteredStoreRules.length ?
            <EmptyStateAction.Link
              to={{
                pathname: `/${storeId}/settings/automations/create`,
                search,
              }}
              icon={getEventTypeIcon(eventType)}
              label={
                eventType ?
                  `Create Rule to ${displayName.eventType[eventType]}`
                : 'Create Automation Rule'
              }
              description={
                eventType ?
                  `Looks like you don't have any automations rules to ${displayName.eventType[
                    eventType
                  ].toLowerCase()} yet. ${eventTypeDescription[eventType]}`
                : 'Automate the review process at different stages of the claim lifecycle.'
              }
            />
          : <ul className="flex flex-col gap-2">
              {filteredStoreRules.map((storeRule) => (
                <li key={storeRule.id}>
                  <StoreRuleDisplay storeRule={storeRule} returnTo={returnTo} />
                </li>
              ))}
            </ul>
          }
        </Skeleton>
      </Card>
    </Page>
  );
}
