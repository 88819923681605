import {
  ClaimResolutionMethodEnum,
  CrewMerchantUi,
  gspClaimReasonLabels,
} from 'corso-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Alert from '~/components/Alert';
import {
  Checkbox,
  Fieldset,
  NumberInput,
  TextAreaInput,
} from '~/components/field';
import LineItem from '~/components/LineItem';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { ShippingProtectionFormValues } from './claimCreateSchemas';

export function ShippingProtectionClaimFields({
  order,
}: {
  order: CrewMerchantUi.CrewOrder;
}) {
  const { control, register, watch, formState } =
    useFormContext<ShippingProtectionFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lineItems',
  });

  const watchFields = watch('lineItems');
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFields[index],
  }));

  return (
    <>
      <Controller
        control={control}
        name="reason"
        render={({ field, fieldState }) => (
          <SimpleSelect
            label="Reason"
            options={order.shippingClaimReasons.map((reason) => ({
              label: gspClaimReasonLabels[reason],
              value: reason,
            }))}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="resolutionMethod"
        render={({ field, fieldState }) => (
          <SimpleSelect
            label="Resolution"
            options={Object.values(ClaimResolutionMethodEnum).map(
              (resolution) => ({ label: resolution, value: resolution }),
            )}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.error?.message}
          />
        )}
      />

      <TextAreaInput
        id="note-from-merchant"
        label="Note To Corso Team"
        details="Additional details to assist the Corso team in processing this claim."
        {...register('noteFromMerchant')}
      />

      <Label htmlFor="notify-customer" className="flex items-center gap-1">
        <Checkbox id="notify-customer" {...register('notifyCustomer')} />
        Send a Corso notification to the customer.
      </Label>
      <Fieldset legend="Line Items">
        <ul className="space-y-4">
          {order.lineItems
            .filter((lineItem) => !lineItem.isCorsoProduct)
            .map((lineItem) => {
              const lineItemFieldIndex = controlledFields.findIndex(
                (field) => field.id === lineItem.id,
              );
              const lineItemField = controlledFields[lineItemFieldIndex];

              return (
                <li className="group" key={lineItem.id}>
                  <div className="flex items-center gap-2">
                    {/* // TODO a11y label */}
                    <Checkbox
                      checked={!!lineItemField}
                      onChange={(e) =>
                        e.target.checked ?
                          append({
                            id: lineItem.id,
                            quantity: lineItem.currentQuantity,
                          })
                        : remove(lineItemFieldIndex)
                      }
                    />
                    <div className="flex-grow">
                      <LineItem
                        imageUrl={lineItem.imgUrl}
                        name={lineItem.name}
                        sku={lineItem.sku}
                        quantity={lineItem.currentQuantity}
                        unitPrice={lineItem.unitPrice}
                        unitTax={lineItem.unitTax}
                        options={lineItem.optionsFromPlatform}
                      />
                    </div>
                  </div>
                  {lineItemField && (
                    <div className="space-y-4 pb-8 pl-7 pt-2 group-last-of-type:pb-0">
                      <NumberInput
                        id={`quantity-${lineItem.id}`}
                        label="Quantity"
                        min={1}
                        max={lineItem.currentQuantity}
                        required
                        {...register(
                          `lineItems.${lineItemFieldIndex}.quantity`,
                          { valueAsNumber: true },
                        )}
                        error={
                          formState.errors.lineItems?.[lineItemFieldIndex]
                            ?.quantity?.message
                        }
                      />
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
        {formState.errors.lineItems?.message && (
          <div className="mt-2">
            <Alert
              variant="danger"
              message={formState.errors.lineItems.message}
            />
          </div>
        )}
      </Fieldset>
    </>
  );
}
