import {
  faBan,
  faBoltLightning,
  faBoxDollar,
  faRightLeft,
  faSackDollar,
  faShoppingBag,
  faTruck,
} from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlusIcon, SparklesIcon } from '@heroicons/react/24/outline';
import {
  CrewClaimReasonCategoryCode,
  CrewClaimResolutionMethodEnum,
  GeoCountryCode,
  Pegasus,
} from 'corso-types';
import { JSX, ReactNode } from 'react';
import GridList from '~/components/GridList';
import Page from '~/components/Page';
import { StoreRuleCreate } from '~/types';

export type StoreRuleTemplate = {
  icon?: JSX.Element;
  /** Suggested to stick to prose-oriented elements. */
  instructions: ReactNode;
  storeRule: StoreRuleCreate;
};

const storeRuleTemplates: StoreRuleTemplate[] = [
  {
    icon: <FontAwesomeIcon icon={faSackDollar} />,
    instructions:
      'This rule charges a handling fee when a customer opts to refund all items. The amount of the fee can be modified, and the name of the fee can be customized.',
    storeRule: {
      name: 'Charge Handling Fee for Refunds',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.in,
              value: [CrewClaimResolutionMethodEnum.refund],
            },
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.notIn,
              value: [
                CrewClaimResolutionMethodEnum.variantExchange,
                CrewClaimResolutionMethodEnum.warrantyReview,
                CrewClaimResolutionMethodEnum.giftCard,
              ],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.applyFee,
          params: {
            feeAmount: 5,
            feeDisplayName: 'Handling Fee',
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faShoppingBag} />,
    instructions:
      'This rule marks an item Final Sale, based on the product tags at the time of the order. This rule can be modified to apply to different tags, or to apply to all items.',
    storeRule: {
      name: 'Make Items Final Sale',
      hook: Pegasus.Hook.orderLookup,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.productTags,
              operator: Pegasus.Operator.in,
              value: ['corso-final-sale'],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.modifyResolutionWindow,
          params: {
            kind: 'eligibilityDays',
            ineligibleLineItemFootnote: 'Final Sale',
            exchange: 0,
            giftCard: 0,
            refund: 0,
            warrantyReview: 0,
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faShoppingBag} />,
    instructions:
      'This rule marks an item Exchange Only, based on the product tags at the time of the order. This rule can be modified to apply to different tags, or to apply to all items.',
    storeRule: {
      name: 'Make Items Exchange Only',
      hook: Pegasus.Hook.orderLookup,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.productTags,
              operator: Pegasus.Operator.in,
              value: ['corso-final-sale'],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.modifyResolutionWindow,
          params: {
            kind: 'eligibilityDays',
            exchange: 30,
            giftCard: 0,
            refund: 0,
            warrantyReview: 0,
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faBoxDollar} />,
    instructions:
      'This rule charges the customer the cost of the return label if order is not protected by Corso.',
    storeRule: {
      name: 'Charge Return Label Cost on Unprotected Orders',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.orderProtectedByCorso,
              operator: Pegasus.Operator.equal,
              value: false,
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.chargeForReturnLabel,
          params: {
            applyLabelMarkup: {
              shouldModify: false,
            },
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faBoxDollar} />,
    instructions:
      'This rule charges the customer the cost of the return label if the return has a refund amount even if the order is protected.',
    storeRule: {
      name: 'Charge Return Label Cost on Protected Order Refunds',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.orderProtectedByCorso,
              operator: Pegasus.Operator.equal,
              value: true,
            },
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.in,
              value: [CrewClaimResolutionMethodEnum.refund],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.chargeForReturnLabel,
          params: {
            applyLabelMarkup: {
              shouldModify: false,
            },
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faTruck} />,
    instructions:
      'This rule deducts the shipping cost from the refund total, when a customer opts to refund all items. This rule can also be modified to apply a markup to the return shipping cost.',
    storeRule: {
      name: 'Deduct Shipping Cost from Refund Total',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.in,
              value: [CrewClaimResolutionMethodEnum.refund],
            },
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.notIn,
              value: [
                CrewClaimResolutionMethodEnum.variantExchange,
                CrewClaimResolutionMethodEnum.warrantyReview,
                CrewClaimResolutionMethodEnum.giftCard,
              ],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.chargeForReturnLabel,
          params: {
            applyLabelMarkup: {
              shouldModify: false,
            },
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faTruck} />,
    instructions:
      'This rule charges for exchange order shipping if amount of the total exchange items is less than $100. This rule can also be modified to apply a markup to the exchange order shipping cost.',
    storeRule: {
      name: 'Charge for Exchange Order Shipping',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.exchangeOrderTotal,
              operator: Pegasus.Operator.lessThan,
              value: 100,
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.chargeForExchangeOrderShipping,
          params: {
            applyExchangeOrderShippingMarkup: {
              shouldModify: false,
            },
          },
        },
      },
    },
  },

  {
    icon: <FontAwesomeIcon icon={faSackDollar} />,
    instructions:
      'This rule waives fees on defective items. This is determined by a customer selecting a reason associated with a category of Defective Item.',
    storeRule: {
      name: 'Waive Fees on Defective Items',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.reasonCategoryCode,
              operator: Pegasus.Operator.in,
              value: [CrewClaimReasonCategoryCode.defectiveItem],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.applyFee,
          params: {
            feeAmount: 0,
            feeDisplayName: '',
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faRightLeft} />,
    instructions:
      'This rule offers instant exchanges to US customers. This rule can be modified to apply to a different country, or to offer instant exchanges to all customers.',
    storeRule: {
      name: 'Offer Instant Exchanges to US Customers',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.orderCountry,
              operator: Pegasus.Operator.in,
              value: [GeoCountryCode.US],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.offerInstantExchange,
          params: {
            timeToReturnBeforeCharging: 7,
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faBan} />,
    instructions:
      'This rule blocks exchanges for international orders. This rule can be modified to block exchanges for a different country, this rule still allows refunds and gift cards for international orders.',
    storeRule: {
      name: 'Block Exchanges for International Orders',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.orderCountry,
              operator: Pegasus.Operator.notIn,
              value: [GeoCountryCode.US],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.modifyResolutionWindow,
          params: {
            kind: 'eligibilityDays',
            refund: 30,
            exchange: 0,
            giftCard: 30,
            warrantyReview: 0,
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faTruck} />,
    instructions:
      'This rule charges return shipping on exchange of exchange orders. This rule can be modified to apply a markup to the return shipping cost.',
    storeRule: {
      name: 'Charge Shipping on Exchange of Exchange Orders',
      hook: Pegasus.Hook.beforeClaimSubmission,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.orderTags,
              operator: Pegasus.Operator.in,
              value: ['CREW-EXCHANGE-ORDER'],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.chargeForReturnLabel,
          params: {
            applyLabelMarkup: {
              shouldModify: false,
            },
          },
        },
      },
    },
  },
  {
    icon: <FontAwesomeIcon icon={faBoltLightning} />,
    instructions:
      'This rule finalizes gift card and variant exchanges when the return shipment is marked as in transit. This rule will not apply to other resolution methods, such as refunds.',
    storeRule: {
      name: 'Finalize Gift Cards and Exchanges on Return Shipment In Transit',
      hook: Pegasus.Hook.returnShipmentWebhook,
      isEnabled: false,
      rule: {
        conditions: {
          all: [
            {
              fact: Pegasus.Fact.returnShipmentStatus,
              operator: Pegasus.Operator.in,
              value: ['in_transit'],
            },
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.in,
              value: [
                CrewClaimResolutionMethodEnum.giftCard,
                CrewClaimResolutionMethodEnum.variantExchange,
              ],
            },
            {
              fact: Pegasus.Fact.claimLineItemRequestedResolutionMethod,
              operator: Pegasus.Operator.notIn,
              value: [CrewClaimResolutionMethodEnum.refund],
            },
          ],
        },
        priority: 1,
        event: {
          type: Pegasus.EventType.autoFinalizeClaim,
          params: {},
        },
      },
    },
  },
];

/** Store Rule templates with a  runtime generated random UUID for selection/data passing of a unique key. */
export const storeRuleTemplatesWithIds = storeRuleTemplates.map(
  (storeRuleTemplate) => ({
    id: crypto.randomUUID(),
    ...storeRuleTemplate,
  }),
);

export default function StoreRuleTemplates() {
  return (
    <Page backAction title="Automation Templates">
      <GridList.Wrapper>
        <GridList data={storeRuleTemplatesWithIds}>
          {({ id, icon, storeRule: { name } }) => (
            <GridList.ItemLink
              key={id}
              label={name}
              to={{
                pathname: '../create',
                search: `?template=${id}`,
              }}
              icon={icon ?? <SparklesIcon aria-hidden="true" />}
              secondaryIcon={<PlusIcon aria-hidden="true" />}
              variant="primary"
            />
          )}
        </GridList>
      </GridList.Wrapper>
    </Page>
  );
}
