import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

// type ButtonWithId = ButtonProps & Required<Pick<ButtonProps, 'id'>>;

// ? possibly adjust for border/ring between elements on mobile/desktop for button group
export default function ButtonGroup({
  horizontal = false,
  children,
}: {
  /** A variation of the button group that stays horizontal at all screen sizes. */
  horizontal?: boolean;
  /**
   * This element handles applying some additional classes and grouping to `Button` elements.
   * It is only intended that our `Button` element is used as a child of this element.
   */
  // TODO find a way to enforce this better in TypeScript
  children: ReactNode;
}) {
  return (
    // `twMerge` is unnecessary here; however, it's used to provide some clarity and separation for the classes applied
    <div
      className={twMerge(
        'flex flex-col rounded-md bg-white lg:flex-row',
        '[&>*]:min-w-max [&>*]:flex-auto [&>*]:rounded-none', // all direct children
        // ? maybe include margin adjustments for borders; however, it doesn't work to show color on the overlapping rings/borders
        // '[&>*:not(:first-child)]:-mt-px [&>*:not(:first-child)]:lg:-ml-px [&>*:not(:first-child)]:lg:mt-0', // all children except first
        // TODO consider if this can be simplified
        !horizontal &&
          'first:[&>*]:rounded-t-md first:[&>*]:lg:rounded-l-md first:[&>*]:lg:rounded-r-none', // first direct child
        !horizontal &&
          'last:[&>*]:rounded-b-md last:[&>*]:lg:rounded-l-none last:[&>*]:lg:rounded-r-md', // last direct child
        horizontal &&
          'flex-row first:[&>*]:rounded-l-md first:[&>*]:rounded-r-none last:[&>*]:rounded-l-none last:[&>*]:rounded-r-md',
      )}
    >
      {children}
    </div>
  );
}
