import { Outlet } from 'react-router-dom';
import { z } from 'zod';
import Page from '~/components/Page';
import SearchTable from '~/components/searchTable/SearchTable';
import { usePathParams } from '~/hooks/usePathParams';

const paramsSchema = z.object({
  storeId: z.string(),
  shippingClaimId: z.string().optional(),
});

/** Represents a sidebar and main content layout, which intelligently shows both on desktop, while separating the sidebar list navigation and individual items on mobile. */
export default function ShippingClaimsLayout() {
  const params = usePathParams(paramsSchema);

  if (params.shippingClaimId) {
    return <Outlet />;
  }

  return (
    <Page
      title="Shipping"
      size="fullWidth"
      primaryAction={{
        to: `/${params.storeId}/orders/lookup`,
        content: 'Order Lookup',
      }}
    >
      <SearchTable entity="Shipping" />
    </Page>
  );
}
