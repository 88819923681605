import { CrewMerchantUi } from 'corso-types';
import MediaGallery from '~/components/MediaGallery';

export default function CustomerMedia({
  assets,
}: {
  assets: CrewMerchantUi.ClaimLineItem['images'];
}) {
  if (!assets?.length) return null;

  return (
    <MediaGallery
      media={assets.map((src) => ({ src, alt: 'Customer Media' }))}
    />
  );
}
