import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

const Checkbox = forwardRef<
  HTMLInputElement,
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(({ className, ...props }, ref) => (
  <input
    ref={ref}
    type="checkbox"
    className={twMerge(
      'h-5 w-5 rounded-md text-corso-blue-600 shadow-sm ring-1 ring-inset ring-corso-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-corso-blue-600 disabled:cursor-not-allowed disabled:bg-corso-gray-400 disabled:text-corso-gray-500 disabled:ring-corso-gray-200',
      className,
    )}
    {...props}
  />
));

export default Checkbox;
