import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { cn } from '~/utils/shadcn';

const LoadingContext = createContext<null | {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}>(null);

export function useLoading() {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoadingContext must be used within a LoadingProvider');
  }

  return context;
}

/**
 * Simply sets `loading` to `true` when mounted, and `false` when unmounted.
 * Does not render anything, but that's delegated to the `LoadingBar`.
 *
 * Provided as an alternative API to the `useLoading` hook.
 */
export function Loading() {
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);

    return () => setLoading(false);
  }, [setLoading]);

  return null;
}

export function LoadingProvider({ children }: PropsWithChildren) {
  const [loading, setLoading] = useState(false);
  const value = useMemo(() => ({ loading, setLoading }), [loading]);

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}

/** The maximum value from 0 to 1 that the loading bar is allow to be at. */
const LOADING_BAR_LIMIT = 0.99;

/**
 * Used to help indicate something is happening, and displays a bar that fills up but never completes when `loading`.
 * This is a visual indicator only, and does not actually indicate progress.
 * It helps users perceive something is happening, while simulating progress.
 */
export function LoadingBar({ className }: { className?: string }) {
  const { loading } = useLoading();
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(0); // reset the value when loading starts
    const interval = setInterval(() => {
      setValue((previous) => {
        const next = Math.min(
          LOADING_BAR_LIMIT,
          previous + Math.cos(previous) * 0.1,
        );
        if (next === LOADING_BAR_LIMIT) clearInterval(interval); // stop when we reach the limit
        return next;
      });
    }, 100);
    if (!loading) clearInterval(interval);
    return () => clearInterval(interval);
  }, [loading]);

  if (!loading) return null;

  return (
    <progress
      className={cn(
        className,
        'h-1 w-full [&::-moz-progress-bar]:bg-corso-blue-800 [&::-webkit-progress-bar]:bg-neutral-700 [&::-webkit-progress-value]:bg-corso-blue-800',
      )}
      value={value}
      aria-label="Loading"
    />
  );
}
